import React, { useState } from "react";
import Token from '../../../Helper/Token';
import { toast } from 'react-toastify';
const TransactionPassword = () => {
  const [pass, setPass] = useState({ OldPassword: "", NewPassword: "", cpass: "" })
  const [btnloder, setBtnloder] = useState()
  const ChangeInput = (e) => setPass({ ...pass, [e.currentTarget.name]: e.currentTarget.value })
  const ChangePass = (e) => {
    e.preventDefault();
    if (pass.NewPassword === pass.cpass) {
      setBtnloder(true);
      Token.post('/ChangeTranPassword', pass).then((res) => {
        if (res.status === 200 && parseInt(res.data.Status) === 1) { toast.success('Password Successfully changed.', { position: "top-left", autoClose: 900 }) } else { toast.error(res.data.Message, { position: "top-left", autoClose: 900 }); }
        setBtnloder(false);
      }).catch((err) => { toast.error('Internal Server Error.', { position: "top-left", autoClose: 900 }); setBtnloder(false); })
    } else { toast.error('Password Not Match.', { position: "top-left", autoClose: 900 }) }
  }
  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Profile</h2>

            </div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active ms-auto">
                <a class="d-flex align-self-center">
                  <span>
                    {" "}
                    <i class="bi bi-ui-checks"></i>
                  </span>
                  Profile
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="">Profile Element</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-8 col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Change Transaction Password</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={ChangePass}>
                      <div class="mb-3 position-relative row">
                        <label class="col-sm-3 col-form-label">
                          Current Transaction Password:
                        </label>
                        <div class="col-sm-9">
                          <input type="password" id="dlab-password-1" name="OldPassword" class="form-control" placeholder="Current Transaction Password" onInput={ChangeInput} required />
                          <span class="show-pass-1 eye style-1">
                            <i class="fa fa-eye-slash"></i>
                            <i class="fa fa-eye"></i>
                          </span>
                        </div>
                      </div>
                      <div class="mb-3 position-relative row">
                        <label class="col-sm-3 col-form-label"> New Transaction Password: </label>
                        <div class="col-sm-9">
                          <input type="password" id="dlab-password-1" name="NewPassword" class="form-control" onInput={ChangeInput} placeholder="New Transaction Password" required />
                          <span class="show-pass-1 eye style-1">
                            <i class="fa fa-eye-slash"></i>
                            <i class="fa fa-eye"></i>
                          </span>
                        </div>
                      </div>
                      <div class="mb-3 position-relative row">
                        <label class="col-sm-3 col-form-label">
                          Confirm Transaction Password
                        </label>
                        <div class="col-sm-9">
                          <input type="password" id="dlab-password-1" name="cpass" class="form-control" onInput={ChangeInput} placeholder="Confirm Transaction Password" required />
                          <span class="show-pass-1 eye style-1">
                            <i class="fa fa-eye-slash"></i>
                            <i class="fa fa-eye"></i>
                          </span>
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <div class="col-sm-10">
                          {
                            btnloder ? <button type="button" class="btn btn-primary btn-sm btn-lod" disabled><div class="spinner-border sz-3" role="status"></div> <span>Loading...</span></button> : <button type="submit" class="btn btn-primary btn-sm">Submit</button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionPassword;
