import { MDBDataTable } from 'mdbreact';
import { useState, useEffect } from 'react';
import Token from '../../../Helper/Token';
import { User } from "../../../Helper/Variables";
import { toast } from "react-toastify";
const AddBusiness = () => {
    const Auth = User();
    if (Auth?.Authorized?.user?.SId != 0) { window.location.href="/user/dashboard"; }
    const [loader, setLoader] = useState(true);
    const [Record, setRecord] = useState([]);
    const [Actlode, setActlode] = useState();
    const [image, setImage] = useState({ temp: "", enc: "" });
    const [Imagesmessage, setImagesmessage] = useState("");
    const [BtnLoader, setBtnLoader] = useState();
    const table = {
        columns: [
            {
                label: 'S.No',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Image',
                field: 'img',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 150
            }
        ],
        rows: []
    };
    const Getdata = () => {
        Token.get('/GetPopup').then(res => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setRecord(res.data.Payload); } else { setRecord([]); }
            setLoader(false)
        }).catch(err => { setLoader(false) })
    }
    useEffect(() => {
        if (Auth.Authorized && Auth.Authorized.user && Auth.Authorized.user && Auth.Authorized.user.SId && parseInt(Auth.Authorized.user.SId) !== 0) {
            window.location.href="/user/dashboard";
        }
        window.scrollTo(0, 0);
        Getdata();
    }, [])
    const Inactive = (e) => {
        setActlode(true);
        Token.post('/PopupInActive?Id=' + e).then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) {
                Getdata();
                toast.success(res.data.Message, { position: "top-right", autoClose: 700, });
            } else { toast.error(res.data.Message, { position: "top-right", autoClose: 700, }); }
            setActlode(false);
        }).catch((err) => { toast.error('Internal Server Error', { position: "top-right", autoClose: 700, }); setActlode(false); })
    }
    const Active = (e) => {
        setActlode(true);
        Token.post('/PopupActive?Id=' + e).then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) {
                Getdata();
                toast.success(res.data.Message, { position: "top-right", autoClose: 700, });
            } else { toast.error(res.data.Message, { position: "top-right", autoClose: 700, }); }
            setActlode(false);
        }).catch((err) => { toast.error('Internal Server Error', { position: "top-right", autoClose: 700, }); setActlode(false); })
    }
    const Delete = (e) => {
        setActlode(true);
        Token.post('/PopupDelete?Id=' + e).then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) {
                Getdata(); toast.success(res.data.Message, { position: "top-right", autoClose: 700, });
            } else { toast.error(res.data.Message, { position: "top-right", autoClose: 700, }); }
            setActlode(false);
        }).catch((err) => { toast.error('Internal Server Error', { position: "top-right", autoClose: 700, }); setActlode(false); })
    }
    Record.map((tr, i) => {
        table.rows.push({
            id: i < 10 ? "0" + (i + 1) : (i + 1),
            img: <img src={`https://apinvshoppe.mnbsoft.co.in/UploadImage/${tr.imagename}`} />,
            status: tr.status === 1 ? <span class="badge rounded-pill text-bg-success">Active</span> : <span class="badge rounded-pill text-bg-warning">Inactive</span>,
            action: Actlode ? <div class="spinner-border" role="status"></div> : <>{tr.status === 1 ? <button type="button" class="btn btn-secondary tblbtn" onClick={() => Inactive(tr.id)}>Inactive</button> : <button type="button" class="btn btn-success tblbtn" onClick={() => Active(tr.id)}>Active</button>}<button type="button" class="btn btn-danger tblbtn" onClick={() => Delete(tr.id)}>Delete</button></>
        })
    })
    const GetImage = (e) => {
        setImagesmessage("");
        if (e.currentTarget.files[0]) {
            const tempurl = URL.createObjectURL(e.currentTarget.files[0]);
            let reader = new FileReader();
            reader.readAsDataURL(e.currentTarget.files[0]);
            reader.onload = () => { setImage({ temp: tempurl, enc: reader.result.split(",")[1] }) }
            reader.onerror = (error) => { setImage({ temp: "", enc: "" }) }
        } else { setImage({ temp: "", enc: "" }); }
    }
    const upload = (e) => {
        e.preventDefault();
        if (image.enc) {
            setBtnLoader(true);
            setImagesmessage('');
            Token.post('/PopupAdd?Img=' + image.enc).then((res) => {
                if (res.status === 200 && parseInt(res.data.Status) === 1) {
                    Getdata(); toast.success(res.data.Message, { position: "top-right", autoClose: 700, });
                    document.getElementById("closepupmodel").click();
                } else { setImagesmessage(res.data.Message); toast.error(res.data.Message, { position: "top-right", autoClose: 700, }); }
                setBtnLoader(false);
            }).catch((err) => { setImagesmessage("Internal Server Error"); toast.error('Internal Server Error', { position: "top-right", autoClose: 700, }); setBtnLoader(false); })
        } else {
            setImagesmessage("Please Select an Popup Image.");
        }
    }
    return (
        <>
            <div class="page-titles">
                <div class="sub-dz-head">
                    <div class="d-flex align-items-center dz-head-title">
                        <h2 class="text-white m-0">Popup</h2>
                    </div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active ms-auto">
                            <a class="d-flex align-self-center">
                                <span>
                                    <i class="bi bi-ui-checks"></i>
                                </span>
                                Popup
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a>Add Business</a>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="content-body">
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Add Business</h4>
                                    <div data-bs-toggle="modal" data-bs-target="#Addpopup" className='btn btn-primary'>Add Business</div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        {loader ?
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-8"></span>
                                            </p> :
                                            <MDBDataTable bordered small data={table} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="Addpopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Add Business</h1>
                            <button type="button" class="btn-close" id="closepupmodel" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={upload}>
                                <div className='text-center'>
                                    {Imagesmessage ? <div class="alert alert-danger" role="alert">{Imagesmessage}</div> : ""}
                                    <label htmlFor="pupimage"><img src={image.temp ? image.temp : "/images/choosefile.png"} class="img-fluid" alt="pop" style={{maxHeight: "200px"}}/></label>
                                    <input type="file" id="pupimage" onChange={GetImage} hidden />
                                </div>
                                {
                                    BtnLoader ? <button type="button" class="btn btn-primary w-100 mt-4 text-center" disabled><div class="spinner-border" role="status"></div></button> : <button type="submit" class="btn btn-primary w-100 mt-4">Upload</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBusiness;
