import { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { humanReadableTimeAndDate } from "../../../Helper/function";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";

export default function FundRevieveReport() {
  const [fundTransfer, setFundTransfer] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  function fetchRequestData() {
    setLoadingTable(true);
    token
      .get("/fund-transfer/fund-recieve")
      .then((res) => {
       
        if (res.data.status == "success") {
          setFundTransfer(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  useEffect(() => {
    fetchRequestData();
  }, []);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },

      {
        label: "Reciever Id",
        field: "senderId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reciever Name",
        field: "senderName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  fundTransfer.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      amount: elem.amount,
      senderId: elem.senderId.userId,
      senderName: elem.senderId.name,
      transactionId: elem.transactionId,
      date: humanReadableTimeAndDate(elem.createdAt),

      remark: elem.remark,
    });
  });

  return (
    <>
      <div class="page-titles">
        <h1 className="text-light">Fund Reciev Fund Recieve Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Amount
                          </th>
                          <th className="border" scope="col">
                          Reciever Id
                          </th>
                          <th className="border" scope="col">
                          Reciever Name
                          </th>
                          <th className="border" scope="col">
                            Date
                          </th>
                          <th className="border" scope="col">
                           Remark
                          </th>
                         
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={6} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
