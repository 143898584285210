import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { useParams } from "react-router-dom";
import TablePlaceholder from "../../../../Components/TablePlaceholder";
import token from "../../../../../Helper/Token";
export default function AdminFlycrickTicketReport() {
    const { flightdate } = useParams()
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [date,setDate] = useState(new Date().toISOString().substring(0, 10))
  useEffect(() => {
    fetchFlycrickReport(); 
  }, [date]);

  async function fetchFlycrickReport() {
    setTableLoading(true);
    token.get(`/admin/random-flight/get-flight-forcelanding-details-bydate?date=${flightdate}`).then((res)=>{
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      toast.error("Connection issue");
    }).finally(()=>{
      setTableLoading(false);
    })
  }

  const table = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Number",
        field: "flightNumber",
        sort: "asc",
        width: 150,
      },
      {
        label: "From",
        field: "fromplace",
        sort: "asc",
        width: 150,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 150,
      },
      {
        label: "Landing",
        field: "landing",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      }
    ],

    rows: [],
  };
  const NewDate = (e) => {
    let dt = new Date(e);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",][dt.getMonth()];
    return dt.getDate() + "-" + months + "-" + dt.getFullYear();
};
  tableData?.map((elem, i) => {
    table.rows.push({
      sno: i + 1,
      flightNumber: elem?.flightNumber,
      fromplace: elem?.fromPlace,
      to: elem?.to,
      landing: elem.forced?<span>Forced</span>:<span>Natural</span>,
      date: NewDate(elem?.departureTime),
    });
  });

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header w-100">
                  <div className="w-100 d-flex justify-content-between">
                    <h4 class="card-title">Flight Report</h4>
                  </div>
                </div>
                <div class="card-body">
                {!tableLoading ?
                        <MDBDataTable bordered small data={table} />
                        :
                        <table class="table table-responsive">
                          <TablePlaceholder colNum={4} />
                        </table>
                      }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
