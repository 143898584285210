import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { formatDate } from "../../helper/function/Helper.function";
import TablePlaceholder from "../../../../Components/TablePlaceholder";
import token from "../../../../../Helper/Token";
export default function AdminForceLandFlight() {
  const [flightData, setFlightData] = useState({});
  const [tableLoading,setTableLoading] = useState(false)
  const { id } = useParams();
  useEffect(() => { fetchSingleFlightData(); }, []);
  async function fetchSingleFlightData() {
    setTableLoading(true)
    token.get(`/admin/random-flight/single-flight-data/${id}`).then((res)=>{
      if (res.data.status === "success") {
        setFlightData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      toast.error("Connection issue");
    }).finally(()=>{
      setTableLoading(false);
    })
  }

  async function landForcefully(reqBody) {
    token.put(`/admin/random-flight/land-flight-forcefully`,reqBody).then((res)=>{
      if (res.data.status === "success") {
        fetchSingleFlightData();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      toast.error("Connection issue");
    })
  }

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">{flightData?.flightNumber}</h4>
                  <h4 class="card-title">From - {flightData?.fromPlace}</h4>
                  <h4 class="card-title">
                    Departure Time - {formatDate(flightData?.departureTime)}
                  </h4>
                </div>
                <div class="card-body px-3">
                  {!tableLoading ?
                    <div class="table-responsive mbd-table striped">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">S. No.</th>
                            <th scope="col">City</th>
                            <th scope="col">booked Tickets</th>
                            <th scope="col">Booked Amount</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {flightData?.destinations?.map((elem, index) => {
                            return (
                              <tr>
                                <th>
                                  {elem?.destinationNumber < 10
                                    ? "0" + elem?.destinationNumber
                                    : elem?.destinationNumber}
                                </th>
                                <td>{elem?.destination}</td>
                                <td>
                                  {elem?.totalTickets < 10
                                    ? "0" + elem?.totalTickets
                                    : elem?.totalTickets}
                                </td>
                                <td>
                                  {"₹" + Number(elem?.betAmount)?.toFixed(2)}
                                </td>
                                <td>
                                  <>
                                    {!elem?.forced ? (
                                      <button
                                        className="btn btn-warning"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          landForcefully({
                                            flightId: flightData?._id,
                                            destinationId: elem?._id,
                                            action: 1,
                                          });
                                        }}
                                      >
                                        Force to land
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          landForcefully({
                                            flightId: flightData?._id,
                                            destinationId: elem?._id,
                                            action: 0,
                                          });
                                        }}
                                      >
                                        Forced to land
                                      </button>
                                    )}
                                  </>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    :
                    <table class="table table-responsive">
                      <TablePlaceholder colNum={4} />
                    </table>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
