import React, { useEffect, useState } from "react";
import { socket } from "../../../../../Helper/Variables";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate, isInPastWithAddedDuration } from "../../helper/function/Helper.function";
import UserFlycrickTimeLeft from "../user-component/User.Time.Left";
import token from "../../../../../Helper/Token";
export default function UserFlycrickLivePlane() {
  const [flightFlying, setFlightFlying] = useState(false);
  const [flightData, setFlightData] = useState({
    finalDestination: {
      destination: (
        <span class="card-text placeholder-glow">
          <span class="placeholder col-10">Loader</span>
        </span>
      ),
    },
    fromPlace: (
      <span class="card-text placeholder-glow">
        <span class="placeholder col-10">Loader</span>
      </span>
    ),
    flightNumber: (
      <span class="card-text placeholder-glow">
        <span class="placeholder col-10">Loader</span>
      </span>
    ),
    destinationCities: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [destination, setDestinaion] = useState("");
  const { flightId } = useParams();

  useEffect(() => {
    socket.on("User_Flycrick_Flight_Flying", (data) => {
      console.log("WORKING FINE FLIGHT IS IN THE AIR", data);
      setFlightFlying(true);

      return () => {
        socket.off("User_Flycrick_Flight_Flying");
      };
    });
  }, []);

  useEffect(() => {
    socket.on("User_Flycrick_Flight_Landed", (destination) => {
      setDestinaion(destination);
      fetchFlightData();
      // socket.off("User_Flycrick_Flight_Flying");
      console.log("WORKING FINE FLIGHT IS LANDED");
      setFlightFlying(false);

      return () => {
        socket.off("User_Flycrick_Flight_Landed");
      };
    });
  }, []);

  useEffect(() => {
    fetchFlightData();
  }, []);

  async function fetchFlightData() {
    token.get(`/random-flight/single-flight/${flightId}`).then((res) => {
      if (res.data.status == "success") {
        setFlightData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch(()=>{
      toast.error("Connection issue");
    })
  }

  return (
    <>
     <div class="page-titles"><div class="container-fluid"><div class="sub-dz-head"><div class="d-flex align-items-center dz-head-title"><h2 class="text-white m-0"></h2></div></div></div></div>

      <div class="content-body">
        <div class="container-fluid px-2">
          <div class="row pb-5">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body p-0">
                      <div class="market-coin flex-wrap">
                        <div class="d-flex align-items-center coin-box">
                          <span>
                            <img
                              src="/favicon/logo-light.png"
                              className="icon-plane"
                              alt=""
                            />
                          </span>
                          <div class="ms-3">
                            <span class="fs-14 font-w400">Flight No</span>
                            <a href="/">
                              <h4 class="font-w600 mb-0">
                                RocketUp {flightData?.flightNumber}
                              </h4>
                            </a>
                          </div>
                        </div>
                        <div class="coin-box">
                          <span class="mb-1 d-block">From</span>
                          <div class="d-flex align-items-center">
                            <h5 class="font-w600 m-0 ">
                              {flightData?.fromPlace}
                            </h5>
                          </div>
                        </div>
                        <div class="coin-box">
                          <span class="mb-1 d-block">To</span>
                          <h5 class="font-w600 m-0 ">
                            {" "}
                            {destination
                              ? destination
                              : flightData?.finalDestination?.destination
                                ? flightData?.finalDestination?.destination
                                : "----"}
                          </h5>
                        </div>
                        <div class="coin-box">
                          <span class="mb-1 d-block">Departure</span>
                          <h5 class="font-w600 m-0 ">
                            {flightData?.departureTime ? (
                              formatDate(flightData?.departureTime)
                            ) : (
                              <span class="card-text placeholder-glow">
                                <span class="placeholder col-10">Loader</span>
                              </span>
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-header">
                      <div>
                        {flightData?.destinationCities?.map((elem, index) => {
                          return (
                            <span
                              class={`badge badge-more badge-pill badge-dark m-1 text-white 
                                
                               ${elem?.destination ===
                                  flightData?.finalDestination?.destination
                                  ? "bg-success"
                                  : elem?.isBooked
                                    ? "bg-warning"
                                    : null
                                }
                                
                                `}
                            >
                              {elem == 0 ? (
                                <span class="card-text placeholder-glow">
                                  <span class="placeholder col-12">
                                    Placeholder...
                                  </span>
                                </span>
                              ) : elem?.destinationNumber < 10 ? (
                                "0" +
                                elem?.destinationNumber +
                                ". " +
                                elem?.destination
                              ) : (
                                elem?.destinationNumber +
                                ". " +
                                elem?.destination
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </div>

                    <div class="card-body Aviator-box spin-box3  d-flex align-items-center justify-content-center ">

                      {/* <div class="glowplane animate fadeIn"></div> */}



                    




                      <div class="clearfix"></div>

                      {/* Loader */}

                      {!flightData?.departureTime ? (
                        <>
                          <div className="loader animate__animated animate__zoomIn" hidden>
                            <div className="loadsection">
                              <div class="Lands">
                                <div class="Lander">
                                  <span id="hours">Loading...</span>
                                  <p>Flight Landed</p>
                                </div>
                              </div>

                              {/* <h3 className="text-uppercase text-center">
                                Waiting For The Flight Takeoff
                              </h3> */}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* Timer */}
                      {flightData?.departureTime ? (
                        <>

                          <div className="">
                            {!isInPastWithAddedDuration(
                              flightData?.departureTime
                            ) && !flightFlying ? (
                              <>
                                <div className="flightwaiting">
                                  <div className="runway"></div>
                                  <div className="wait"><img src="/images/Waiting.png" alt="" /></div>
                                  <audio className="flight-audio" controls loop autoplay="">

                                    <source src="/images/Flight-Sounds.mp3" type="audio/mpeg" />

                                  </audio>
                                </div>
                                <UserFlycrickTimeLeft
                                  departureTime={flightData?.departureTime}
                                />
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : null}

                      {/* {FLYING PLANE} */}

                      {flightFlying ? (
                        <>
                           <div className="cloudgr"></div>
                          <div class="finalAviator">

                            <audio className="flight-audio" controls loop autoplay="">

                              <source src="/images/takeoff.mp3" type="audio/mpeg" />

                            </audio>
                            <div class="outerchart themewidth"></div>
                          
                            <div className="">
                              <img
                                src="/images/flightland.png"
                                class="startgraph  planetakeoff "
                                alt=""
                              />
                            </div>

                            <div class="cloudbox">
                              <div class="clouds">
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud2.png" alt="" />
                                </span>
                                <span class="cloud-box">
                                  <img src="/images/cloud.png" alt="" />
                                </span>
                              </div>
                            </div>

                            <div class="leftdots">
                              <div class="bdots">
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                                <span class="bdotted"></span>
                              </div>
                            </div>


                            <div class="building">
                              <div className="outerbuild">
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="buildingimg3"></div>
                                <div className="buildingimg"></div>
                                <div className="buildingimg2"></div>
                                {/* <div className="buildingimg"></div> */}
                                {/* <div className="buildingimg"></div>
                                <div className="buildingimg"></div> */}

                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain"></div>
                                <div className="mountain2"></div>

                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>

                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>
                                <div className="runwayimg"></div>




                              </div>






                            </div>
                            <div class="bottomdots">






                              <div class="dots">
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                                <span class="dotted"></span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* DESTINATION NAME */}
                      {!flightFlying || !flightData?.departureTime ? (
                        <>
                          {isInPastWithAddedDuration(
                            flightData?.departureTime
                          ) ? (
                            <>


                              <div className="cloudgr"></div>
                              <div class="finalAviator">


                                <div class="outerchart themewidth"></div>
                                {/* anim */}
                                <div className="">
                                  <img
                                    src="/images/flightland.png"
                                    class="startgraphland  "
                                    alt=""
                                  />
                                </div>






                                <div class="building">
                                  <div className="outerbuild2 ">
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="buildingimg3"></div>
                                    <div className="buildingimg"></div>
                                    <div className="buildingimg2"></div>
                                    {/* <div className="buildingimg"></div> */}
                                    {/* <div className="buildingimg"></div>
                                <div className="buildingimg"></div> */}



                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>

                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>
                                    <div className="runwayimg"></div>




                                  </div>






                                </div>

                              </div>


                              <div className="loader posit  animate__animated animate__zoomIn">
                                <div className="loadsection">
                                  <div class="Lands">
                                    <div class="Lander">
                                      <span id="hours">
                                        {destination
                                          ? destination
                                          : flightData?.finalDestination
                                            ?.destination
                                            ? (flightData?.finalDestination
                                              ?.destinationNumber < 10
                                              ? "0" +
                                              flightData?.finalDestination
                                                ?.destinationNumber
                                              : flightData?.finalDestination
                                                ?.destinationNumber) +
                                            " " +
                                            flightData?.finalDestination
                                              ?.destination
                                            : "Loading..."}
                                      </span>
                                      <p>Flight Landed</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3" hidden>
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Recent Winners</h4>
                </div>
                <div class="card-body py-2 px-2 ">
                  <button class="btn btn-xs mx-1 btn-primary">
                    All Bets 676
                  </button>
                  <button class="btn btn-xs me-1  btn-primary">My Bets</button>
                  <button class="btn btn-xs btn-primary">Top Bets</button>
                  <div class="table-responsive mt-2 card-height">
                    <table class="table  text-nowrap text-white  table-winner  order-tbl">
                      <thead class="sticky-md-top head-cash">
                        <th class="px-1 py-2 head-cash">User</th>
                        <th class="px-1 py-2 head-cash">Bet</th>
                        <th class="px-1 py-2 head-cash">X</th>
                        <th class="px-1 py-2 head-cash">Cash Out</th>
                      </thead>
                      <tbody>
                        <tr class="CashOut">
                          <td class="text-start px-1 py-2 font-10">Theodore</td>
                          <td class="px-1 py-2">900 INR</td>
                          <td class="px-1 py-2">1.08x</td>
                          <td class="px-1 py-2">972.00 INR</td>
                        </tr>

                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Oliver</td>
                          <td class="px-1 py-2">940 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Maya</td>
                          <td class="px-1 py-2">610 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Isabelle</td>
                          <td class="px-1 py-2">680 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Layla</td>
                          <td class="px-1 py-2">870 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Hazel</td>
                          <td class="px-1 py-2">560 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="">
                          <td class="text-start px-1 py-2 font-10">Zoey</td>
                          <td class="px-1 py-2">750 INR</td>
                          <td class="px-1 py-2"></td>
                          <td class="px-1 py-2"></td>
                        </tr>
                        <tr class="CashOut">
                          <td class="text-start px-1 py-2 font-10">Emily</td>
                          <td class="px-1 py-2">490 INR</td>
                          <td class="px-1 py-2">1.64x</td>
                          <td class="px-1 py-2">803.60 INR</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
