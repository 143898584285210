import { Link } from "react-router-dom";
import SocialButtons from "../../Components/SocialButtons";

export default function HomePage() {
  return (
    <>
      {/* <div id="preloader"></div> */}
      <header class="header-one">
        <div id="sticker" class="header-area header-area-2 hidden-xs">
          <div class="container-fluid">
            <div class="row">
              {/* <!-- logo start --> */}
              <div class="col-md-2 col-sm-2">
                <div class="logo">
                  {/* <!-- Brand --> */}
                  <a class="navbar-brand " href="">
                    <img src="img/logo/logo2.png" alt="" />
                  </a>
                </div>
                {/* <!-- logo end --> */}
              </div>
              <div class="col-md-10 col-sm-10">
                <div class="header-right-link">
                  {/* <!-- search option end --> */}
                  <Link to={"/login"} class="s-menu-2">
                    Login now
                  </Link>
                  <Link to={"/sign-up"} class="s-menu">
                    Register now
                  </Link>
                </div>
                {/* <!-- mainmenu start --> */}
                <nav class="navbar navbar-default">
                  <div class="collapse navbar-collapse" id="navbar-example">
                    <div class="main-menu">
                      <ul class="nav navbar-nav navbar-right">
                        <li>
                          <a class="pagess" href="">
                            About
                          </a>
                        </li>

                        <li>
                          <a class="pagess" href="">
                            Play
                          </a>
                        </li>
                        <li>
                          <a class="pagess" href="">
                            Games
                          </a>
                        </li>
                        <li>
                          <a class="pagess" href="">
                            Lottery
                          </a>
                        </li>
                        <li>
                          <a href="">Statistics</a>
                        </li>

                        <li>
                          <a href="">Results</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                {/* <!-- mainmenu end --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- header-area end --> */}
        {/* <!-- mobile-menu-area start --> */}
        <div class="mobile-menu-area hidden-lg hidden-md hidden-sm">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu">
                  <div class="logo">
                    <a href="">
                      <img src="img/logo/logo2.png" alt="" />
                    </a>
                  </div>
                  <div class="header-right-link">
                    <Link to={"/login"} class="s-menu-2">
                      Login now
                    </Link>
                    <Link to={"/sign-up"} class="s-menu">
                      Join now
                    </Link>
                  </div>

                  {/* <nav id="dropdown">
                    <ul>
                      <li>
                        <a class="pagess" href="">
                          Home
                        </a>
                      </li>
                      <li>
                        <a class="pagess" href="">
                          About
                        </a>
                      </li>
                      <li>
                        <a class="pagess" href="">
                          Play
                        </a>
                      </li>
                      <li>
                        <a class="pagess" href="">
                          Games
                        </a>
                      </li>
                      <li>
                        <a class="pagess" href="">
                          Lottery
                        </a>
                      </li>
                      <li>
                        <a href="">contacts</a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- mobile-menu-area end --> */}
      </header>
      {/* <!-- header end --> */}
      {/* <!-- Start Slider Area --> */}

      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="img/slider/1.jpg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="img/slider/2.jpg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="img/slider/3.jpg" class="d-block w-100" alt="..." />
          </div>
        </div>
      </div>

      <div class="chips-area bg-gray area-padding-2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="single-chips">
                <span class="chips-price">186 People</span>
                <div class="chips-image">
                  <img src="/img/games/b1.jpg" alt="" />
                </div>
                <div class="chips-text">
                  <div class="chips-number">Aviator Game</div>
                  <p>Instant game</p>
                  <Link to={"/login"} class="chips-btn">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="single-chips">
                <span class="chips-price">260 People</span>
                <div class="chips-image">
                  <img src="/img/games/b2.jpg" alt="" />
                </div>
                <div class="chips-text">
                  <div class="chips-number">Dice Game</div>
                  <p>Instant game</p>
                  <Link to={"/login"} class="chips-btn">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="single-chips">
                <span class="chips-price">400 People</span>
                <div class="chips-image">
                  <img src="/img/games/b3.jpg" alt="" />
                </div>
                <div class="chips-text">
                  <div class="chips-number">Quiz Games</div>
                  <p>Instant game</p>
                  <Link to={"/login"} class="chips-btn">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="overview-area bg-gray fix area-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="overview-content">
                <div class="overview-images about-img">
                  <img src="/img/about/about.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="overview-text">
                <h3>Playing games with Rocket Up winning instant Payment </h3>
                <p>
                Rocket Up is among the most-played crash games in India in 2024. You can win a shot at multiplying your stake by x100 and above playing this game. It’s important to note that Aviator is based on the Provably Fair algorithm, which means you can have complete faith in the integrity and security of this game as well as the handpicked casinos mentioned further in this review.
                </p>
                <ul>
                  <li>
                    <a href="#">Innovation idea latest business tecnology</a>
                  </li>
                  <li>
                    <a href="#">
                      Digital content marketing online clients plateform
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Safe secure services for you online email account
                    </a>
                  </li>
                </ul>
                <a class="overview-btn" href="#">
                  Signup today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="payment-history-area bg-gray fix area-padding">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <div class="section-headline">
                <h4 class="text-black text-center mb-90">
                  Today's Earnings People
                </h4>
              </div>
              <div class="dailyProfitRank__content">
                <div class="dailyProfitRank__content-topThree">
                  <div
                    class="dailyProfitRank__content-topThree__item placeholder-glow"
                    style={{ top: "-30px" }}
                  >
                    <div>
                      <img src="/img/team/t1.jpg" />
                    </div>
                    <div>
                      <img class="" src="/img/crown1.png" />
                      <img class="" src="/img/place1.png" />
                    </div>
                    <span className="placeholder">Mem***NPS</span>
                    <span className="placeholder">**********</span>
                  </div>

                  <div
                    class="dailyProfitRank__content-topThree__item placeholder-glow"
                    style={{ top: "-60px" }}
                  >
                    <div>
                      <img src="/img/team/t2.jpg" />
                    </div>
                    <div>
                      <img class="" src="/img/crown2.png" />
                      <img class="" src="/img/place2.png" />
                    </div>
                    <span className="placeholder">Mem***NPS</span>
                    <span className="placeholder">**********</span>
                  </div>

                  <div
                    class="dailyProfitRank__content-topThree__item placeholder-glow"
                    style={{ top: "-30px" }}
                  >
                    <div>
                      <img src="/img/team/t1.jpg" />
                    </div>
                    <div>
                      <img class="" src="/img/crown3.png" />
                      <img class="" src="/img/place3.png" />
                    </div>
                    <span className="placeholder">Mem***NPS</span>
                    <span className="placeholder">**********</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-history-area bg-gray fix area-padding m-pb">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="section-headline">
                <h4 class="text-black">Aviator Game History</h4>
              </div>
              <div class="luckyWinners__container-wrapper">
                <div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="section-headline">
                <h4 class="text-black">Dice Game History</h4>
              </div>
              <div class="luckyWinners__container-wrapper">
                <div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>

                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="section-headline">
                <h4 class="text-black">Quiz Games History</h4>
              </div>
              <div class="luckyWinners__container-wrapper">
                <div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                  <div class="luckyWinners__container-wrapper__item">
                    <div class="luckyWinners__container-wrapper__item-img">
                      <img class="" src="/img/team/t1.jpg" />
                    </div>

                    <div class="luckyWinners__container-wrapper__item-info placeholder-glow">
                      <h1 className="placeholder">Mem***DHA</h1>
                    </div>

                    <div class="luckyWinners__container-wrapper__item-winType">
                      <img
                        class=""
                        src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062111bt8e.png"
                      />
                    </div>
                    <div class="placeholder-glow luckyWinners__container-wrapper__item-winAmount">
                      <h1>
                        Receive <span className="placeholder">₹400</span>
                      </h1>
                      <span>Winning amount</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start Footer Area --> */}
      <footer class="footer-1">
        <div class="footer-area">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-7">
                <div class="footer-content logo-footer">
                  <div class="footer-head">
                    <div class="footer-logo">
                      <a class="footer-black-logo" href="#">
                        <img src="img/logo/logo2.png" alt="" />
                      </a>
                    </div>
                    <p class="text-white">
                      Replacing a maintains the amount of lines. When replacing
                      a selection. help agencies to define their new business
                      objectives and then create. Replacing a maintains the
                      amount of lines.
                    </p>
                    <div class="subs-feilds">
                      <div class="suscribe-input">
                        <input
                          type="email"
                          class="email form-control width-80"
                          id="sus_email"
                          placeholder="Type Email"
                        />
                        <button type="submit" id="sus_submit" class="add-btn">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Start Footer Bottom Area --> */}

        <div class="footer-area-bottom">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="copyright">
                  <p>
                    Copyright © 2024 <a href="#">Rocket Up</a> All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Footer Bottom Area --> */}
      </footer>

      <div class="tabbar__container">
        <a href="login" class="tabbar__container-item">
          <i class="bi bi-house"></i>
          <span>Home</span>
        </a>
        <a href="login" class="tabbar__container-item">
          <i class="bi bi-activity"></i>
          <span>Activity</span>
        </a>
        <a href="login" class="tabbar__container-item">
          <img src="favicon/logo-light.png" />
          <span>Promotion</span>
        </a>
        <a href="login" class="tabbar__container-item">
          <i class="bi bi-wallet"></i>
          <span>Wallet</span>
        </a>
        <a href="login" class="tabbar__container-item">
          <i class="bi bi-gear-wide-connected"></i>
          <span>Account</span>
        </a>
      </div>
    </>
  );
}
