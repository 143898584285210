import React, { useEffect, useState } from "react";
import Token from '../../../Helper/Token';
const Myprofile = () => {
   const [Info, SetInfo] = useState();
   const [btnloder, SetBTNLoder] = useState();
   useEffect(() => {
      SetBTNLoder(true)
      Token.get('/CheckUserDetails').then((res) => {
         if (res.status === 200 && parseInt(res.data.Status) === 1) { SetInfo(res.data.Payload[0]) } SetBTNLoder(false)
      }).catch((err) => { console.log(err); SetBTNLoder(false) })
   }, [])
   const NewDate = (e) => {
      let d = new Date(e);
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][d.getMonth()];
      return d.getDate() + '/' + months + '/' + d.getFullYear();
   }
   return (
      <>
         <div class="page-titles">
            <div class="sub-dz-head">
               <div class="d-flex align-items-center dz-head-title">
                  <h2 class="text-white m-0">Profile</h2>

               </div>
               <ol class="breadcrumb">
                  <li class="breadcrumb-item active ms-auto">
                     <a class="d-flex align-self-center">
                        <span>
                           <i class="bi bi-ui-checks"></i>
                        </span>
                        Profile
                     </a>
                  </li>
                  <li class="breadcrumb-item">
                     <a href="">Profile Element</a>
                  </li>
               </ol>
            </div>
         </div>
         <div class="content-body">
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-4 print-m-0">
                     <div class="clearfix">
                        <div class="card card-bx profile-card author-profile m-b30">
                           <div class="card-body py-3">
                              <div id="darc">

                                 <div class="team-card">
                                    <img src="/images/front.jpg" alt="" class="card-bg1" />
                                    <div class="card-logo">
                                       <img alt="" src="/images/logo-light.png" />
                                    </div>
                                    <div class="photo">
                                       <img alt="" src={`https://apinvshoppe.mnbsoft.co.in/UploadImage/${Info?.UserImg}`} />
                                    </div>
                                    <div class="name" id="content">
                                       <span id="ctl00_ContentPlaceHolder1_lblName">{Info?.Name}</span>
                                       <p class="status">INDEPENDENT REPRESENTATIVE</p>
                                    </div>

                                    <div class="post">
                                       ID :
                                       <span id="ctl00_ContentPlaceHolder1_lbluserid">{Info?.RegNo}</span>
                                    </div>
                                    <div class="post">
                                       ACTIVATION DATE :
                                       <span id="ctl00_ContentPlaceHolder1_lbldate">01 Oct 2023</span>
                                    </div>
                                    <div class="post">
                                       PHONE NO. : +91
                                       <span id="ctl00_ContentPlaceHolder1_lblmob">{Info?.MobileNo}</span>
                                    </div>


                                 </div>


                                 <div class="team-card mt-20">
                                    <img src="/images/back.jpg" alt="" class="card-bg1" />
                                    <div class="photo phos" >
                                       <img alt="" src="/images/logo-light.png" />
                                    </div>

                                    <div class="post">{Info?.Address},{Info?.District},{Info?.State},{Info?.Country},{Info?.PinCode}</div>
                                    <div class="post">{Info?.Email}</div>
                                    <div class="post">{Info?.MobileNo}</div>

                                    <div class="post">www.{document.location.host}</div>

                                 </div>

                              </div>




                           </div>

                        </div>
                     </div>
                  </div>
                  <div class=" col-lg-8">
                     <div class="card profile-card card-bx m-b30">
                        <div class="card-header">
                           <h4 class="card-title">Account</h4>
                        </div>
                        <form class="profile-form">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" >Name </label><input type="text" class="form-control form-control-sm" name="Name" id="Name" value={Info?.Name} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" >Email address </label><input type="text" class="form-control" value={Info?.Email} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" >Contact number </label><input type="number" class="form-control" value={Info?.MobileNo} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" >Created Date </label><input type="email" class="form-control" name="Email" id="Email" value={NewDate(Info?.DOJ)} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label">Expire date </label><input type="text" class="form-control" name="PinCode" value={Info?.PinCode} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" >Top Up Date </label><input type="email" class="form-control" id="Country" value={Info?.Country} readOnly /></div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="mb-3"><label class="form-label" > Address </label><input type="email" class="form-control" id="State" value={Info?.State} readOnly /></div>
                                 </div>

                              </div>
                           </div>

                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Myprofile;