import { useState, useEffect } from 'react';
import Token from '../../../Helper/Token';
import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
const PinTransfer = () => {
    const [BtnLoader, setBtnloder] = useState();
    const [loader, setLoader] = useState(false);
    const [message, setmessage] = useState({status: 0, message: ''});
    const [Pindetails, setPindetails] = useState([]);
    const [ReqData, setRequestData] = useState({SendTo: '',PinNo : '' })
    const SelectPin = (e) =>{
        setRequestData({...ReqData, PinNo : e })
        setmessage({status: '', message: ''})
    }
    const table = {
        columns: [
            {
                label: '',
                field: 'check',
                sort: 'asc',
                width: 150
            },
            {
                label: 'S.No',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Pin No',
                field: 'pinno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Package',
                field: 'package',
                sort: 'asc',
                width: 150
            }
        ],
        rows: []
    };
    const useridValidation = (e) => {
        setmessage({status: '', message: ''});
        e.currentTarget.value = e.currentTarget.value.slice(0,8);
        setRequestData({...ReqData, SendTo : e.currentTarget.value })
    }
    const getPin = () => {
        Token.get('/Pinfortransfer').then(res => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setPindetails(res.data.Payload); } setLoader(false)
        }).catch(err => { setLoader(false) })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getPin();
    }, [])
    Pindetails.map((tr, i) => {
        table.rows.push({
            id: i < 10 ? "0" + (i + 1) : (i + 1),
            check: <input type='radio' name="pin"onChange={() => SelectPin(tr.PinNo)}/>,
            pinno: tr.PinNo,
            package: tr.joinfor.toFixed(2)
        })
    })
    const Transfer = (e) => {
        e.preventDefault();
        setmessage({status: '', message: ''});
        if(ReqData.PinNo){
            setBtnloder(true)
            Token.post('/TransferPin',ReqData).then((res) => {
                if (res.status == 200 && parseInt(res.data.Status) === 1) { 
                    setmessage({status: 1, message: 'Pin Transfer Success.'}); getPin(); 
                    setRequestData({...ReqData, PinNo : '' });
                } else { setmessage({status: 2, message: res.data.Message}) }setBtnloder(false)
            }).catch((err) => { setmessage({status: '', message: 'Internal Server Error.'}); setBtnloder(false) })
        }else{ setmessage({status: 2, message: 'Please Select Pin.'}) }
    }
    console.log(ReqData)
    return (<>
        <div class="page-titles">
            <div class="sub-dz-head">
                <div class="d-flex align-items-center dz-head-title">
                    <h2 class="text-white m-0">Pin</h2>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active ms-auto">
                        <a class="d-flex align-self-center">
                            <span>
                                <i class="bi bi-ui-checks"></i>
                            </span>
                            Pin
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a>Transfer</a>
                    </li>
                </ol>
            </div>
        </div>
        <div class="content-body">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-xl-10 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> Transfer</h4>
                            </div>
                            <div class="card-body">
                                <div class="basic-form">
                                    <form onSubmit={Transfer}>
                                        <div class="mb-3 row">
                                            { message.status? message.status===1?<div class="alert alert-success" role="alert">{message.message}</div>:<div class="alert alert-danger" role="alert">{message.message}</div>:<></>}
                                        </div>
                                        <div class="mb-3 row">
                                            <div class="col-sm-2">
                                                <input type="text" class="form-control" id="pinno" placeholder="Pin No" value={ReqData.PinNo} readOnly/>
                                            </div>
                                            <div class="col-sm-5">
                                                <input type="text" class="form-control" id="userid" placeholder="Enter User Id"value={ReqData.SendTo} onChange={useridValidation} required/>
                                            </div>
                                            <div class="col-sm-2">
                                                {
                                                    BtnLoader?<button type="button" class="btn btn-primary btn-sm btn-lod" disabled><div class="spinner-border sz-3" role="status"></div> <span>Processing...</span></button>:<button type="submit" class="btn btn-primary Confirm-btn mb-2"> Transfer </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="table-responsive mt-5">
                                        {loader ?
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-8"></span>
                                            </p> :
                                            <MDBDataTable bordered small data={table} />
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default PinTransfer;