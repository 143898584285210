import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../../Helper/Token";
import { humanReadableTimeAndDate } from "../../../../Helper/function";
import TablePlaceholder from "../../../Components/TablePlaceholder";
import { useParams } from "react-router";

export default function WithdrawalReportAdmin() {
  const [WithdrawalReport, setWithdrawalReport] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const { status, payment_mode } = useParams();

  function fetchWithdrawalData() {
    setLoadingTable(true);
    token
      .get(
        `/admin/get-withdrawal-report/?status=${status}&payment_mode=${payment_mode}`
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          setWithdrawalReport(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoadingTable(false);
  }

  useEffect(() => {
    fetchWithdrawalData();
  }, [status, payment_mode]);

  function updateWithdrawalrewquest(requestId, response) {
    setLoadingTable(true);
    token
      .put(`/admin//update-withdrawal-request`, { requestId, response })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchWithdrawalData();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoadingTable(false);
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Converted Amount",
        field: "convertedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Date",
        field: "reqDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Response Date",
        field: "resDate",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  if (status == 0) {
    table?.columns.push({
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "bank") {
    table?.columns.splice(5, 0, {
      label: "Bank Name",
      field: "bankName",
      sort: "asc",
      width: 150,
    });
    table?.columns.splice(6, 0, {
      label: "Branch Name",
      field: "branchName",
      sort: "asc",
      width: 150,
    });
    table?.columns.splice(7, 0, {
      label: "Account Number",
      field: "accNum",
      sort: "asc",
      width: 150,
    });
    table?.columns.splice(8, 0, {
      label: "IFSC",
      field: "ifsc",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "upi") {
    table?.columns.splice(5, 0, {
      label: "UPI Address",
      field: "upiAddress",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "tron") {
    table?.columns.splice(5, 0, {
      label: "Tron Address",
      field: "kmtAddress",
      sort: "asc",
      width: 150,
    });

    table?.columns.splice(6, 0, {
      label: "Tron Rate",
      field: "kmtRate",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "usdt") {
    table?.columns.splice(5, 0, {
      label: "USDT Address",
      field: "usdtAddress",
      sort: "asc",
      width: 150,
    });
    table?.columns.splice(6, 0, {
      label: "USDT Rate",
      field: "usdtRate",
      sort: "asc",
      width: 150,
    });
  }

  if (status == 0) {
    table?.columns.splice(-2, 1);
  }

  WithdrawalReport?.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      userId: elem?.userId?.userId,
      name: elem?.userId?.name,
      amount: `₹ ${elem?.amount.toFixed(2)}`,
      convertedAmount:
        payment_mode == "tron"
          ? (elem?.amount / elem?.kmtRate).toFixed(2) + " Tron"
          : payment_mode == "usdt"
          ? (
              elem?.amount / elem?.usdtRate -
              ((elem?.amount / elem?.usdtRate) * 5) / 100
            ).toFixed(2) + " USDT"
          : payment_mode == "bank" || payment_mode == "upi"
          ? ` ₹ ${(elem?.amount - elem?.amount / 10).toFixed(2)}            `
          : null,
      bankName: elem?.bankName,
      branchName: elem?.branchName,
      accNum: elem?.accountNumber,
      ifsc: elem?.ifsc,
      usdtAddress: elem?.usdtAddress,
      upiAddress: elem?.upiAddress,
      kmtAddress: elem?.kmtAddress,
      usdtRate: elem?.usdtRate + " / INR",
      kmtRate: elem?.kmtRate + " / INR",
      reqDate: humanReadableTimeAndDate(elem?.createdAt),
      resDate:
        elem?.status != 0 ? humanReadableTimeAndDate(elem?.updatedAt) : null,

      action: (
        <>
          {" "}
          <button
            className="btn btn-success m-1"
            onClick={(e) => {
              e.preventDefault();
              updateWithdrawalrewquest(elem._id, 1);
            }}
          >
            Accept
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={(e) => {
              e.preventDefault();
              updateWithdrawalrewquest(elem._id, -1);
            }}
          >
            Reject
          </button>
        </>
      ),
    });
  });

  return (
    <>
      <div class="page-titles">
        <h1 className="text-light ">
          {" "}
          {status == 1
            ? "Approved"
            : status == 0
            ? "Pending"
            : status == -1
            ? "Rejected"
            : null}{" "}
          {payment_mode == "bank"
            ? "Bank"
            : payment_mode == "upi"
            ? "UPI"
            : payment_mode == "tron"
            ? "Tron"
            : payment_mode == "usdt"
            ? "USDT"
            : null}{" "}
          Withdrawal Report
        </h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>

                          <th className="border" scope="col">
                            Amount
                          </th>

                          <th className="border" scope="col">
                            Payment mode
                          </th>
                          <th className="border" scope="col">
                            Bank Name
                          </th>

                          <th className="border" scope="col">
                            Branch Name
                          </th>

                          <th className="border" scope="col">
                            Account Number
                          </th>
                          <th className="border" scope="col">
                            IFSC
                          </th>

                          <th className="border" scope="col">
                            USDT Address
                          </th>
                          <th className="border" scope="col">
                            UPI Address
                          </th>
                          <th className="border" scope="col">
                            Tron Address
                          </th>

                          <th className="border" scope="col">
                            Request Date
                          </th>
                          <th className="border" scope="col">
                            Respond Date
                          </th>
                          <th className="border" scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={13} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
