import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import token from "../../../../Helper/Token";
import TablePlaceholder from "../../../Components/TablePlaceholder";

export default function AllQuizQuestionReport() {
  const [questions, setQuestions] = useState([]);
  const [singleQuestion, setSingleQuestion] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuestions();
  }, []);

  function fetchQuestions() {
    setLoadingTable(true);
    token
      .get("/quiz-question/get-all-questions")
      .then((res) => {
        if (res.data.status == "success") {
          setQuestions(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  function deleteQuestion(questionId) {
    token
      .delete(`/quiz-question/delete-question/${questionId}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchQuestions();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Question Id",
        field: "questionId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Question",
        field: "question",
        sort: "asc",
        width: 150,
      },
      {
        label: "Difficulty Level",
        field: "difficultyLevel",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  questions.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      questionId: elem?.questionId,
      question:
        elem?.question.length > 80
          ? elem?.question.slice(0, 100) + "..."
          : elem?.question,
      difficultyLevel:
        elem?.difficultyLevel == 1
          ? "One"
          : elem?.difficultyLevel == 2
          ? "Two"
          : elem?.difficultyLevel == 3
          ? "Three"
          : elem?.difficultyLevel == 4
          ? "Four"
          : elem?.difficultyLevel == 5
          ? "Five"
          : elem?.difficultyLevel == 6
          ? "Six"
          : elem?.difficultyLevel == 7
          ? "Seven"
          : "",
      category: elem?.category?.name,
      action: (
        <>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => {
              document.getElementById("open-question-details").click();
              setSingleQuestion(elem);
            }}
          >
            View
          </button>
          <button
            className="btn btn-danger btn-sm m-1"
            onClick={(e) => {
              e.preventDefault();
              deleteQuestion(elem._id);
            }}
          >
            Delete
          </button>
        </>
      ),
    });
  });

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">All Questions Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Question Id
                            </th>
                            <th className="border" scope="col">
                              Question
                            </th>
                            <th className="border" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={4} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="open-question-details"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {singleQuestion?.questionId}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6> {singleQuestion?.question}</h6>

              <ul class="list-group ">
                <li class="list-group-item d-flex justify-content-between">
                  <strong>Option A</strong>
                  {singleQuestion.optionA}
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <strong>Option B</strong>
                  {singleQuestion.optionB}
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <strong>Option C</strong>
                  {singleQuestion.optionC}
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <strong>Option D</strong>
                  {singleQuestion.optionD}
                </li>
                <li class="list-group-item d-flex justify-content-between text-success">
                  <strong>Correct Answere</strong>
                  {singleQuestion.correctOption}
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary d-none"
                data-bs-dismiss="modal"
                id="close-question-details"
              >
                Close
              </button>

              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("close-question-details").click();
                  navigate(`/admin/edit-quiz/${singleQuestion?._id}`);
                }}
              >
                Edit
              </button>

              <button
                type="button"
                class="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  deleteQuestion(singleQuestion?._id);
                  document.getElementById("close-question-details").click();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
