import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { User, socket } from "../../Helper/Variables";
import { getUserIDFromToken } from "../../Helper/function";
import token from "../../Helper/Token";
import { toast } from "react-toastify";
const Header = () => {
  const [userdata, setUserData] = useState({});
  const location = useLocation();
  const [isOpens, setIsOpens] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const [diceReqData, setDiceReqData] = useState({
    senderName: "",
    amount: "",
    requestId: "",
  });

  const Auth = User();
  const Navigate = useNavigate();



  
  if (
    !sessionStorage.getItem("token") ||
    sessionStorage.getItem("token") === null
  ) {
    window.location.href = "/login";
  }

  const add = () => {
    setIsOpens(!isOpens);
  };
  const toggleNav = () => {
    setIsOpenNav(!isOpenNav);
  };

  useEffect(() => {
    if (location.pathname == "/user/DiceGame") {
      socket.emit("userCameBack", getUserIDFromToken());
    } else {
      setTimeout(() => {
        if (location.pathname != "/user/DiceGame") {
          socket.emit("userLeft", getUserIDFromToken());
        }
      }, 10000);
    }
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          setUserData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      setTimeout(() => {
        if (document.visibilityState === "hidden") {
          socket.emit("userLeft", getUserIDFromToken());
          socket.off("userLeft");
          socket.emit("leftFromWebsite", getUserIDFromToken());
          socket.off("leftFromWebsite");
        }
      }, 10000);
    } else {
      socket.emit("userCameBack", getUserIDFromToken());
      socket.off("userCameBack");
      socket.emit("cameBackOnWebsite", getUserIDFromToken());
      socket.off("cameBackOnWebsite");
    }
  };



  useEffect(() => {
    socket.on("broadcast-game-request", (requestObj) => {

      setDiceReqData({
        ...diceReqData,
        senderName: requestObj.senderId.name,
        amount: requestObj.amount,
        requestId: requestObj._id,
      });
      
      document?.querySelector(".diceGamePopUp")?.click();

      return () => {
        socket.off("broadcast-game-request");
      };
    });
  }, []);





  function responseToRequest(resp, requestId) {
    token
      .put("/dice/response-request", { response: resp, requestId: requestId })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });

          if (resp == 1 && res.data.message == "Game request accepted") {
            socket.emit("requestAccepted", requestId);

            Navigate("/user/DiceGame");
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div
        id="main-wrapper"
        className={` ${isOpenNav ? "active show menu-toggle " : " "}`}
      >
        <div
          className="header-banner"
          style={{ backgroundImage: "url(/images/bg-1.png)" }}
        ></div>
        <div className="header-nav">
          <div className="nav-header">
            <a className="brand-logo">
              <div className="brand-title">
                <Link to="/user/dashboard">
                  <img src="/images/logo-light.png" alt="" />
                </Link>
              </div>
            </a>
            <div className="nav-control">
              <div
                className={`hamburger ${isOpenNav ? "is-active " : ""}`}
                onClick={toggleNav}
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </div>
          </div>
          <div className="header">
            <div className="header-content">
              <nav className="navbar navbar-expand">
                <div className="collapse navbar-collapse justify-content-between">
                  <div className="d-flex justify-content-between">
                    <div className="header-left">
                      {/* <div className="input-group search-area">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here.."
                    />
                    <span className="input-group-text">
                      <i class="bi bi-search" style={{ color: "#fff" }}></i>
                    </span>
                  </div> */}
                    </div>
                    <ul className="navbar-nav header-right">
                      {/* <li className="nav-item dropdown notification_dropdown">
                      <a className="nav-link bell dz-theme-mode active">
                        <i
                          class="bi bi-moon-stars"
                          style={{ color: "#fff", cursor: "pointer" }}
                        ></i>
                      </a>
                    </li> */}
                      <li class="nav-item dropdown notification_dropdown">
                        <Link
                          class="nav-link "
                          to="/user/support"
                          role="button"
                        >
                          <i
                            class="bi bi-bell"
                            style={{ color: "#fff", cursor: "pointer" }}
                          ></i>
                        </Link>
                      </li>
                      <li class="nav-item dropdown notification_dropdown">
                        <Link to="/user/changpwd" class="nav-link me-0 ">
                          <i
                            class="fa fa-cog fa-spin"
                            style={{ color: "#fff", cursor: "pointer" }}
                          ></i>
                        </Link>
                      </li>
                      <div className="dropdown header-profile2">
                        <a
                          class="nav-link"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div class="header-info2 d-flex align-items-center">
                            <div class="d-flex align-items-center sidebar-info">
                              <div>
                                <h5 class="mb-0 text-white mb-1">
                                  {Auth?.Authorized?.user?.Name}
                                </h5>
                                <span class="d-block text-end">
                                  {Auth?.Authorized?.user?.RegNo}
                                </span>
                              </div>
                            </div>
                            <img src="/images/user.jpg" alt="" />
                          </div>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-end "
                          data-bs-popper="static"
                        >
                          <Link
                            to="/user/network/profile"
                            class="dropdown-item ai-icon "
                          >
                            <i class="bi bi-person-fill"></i>
                            <span class="ms-2">Profile </span>
                          </Link>
                          <Link
                            to="/user/support"
                            class="dropdown-item ai-icon "
                          >
                            <i class="bi bi-chat-square-quote-fill"></i>
                            <span class="ms-2">Message </span>
                          </Link>
                          <Link
                            to="/user/changpwd"
                            class="dropdown-item ai-icon "
                          >
                            <i class="bi bi-gear-fill"></i>
                            <span class="ms-2">Settings </span>
                          </Link>

                          <Link
                            to="/login"
                            onClick={() => {
                              // Perform any cleanup operations here
                              socket.emit(
                                "leftFromWebsite",
                                getUserIDFromToken()
                              );
                              Auth.Destroy();
                              sessionStorage.clear();
                            }}
                            className="dropdown-item ai-icon"
                          >
                            <i
                              className="bi bi-box-arrow-right"
                              style={{ color: "red" }}
                            ></i>
                            <span className="ms-2 text-danger">Logout</span>
                          </Link>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="dlabnav">
            {/* <div className="feature-box style-3">
            <div className="wallet-box">
              <i class="bi bi-wallet" style={{ color: "#fff" }}></i>
              <div className="ms-3">
                <h4 className="text-white mb-0 ">
                  ₹{Auth?.Authorized?.user?.TotalBalance?.toFixed(2)}
                </h4>
                <small>Balance</small>
              </div>
            </div>
          </div> */}
            <span className="main-menu">Main Menu</span>

            <div className="menu-scroll ">
              <div className="dlabnav-scroll">
                <ul class="metismenu pt-0 " id="menu">
                  {!userdata?.role ? (
                    <>
                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/user/dashboard">
                          <i class="bi bi-ui-checks-grid"></i>
                          <span class="nav-text">Dashboard</span>
                        </Link>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/user/dashboard#Personal"
                        >
                          <i class="bi bi-person-bounding-box"></i>
                          <span>Personal Details</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Personal"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/user/edit-profile">
                                Update profile
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/changpwd">Change Password</Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/transpwd">
                                Transaction Password
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/kyc">Update KYC</Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/user/DiceGame" className="glow">
                          <i class="bi bi-dice-6"></i>
                          <span class="nav-text">Play Dice Game</span>
                        </Link>
                      </li>

                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/user/quiz-home" className="glow">
                          <i class="bi bi-trophy"></i>
                          <span class="nav-text">Quiz</span>
                        </Link>
                      </li>

                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/user/aviator" className="glow">
                          <i class="bi bi-rocket-takeoff"></i>
                          <span class="nav-text">Aviator</span>
                        </Link>
                      </li>
                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/user/random-flight" className="glow">
                        <i class="bi bi-airplane"></i>
                          <span class="nav-text">Random Flight</span>
                        </Link>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/user/dashboard#Pin"
                        >
                          <i class="bi bi-controller"></i>
                          <span>Games</span>
                        </a>
                        <div class="accordion-collapse collapse" id="Pin" data-bs-parent="#menu">
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/user/DiceRequest">Dice Request</Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/dice/game-report">Game Report</Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/quiz-home">Quiz</Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/random-flight/report">Random Flight</Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/user/dashboard#Network"
                        >
                          <i class="bi bi-diagram-3-fill"></i>
                          <span>My Network</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Network"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/user/network/team">My Team </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/user/network/direct-team">
                                {" "}
                                Direct Team
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/user/dashboard#Earning"
                        >
                          <i class="bi bi-bar-chart"></i>
                          <span>Deposit</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Earning"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/fund/fund-request">
                                Deposit Request{" "}
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/fund/fund-request-report">
                                Deposit Report{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/user/dashboard#Purchase"
                        >
                          <i class="bi bi-wallet"></i>
                          <span>Withdrawal</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Purchase"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/fund/withdrawel-request">
                                Withdrawal Request{" "}
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/fund/withdrawel-report">
                                Withdrawel Report
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li class="" onClick={toggleNav}>
                        <Link to="/user/support">
                          <i class="bi bi-headset"></i>
                          <span class="nav-text">Support</span>
                        </Link>
                      </li>
                    </>
                  ) : null}
                  {userdata?.role == 1 ? (
                    <>
                      <li class="mm-active" onClick={toggleNav}>
                        <Link to="/admin/admin-dashboard">
                          <i class="bi bi-ui-checks-grid"></i>
                          <span class="nav-text">Admin Dashboard </span>
                        </Link>
                      </li>

                      <li onClick={toggleNav}>
                        <Link to="/admin/game-record">
                          <i class="bi bi-dice-6"></i>
                          <span class="nav-text">Dice Game Record </span>
                        </Link>
                      </li>
                      <li>
                        <a data-bs-toggle="collapse" aria-expanded="false" aria-current="page" class="side-nav-link has-arrow open  null active" href="/admin/dashboard#live-game-report">
                          <i class="bi bi-trophy"></i>
                          <span>Live Game Report</span>
                        </a>
                        <div class="accordion-collapse collapse" id="live-game-report" data-bs-parent="#menu">
                          <ul class="side-nav-second-level">
                            <li>
                              <Link to="/admin/all-quiz-questions">Dice</Link>
                            </li>
                            <li>
                              <Link to="/admin/all-quiz-categories">Quiz</Link>
                            </li>
                            <li>
                              <Link to="/admin/aviator-live-game-report">Aviator</Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="/admin/dashboard#Discount"
                        >
                          <i class="bi bi-journal-check"></i>
                          <span>Quiz Game</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Discount"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li>
                              <Link to="/admin/add-quiz">Add Quiz</Link>
                            </li>
                            <li>
                              <Link to="/admin/all-quiz-questions">
                                Quiz List
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin/all-quiz-categories">
                                Quiz Categories
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      
                      <li>
                        <a data-bs-toggle="collapse" aria-expanded="false" aria-current="page" class="side-nav-link has-arrow open  null active" href="/admin/dashboard#random-flight">
                        <i class="bi bi-airplane"></i>
                          <span>Random Flight</span>
                        </a>
                        <div class="accordion-collapse collapse" id="random-flight" data-bs-parent="#menu" >
                          <ul class="side-nav-second-level">
                            <li>
                              <Link to="/admin/random-flight/ticket-report">Ticket Report</Link>
                            </li>
                            <li>
                              <Link to="/admin/random-flight/flight-report">Flight Report</Link>
                            </li>
                            <li>
                              <Link to="/admin/random-flight/flight-landing-report">Flight Landing Report</Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li class="" onClick={toggleNav}>
                        <Link to="/admin/update-kyc">
                          <i class="bi bi-qr-code-scan"></i>
                          <span class="nav-text">Account Details </span>
                        </Link>
                      </li>

                      <li onClick={toggleNav}>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#news"
                        >
                          <i class="bi bi-person-bounding-box"></i>
                          <span>News Details</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="news"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/admin/news-form/0">Add News </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/news-report">News Report </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li onClick={toggleNav}>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#Users"
                        >
                          <i class="bi bi-person-bounding-box"></i>
                          <span>User Details</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="Users"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/admin/all-users-report">
                                Total Users{" "}
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/all-users-report/1">
                                Active Users{" "}
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/all-users-report/0">
                                Block Users{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#UsersKYC"
                        >
                          <i class="bi bi-fingerprint"></i>
                          <span>Update KYC</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="UsersKYC"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li>
                              <Link to="/admin/admin-kyc-report/0">
                                Pending KYC{" "}
                              </Link>
                            </li>

                            <li>
                              <Link to="/admin/admin-kyc-report/1">
                                Approved KYC
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin/admin-kyc-report/-1">
                                Rejected KYC
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#UsersDeposit"
                        >
                          <i class="bi bi-credit-card"></i>
                          <span>Deposit</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="UsersDeposit"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/1/upi">
                                All UPI Deposit
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/1/tron">
                                All Tron Deposit
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/1/usdt">
                                All USDT Deposit
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/0/upi">
                                Pending UPI Deposit{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/0/tron">
                                Pending Tron Deposit{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/0/usdt">
                                Pending USDT Deposit{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/-1/upi">
                                Rejected UPI Deposit{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/-1/tron">
                                Rejected Tron Deposit{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/fund-request-report/-1/usdt">
                                Rejected USDT Deposit{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#UsersWithdrawal"
                        >
                          <i class="bi bi-bank2"></i>
                          <span>Withdraw</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="UsersWithdrawal"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/1/bank">
                                All bank Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/1/upi">
                                All UPI Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/1/tron">
                                All Tron Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/1/usdt">
                                All USDT Withdrawal
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/0/bank">
                                Pending Bank Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/0/upi">
                                Pending UPI Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/0/tron">
                                Pending Tron Withdrawal
                              </Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/0/usdt">
                                Pending USDT Withdrawal
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/-1/bank">
                                Rejected Bank Withdrawal{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/-1/upi">
                                Rejected UPI Withdrawal{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/-1/tron">
                                Rejected Tron Withdrawal{" "}
                              </Link>
                            </li>

                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/-1/usdt">
                                Rejected USDT Withdrawal{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          aria-current="page"
                          class="side-nav-link has-arrow open  null active"
                          href="#UsersSupport"
                        >
                          <i class="bi bi-ticket"></i>
                          <span>Support</span>
                        </a>
                        <div
                          class="accordion-collapse collapse"
                          id="UsersSupport"
                          data-bs-parent="#menu"
                        >
                          <ul class="side-nav-second-level">
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/1">Inbox</Link>
                            </li>
                            <li onClick={toggleNav}>
                              <Link to="/admin/withdrawal-report/0">
                                Outbox
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li class="">
                        <Link
                          to="/login"
                          onClick={() => {
                            // Perform any cleanup operations here
                            socket.emit(
                              "leftFromWebsite",
                              getUserIDFromToken()
                            );
                            Auth.Destroy();
                            sessionStorage.clear();
                          }}
                        >
                          {" "}
                          <i class="bi bi-power"></i> Logout{" "}
                        </Link>
                      </li>
                    </>
                  ) : null}
                </ul>

                <ul className="metismenu pt-0 " id="menu">
                  {Auth?.Authorized?.user?.SId == 0 ? (
                    <li>
                      <a
                        className={`has-arrow ${isOpens ? "open" : "close"}`}
                        aria-expanded={isOpens ? "true" : "false"}
                        onClick={add}
                      >
                        <i class="bi bi-plus-lg"></i>
                        <span className="nav-text">Add</span>
                      </a>
                      {isOpens && (
                        <ul aria-expanded="true">
                          <li>
                            <Link to="/user/addpopup">Add Banner</Link>
                          </li>
                          <li>
                            <Link to="/user/addbusiness">
                              Add Business Plan
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>

                <div className="support-box">
                  <div className="header-profile2">
                    <img src="/images/user.jpg" alt=""></img>
                  </div>
                  <div className="info">
                    <p>Rocket UP </p>
                    <Link
                      to="/user/support"
                      className="btn bg-white text-black w-75 btn-sm"
                    >
                      Supports
                    </Link>
                  </div>
                </div>
                <div className="copyright">
                  <p>
                    {" "}
                    <strong>Rocket UP </strong> ©{" "}
                    <span className="current-year">2024</span> All Rights
                    Reserved{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />

      <div className="footer style-1" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="copyright">
          <p>
            {" "}
            Copyright © Designed &amp; Developed by{" "}
            <a href="https://mnbsoft.com/" target="blank">
              {" "}
              mnbsoft{" "}
            </a>{" "}
            <span className="current-year">2024</span>
          </p>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary diceGamePopUp"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        hidden
      ></button>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-light" id="staticBackdropLabel">
                Game Invite
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-light">
              {diceReqData.senderName} has sent you game request of ₹
              {diceReqData.amount}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success"
                onClick={() => {
                  responseToRequest(1, diceReqData.requestId);
                  document.querySelector("#close").click();
                }}
              >
                Accept
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={() => {
                  responseToRequest(-1, diceReqData.requestId);
                  document.querySelector("#close").click();
                }}
              >
                Reject
              </button>
              <button
                type="button"
                id="close"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                hidden
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="tabbar__container">
        <Link to="/user/network/team" class="tabbar__container-item">
          <i class="bi bi-people"></i>
          <span>Team</span>
        </Link>
        <Link to="/fund/fund-request" class="tabbar__container-item">
          <i class="bi bi-cash"></i>
          <span>Deposit</span>
        </Link>
        <Link to="/home-page" class="tabbar__container-item">
          <img src="../favicon/logo-light.png" />
          <span>Home</span>
        </Link>
        <Link to="/user/dashboard" class="tabbar__container-item">
          <i class="bi bi-wallet"></i>
          <span>Wallet</span>
        </Link>
        <Link to="/user/support" class="tabbar__container-item">
          <i class="bi bi-gear-wide-connected"></i>
          <span>Account</span>
        </Link>
      </div>
    </>
  );
};

export default Header;
