import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../../Helper/Token";
import TablePlaceholder from "../../../Components/TablePlaceholder";

export default function AllQuizCategoryReport() {
  const [category, setCategories] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [addCategoryForm, setAddCategoryForm] = useState({
    categoryId: "",
    name: "",
    description: "",
    image: "",
    isActive: 1,
  });
  const [validateAddCategoryForm, setValidateAddCategoryForm] = useState({
    name: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    fetchQuizCategoris();
  }, []);

  function fetchQuizCategoris() {
    setLoadingTable(true);
    token
      .get("/quiz-category/get-all-category")
      .then((res) => {
        console.log("RESPONSE DATA =>", res.data)
        if (res.data.status == "success") {
          setCategories(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  function deleteCategory(categoryId) {
    token
      .delete(`/quiz-category/delete-category/${categoryId}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchQuizCategoris();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function toggleCategoryStatus(categoryId, isActive) {
    token
      .put("/quiz-category/update-category-status", { categoryId, isActive })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchQuizCategoris();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function addNewCategory(e) {
    e.preventDefault();

    if (addCategoryForm.categoryId) {
      updateCategory();
      return;
    }

    if (
      !addCategoryForm.name ||
      !addCategoryForm.description ||
      !addCategoryForm.image
    ) {
      setValidateAddCategoryForm({
        ...validateAddCategoryForm,
        name: addCategoryForm?.name ? "" : "Category name is required",
        description: addCategoryForm?.description
          ? ""
          : "Description is required",
        image: addCategoryForm?.image ? "" : "Category image is required",
      });

      return;
    }

    const formInfo = new FormData();

    formInfo.append("name", addCategoryForm.name);
    formInfo.append("description", addCategoryForm.description);
    formInfo.append("image", addCategoryForm.image);

    token
      .post("/quiz-category/add-category", formInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          setAddCategoryForm({
            name: "",
            description: "",
          });
          document.getElementById("close-add-category-form").click();
          fetchQuizCategoris();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateCategory() {
    if (!addCategoryForm.name || !addCategoryForm.description) {
      setValidateAddCategoryForm({
        ...validateAddCategoryForm,
        name: addCategoryForm.name ? "" : "Category name is required",
        description: addCategoryForm.description
          ? ""
          : "Description is required",
      });

      return;
    }
    const formInfo = new FormData();
    formInfo.append("name", addCategoryForm.name);
    formInfo.append("description", addCategoryForm.description);
    formInfo.append("image", addCategoryForm.image);
    formInfo.append("categoryId", addCategoryForm.categoryId);
    token
      .put("/quiz-category/update-category", formInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          setAddCategoryForm({
            name: "",
            description: "",
          });
          document.getElementById("close-add-category-form").click();
          fetchQuizCategoris();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Question",
        field: "totalQuestion",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  category?.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      category: elem?.name,
      image: (
        <img
          alt="Category-image"
          src={""}
          height={50}
        />
      ),
      totalQuestion:elem?.totalQuestion,
      status:
        elem?.isActive == 1 ? (
          <>
            <div class="form-check form-switch">
              <input
                class="form-check-input mt-2"
                type="checkbox"
                role="switch"
                checked={elem.isActive ? true : false}
                onChange={(e) => {
                  e.preventDefault();
                  toggleCategoryStatus(elem._id, 0);
                }}
              />
              <label
                class="form-check-label text-success"
                for="flexSwitchCheckDefault"
              >
                Active
              </label>
            </div>
          </>
        ) : (
          <>
            <div class="form-check form-switch">
              <input
                class="form-check-input mt-2"
                type="checkbox"
                role="switch"
                checked={elem.isActive == 1 ? true : false}
                onChange={(e) => {
                  e.preventDefault();
                  toggleCategoryStatus(elem._id, 1);
                }}
              />
              <label
                class="form-check-label text-danger"
                for="flexSwitchCheckDefault"
              >
                Inactive
              </label>
            </div>
          </>
        ),

      action: (
        <>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => {
              setAddCategoryForm({
                categoryId: elem?._id,
                name: elem?.name,
                description: elem?.description,
                image: elem?.image,
                isActive: elem?.isActive,
              });
              document.getElementById("open-question-details").click();
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm m-1"
            onClick={(e) => {
              e.preventDefault();
              deleteCategory(elem._id);
            }}
          >
            Delete
          </button>
        </>
      ),
    });
  });

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">All Category Report</h1>
      </div>
      <div className="d-flex justify-content-end mx-5">
        <button
          className="btn btn-success "
          onClick={() => {
            setAddCategoryForm({ name: "", description: "", categoryId: "" });
            document.getElementById("open-question-details").click();
          }}
        >
          Add Category
        </button>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Category
                            </th>
                            <th className="border" scope="col">
                              Desciption
                            </th>
                            <th className="border" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={4} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="open-question-details"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {addCategoryForm?.categoryId
                  ? "Update Category"
                  : " Add New Category"}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={addNewCategory}>
                <label htmlFor="category-name" class="text-light mb-2">
                  Category Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={addCategoryForm.name}
                  placeholder="Enter category name"
                  onChange={(e) => {
                    setAddCategoryForm({
                      ...addCategoryForm,
                      name: e.target.value,
                    });

                    setValidateAddCategoryForm({
                      ...validateAddCategoryForm,
                      name: "",
                    });
                  }}
                />
                <p className="text-center text-danger">
                  {validateAddCategoryForm.name}
                </p>

                <label htmlFor="category-image" class="text-light mb-2">
                  Category Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  class="form-control form-control-sm"
                  id="category-image"
                  onChange={(e) => {
                    setAddCategoryForm({
                      ...addCategoryForm,
                      image: e.target.files[0],
                    });
                  }}
                />

                <p className="text-center text-danger">
                  {validateAddCategoryForm.image}
                </p>

                {addCategoryForm?.categoryId ? (
                  <div className="d-flex justify-content-center mt-5 mb-3">
                    <img
                      alt="Category-Image"
                      src={""}
                      height={120}
                    />
                  </div>
                ) : null}

                <label htmlFor="category-name" className="text-light mt-4 mb-2">
                  Description
                </label>
                <textarea
                  rows={4}
                  type="text"
                  className="form-control"
                  value={addCategoryForm.description}
                  placeholder="Enter category description"
                  onChange={(e) => {
                    setAddCategoryForm({
                      ...addCategoryForm,
                      description: e.target.value,
                    });
                    setValidateAddCategoryForm({
                      ...validateAddCategoryForm,
                      description: "",
                    });
                  }}
                ></textarea>
                <p className="text-center text-danger">
                  {validateAddCategoryForm.description}
                </p>
                <input
                  type="submit"
                  className="btn btn-primary mt-4"
                  value={
                    addCategoryForm?.categoryId
                      ? "Update Category"
                      : "Add Category"
                  }
                />
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary d-none"
                data-bs-dismiss="modal"
                id="close-add-category-form"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
