export default function UserFlycrickFlightCardPlaceholder() {
  return (
    <>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div class="col-md-4 col-sm-4 col-12">
          <div class="card card-box pull-up">
            <div class="card-header   border-0">
              <h6 class="mb-0">
                <img src="/flighticon.png" className="planelogo" alt="" />
                Flycrick <span className="font-w300"></span>{" "}
              </h6>

              <div className="placeholder-glow d-flex">
                <button
                  class="btn button-9 btn-success disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Booked
                </button>{" "}
                <button
                  class="btn button-9 btn-primary disabled btn-sm placeholder m-1"
                  aria-disabled="true"
                >
                  Book Now
                </button>
              </div>
            </div>

            <div class="card-body  pt-3 px-3">
              <div class="row alig">
                <div class="col">
                  <span class="text-light">From</span>
                  <div class=" border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-light">Departure</span>
                  <div class=" border-0 pt-1">
                    <span class=" text-warning">
                      <span class="card-text placeholder-glow">
                        <span class="placeholder col-10"></span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="col text-end  ">
                  <span class="text-light">To</span>
                  <div class="border-0 text-light pt-1 fs-16 font-w600">
                    <span class="card-text placeholder-glow">
                      <span class="placeholder col-10"></span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="Departure placeholder-glow">
                <span class="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
