import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import token from "../../Helper/Token";
import {
  characterValidation,
  CheckPhoneNo,
  emailValidation,
  passwordValidation,
} from "../../Helper/function";

const Signup = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [sponserRes, setSponserRes] = useState({ status: "", name: "" });

  const [formData, setFormData] = useState({
    sponserId: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    transactionPassword: "",
    confirmTransactionPassword: "",
  });

  const [validationObj, setValidationObj] = useState({
    sponserId: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    transactionPassword: "",
    confirmTransactionPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    showPassword: "password",
    showConfirmPassword: "password",
    showTransactionPassword: "password",
    showConfirmTransactionPassword: "password",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      setFormData({ ...formData, sponserId: params.id });
    }
  }, []);

  useEffect(() => {
    if (formData.sponserId.length > 7) {
      token
        .get(`/user/sponser-name/${formData.sponserId}`)

        .then((res) => {
          setSponserRes({ status: res.data.status, name: res.data.data.name });
        })
        .catch((error) => {
          toast.error("Internal Server Error.", {
            position: "top-right",
            autoClose: 900,
          });
        });
    }
  }, [formData.sponserId]);

  function registerUser(e) {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }
    setLoading(true);
    token
      .post(`/user/register`, formData)

      .then((res) => {
        console.log("RESPONSE DATA =>", res.data);

        if (res.data.status != "success") {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
          return;
        }

        toast.success(res.data.message, {
          position: "top-left",
          autoClose: 900,
        });
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoading(false);
  }

  function formValidation() {
    if (!formData?.email) {
      setValidationObj((prev) => ({ ...prev, email: "Email is required" }));
      return false;
    } else if (!emailValidation(formData?.email)) {
      setValidationObj((prev) => ({ ...prev, email: "Invalid email" }));
      return false;
    } else if (!formData?.phone) {
      setValidationObj((prev) => ({
        ...prev,
        phone: "Phone number is required",
      }));
      return false;
    } else if (!formData?.phone?.length == 10) {
      setValidationObj((prev) => ({ ...prev, phone: "Invalid phone number" }));
      return false;
    } else if (!formData?.name) {
      setValidationObj((prev) => ({ ...prev, name: "Name is required" }));
      return false;
    } else if (!formData?.password) {
      setValidationObj((prev) => ({
        ...prev,
        password: "Password is required",
      }));
      return false;
    } else if (!formData?.confirmPassword) {
      setValidationObj((prev) => ({
        ...prev,
        confirmPassword: "Confirm password is required",
      }));
      return false;
    } else if (formData?.password != formData?.confirmPassword) {
      setValidationObj((prev) => ({
        ...prev,
        confirmPassword: "Confim password does not match",
      }));
      return false;
    }else{
      return true;
    }

    // if (
    //   !formData.email ||
    //   !formData.password ||
    //   !formData.sponserId ||
    //   !formData.name ||
    //   !formData.confirmPassword ||
    //   !formData.transactionPassword ||
    //   !formData.confirmTransactionPassword
    // ) {
    //   setValidationObj({
    //     email: formData.email ? "" : "Email is required",
    //     password: formData.password ? "" : "Password is required",
    //     sponserId: formData.sponserId ? "" : "Sponser id is required",
    //     name: formData.name ? "" : "Name is required",
    //     confirmTransactionPassword: formData.confirmTransactionPassword
    //       ? ""
    //       : "Confirm transaction password is required",
    //     transactionPassword: formData.transactionPassword
    //       ? ""
    //       : "Transaction password is required",
    //     confirmPassword: formData.confirmPassword
    //       ? ""
    //       : "Confirm Password is required",
    //   });
    //   return false;

    // }

    // if (formData.password != formData.confirmPassword) {
    //   setValidationObj({
    //     ...validationObj,
    //     confirmPassword: "Confirm password does not match",
    //   });
    //   return false;
    // }

    // if (formData.transactionPassword != formData.confirmTransactionPassword) {
    //   setValidationObj({
    //     ...validationObj,
    //     transactionPassword: "Confirm transaction password does not match",
    //   });
    //   return false;
    // }

    // const transactionPasswordRes = passwordValidation(
    //   formData.transactionPassword
    // );
    // if (transactionPasswordRes) {
    //   setValidationObj({
    //     ...validationObj,
    //     transactionPassword: transactionPasswordRes,
    //   });
    //   return false;
    // }

    // if (!emailValidation(formData.email)) {
    //   setValidationObj({ ...validationObj, email: "Invalid email" });
    //   return false;
    // }

    // const passwordRes = passwordValidation(formData.password);
    // if (passwordRes) {
    //   setValidationObj({ ...validationObj, password: passwordRes });
    //   return false;
    // }

    // if (sponserRes.status != "success") {
    //   setValidationObj({ ...validationObj, sponserId: "Invalid sponser id" });
    //   setSponserRes({ ...sponserRes, name: "" });
    //   return;
    // }

    // return true;
  }

  return (
    <>

<header class="header-one">       
        {/* <!-- mobile-menu-area start --> */}
        <div class="mobile-menu-area hidden-lg hidden-md hidden-sm">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu">
                  <div class="logo">
                    <a href="">
                      <img src="img/logo/logo2.png" alt="" />
                    </a>
                  </div>
                  <div class="header-right-link">
                  <Link to={"/login"} class="s-menu-2">Login now</Link>
                  <Link to={"/sign-up"} class="s-menu">Join now</Link>                  
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- mobile-menu-area end --> */}
      </header>

      <div class="authincation d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="login-aside text-center  d-flex flex-column flex-row-auto">
          <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center mb-lg-4 mb-2 pt-5 logo-login">
              <Link to="/">
                <img src="images/logo-light.png" style={{ height: "55px" }} />
              </Link>
            </div>
            <h3 class="mb-2 text-white">Welcome Back!</h3>
            <p class="mb-4"> Play Online Game & Win Money Unlimited </p>
          </div>
          <div
            class="aside-image position-relative"
            style={{ backgroundImage: "url(images/background/pic-2.png)" }}
          >
            <img class="img1 move-1" src="images/background/pic3.png" alt="" />
            <img class="img2 move-2" src="images/background/pic4.png" alt="" />
            <img class="img3 move-3" src="images/background/pic5.png" alt="" />
          </div>
        </div>
        <div class="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto login-body">
          <div class="d-flex justify-content-center h-100 align-items-center">
            <div class="authincation-content style-2">
              <div class="row no-gutters">
                <div class="col-xl-12 tab-content">
                  <div
                    id="sign-up"
                    class="auth-form tab-pane fade show active  form-validation"
                  >
                    <form onSubmit={registerUser}>
                      <div class="text-center mb-4 mt-4">
                        <h3 class="text-center mb-2 text-light">Register Now</h3>
                      </div>
                      <div class="mb-3">
                        <label
                          htmlFor="sponser-id"
                          class="form-label text-light"
                        >
                          Invite Code
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="sponser-id"
                          placeholder="Enter your invite id"
                          value={formData.sponserId}
                          readOnly={params.id ? "readOnly" : null}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              sponserId: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              sponserId: "",
                            });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.sponserId}
                        </p>
                        {sponserRes.status == "success" ? (
                          <p className="text-center text-success">
                            {sponserRes.name}
                          </p>
                        ) : (
                          <p className="text-center text-danger">
                            {sponserRes.name}
                          </p>
                        )}
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="email-id"
                          class="form-label text-light required"
                        >
                          Email Id
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="email-id"
                          placeholder="Enter your email"
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            });
                            setValidationObj({ ...validationObj, email: "" });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.email}
                        </p>
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="phone"
                          class="form-label text-light required"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="phone"
                          name="phone"
                          placeholder="Enter your phone"
                          value={formData.phone}
                          onChange={(e) => {
                            const temp = CheckPhoneNo(e);

                            if (temp) {
                              setValidationObj((prev) => ({
                                ...prev,
                                phone: temp,
                              }));
                            } else {
                              setValidationObj((prev) => ({
                                ...prev,
                                phone: null,
                              }));

                              setFormData((prev) => ({
                                ...prev,
                                phone: e.target.value,
                              }));
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.phone}
                        </p>
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="name"
                          class="form-label text-light required"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="name"
                          placeholder="Enter your name"
                          value={formData.name}
                          onChange={(e) => {
                            if (!characterValidation(e)) {
                              setValidationObj({
                                ...validationObj,
                                name: "Only characters are allowed",
                              });
                            } else {
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              });
                              setValidationObj({ ...validationObj, name: "" });
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.name}
                        </p>
                      </div>

                      <div class="position-relative ">
                        <label
                          htmlFor="password"
                          class="form-label text-light required"
                        >
                          Password
                        </label>
                        <input
                          type={showPasswords.showPassword}
                          id="password"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.password}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              password: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.showPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <p className="text-center text-danger">
                        {validationObj.password}
                      </p>

                      <div class="position-relative">
                        <label
                          htmlFor="confirmPassword"
                          class="form-label text-light required"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={showPasswords.showConfirmPassword}
                          id="confirmPassword"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.confirmPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              confirmPassword: e.target.value,
                            });

                            setValidationObj({
                              ...validationObj,
                              confirmPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.showConfirmPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showConfirmPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showConfirmPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <p className="text-center text-danger">
                        {validationObj.confirmPassword}
                      </p>

                      {/* <div class="position-relative ">
                        <label
                          htmlFor="transactionPassword"
                          class="form-label text-light required"
                        >
                          Transaction Password
                        </label>
                        <input
                          type={showPasswords.showTransactionPassword}
                          id="transactionPassword"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.transactionPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              transactionPassword: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              transactionPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.showTransactionPassword !=
                          "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showTransactionPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showTransactionPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <p className="text-center text-danger">
                        {validationObj.transactionPassword}
                      </p> */}

                      {/* <div class="position-relative">
                        <label
                          htmlFor="confirmTransactionPassword"
                          class="form-label text-light required"
                        >
                          Confirm Transaction Password
                        </label>
                        <input
                          type={showPasswords.showConfirmTransactionPassword}
                          id="confirmTransactionPassword"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.confirmTransactionPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              confirmTransactionPassword: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              confirmTransactionPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.showConfirmTransactionPassword !=
                          "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showConfirmTransactionPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  showConfirmTransactionPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <p className="text-center text-danger">
                        {validationObj.confirmTransactionPassword}
                      </p> */}


                      <div className=" mt-7 mt-lg-8">
                        {loading ? (
                          <button
                            class="btn btn-primary btn-block"
                            type="button"
                            disabled
                          >
                            <span class="spinner-border spinner-border-sm me-2"></span>
                            <span>Loading...</span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            name="submit"
                            id="submit"
                          >
                            Register
                          </button>
                        )}
                      </div>
                      <div className="text-center mt-3">
                        <p class="text-light">Already have an account? <Link to="/login" class="text-success">
                          Sign in
                        </Link></p>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
