import React, { useState } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../../../Helper/Token";
import { isNumeric } from "../../../Helper/function";

export default function FundTransfer() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    amount: 0,
    recieverId: "",
    remark: "",
  });
  const [validationObj, setValidationObj] = useState({
    amount: "",
    recieverId: "",
    remark: "",
  });

  function transferFund(e) {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    setLoading(true);
    token
      .post("/fund-transfer/fund-transfer", formData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
        setLoading(false);
      });
  }

  function formValidation() {
    if (!formData.amount || !formData.remark || !formData.recieverId) {
      setValidationObj({
        amount: formData.amount ? "" : "Amount is required",
        remark: formData.remark ? "" : "Remark is required",
        recieverId: formData.recieverId ? "" : "Reciever id is required",
      });
      return false;
    }
    return true;
  }

  return (
    <>
      <div class="authincation d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="login-aside text-center  d-flex flex-column flex-row-auto">
          <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center mb-lg-4 mb-2 pt-5 logo">
              <Link to="/">
                <img src="images/logo-light.png" style={{ height: "55px" }} />
              </Link>
            </div>
            <h3 class="mb-2 text-white">Welcome back!</h3>
            <p class="mb-4">
              Let's get started. <br />
              Shop With Us And Change Your Life
            </p>
          </div>
          <div
            class="aside-image position-relative"
            style={{ backgroundImage: "url(images/background/pic-2.png)" }}
          >
            <img class="img1 move-1" src="images/background/pic3.png" alt="" />
            <img class="img2 move-2" src="images/background/pic4.png" alt="" />
            <img class="img3 move-3" src="images/background/pic5.png" alt="" />
          </div>
        </div>
        <div class="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div class="d-flex justify-content-center h-100 align-items-center">
            <div class="authincation-content style-2">
              <div class="row no-gutters">
                <div class="col-xl-12 tab-content">
                  <div
                    id="sign-up"
                    class="auth-form tab-pane fade show active  form-validation"
                  >
                    <form onSubmit={transferFund}>
                      <div class="text-center mb-4">
                        <h3 class="text-center mb-2 text-light">
                          Fund Transfer
                        </h3>
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="amount"
                          class="form-label text-light required"
                        >
                          Amount
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="amount"
                          placeholder="Enter the amount"
                          defaultValue={formData.amount}
                          onChange={(e) => {
                            if (isNumeric(e.target.value)) {
                              setFormData({
                                ...formData,
                                amount: e.target.value,
                              });
                              setValidationObj({
                                ...validationObj,
                                amount: "",
                              });
                            } else {
                              setValidationObj({
                                ...validationObj,
                                amount: "Only numbers are allowed",
                              });
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.value.length - 1
                              );
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.amount}
                        </p>
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="transactionId"
                          class="form-label text-light required"
                        >
                          Reciever Id
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="transactionId"
                          placeholder="Enter the transaction id"
                          onChange={(e) => {
                            setValidationObj({
                              ...validationObj,
                              recieverId: "",
                            });
                            setFormData({
                              ...formData,
                              recieverId: e.target.value,
                            });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.recieverId}
                        </p>
                      </div>

                      <div class="mb-3">
                        <label
                          htmlFor="remark"
                          class="form-label text-light required"
                        >
                          Remark
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          id="remark"
                          placeholder="Enter the transaction id"
                          onChange={(e) => {
                            setValidationObj({ ...validationObj, remark: "" });
                            setFormData({
                              ...formData,
                              remark: e.target.value,
                            });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.remark}
                        </p>
                      </div>

                      <div className=" mt-5 mt-lg-8">
                        {loading ? (
                          <button
                            class="btn btn-primary btn-block"
                            type="button"
                            disabled
                          >
                            <span class="spinner-border spinner-border-sm me-2"></span>
                            <span>Loading...</span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            name="submit"
                            id="submit"
                          >
                            Transfer Fund
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
