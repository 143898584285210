import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../../Helper/Token";
import TablePlaceholder from "../../../Components/TablePlaceholder";
import { Link, useParams } from "react-router-dom";

export default function AllUserReportByStatus() {
  const [playersData, setPlayersData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoadingTable(true);
    token
      .get(`/admin/user-by-status/${id}`)
      .then((res) => {
        if (res.data.status == "success") {
          setPlayersData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, [id]);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sponser Id",
        field: "sponserId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Deposit",
        field: "totalDeposit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Withdrawal",
        field: "totalWithdrawal",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available Balance",
        field: "availableBalance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Details",
        field: "details",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  playersData.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      name: elem?.name,
      userId: elem?.userId,
      sponserId: elem?.sponserId?.userId
        ? elem?.sponserId?.userId
        : elem?.reffrenceId,
      totalDeposit: elem?.totalDeposite.toFixed(2) + " INR",
      totalWithdrawal: elem?.totalWithdrawal.toFixed(2) + " INR",
      availableBalance: elem?.availableBalance.toFixed(2) + " INR",
      status:
        elem?.status === 0 ? (
          <p className="text-danger">Blocked</p>
        ) : (
          <p className="text-success">Unblocked</p>
        ),
      details: (
        <Link to={`/admin/single-user-report/${elem?._id}`}>
          <p className="text-warning">Details</p>
        </Link>
      ),
    });
  });

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">Fund Transf Fund Transfer Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Name
                            </th>
                            <th className="border" scope="col">
                              User Id
                            </th>
                            <th className="border" scope="col">
                              Sponser Id
                            </th>
                            <th className="border" scope="col">
                              Total Deposite
                            </th>

                            <th className="border" scope="col">
                              Total Withdrawal
                            </th>
                            <th className="border" scope="col">
                              Available Balance
                            </th>
                            <th className="border" scope="col">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={8} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
