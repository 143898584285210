import { useEffect, useState } from "react";

import { MDBDataTable } from "mdbreact";
import { socket } from "../../../Helper/Variables";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";

export default function AviatorLiveGameReport() {
  const [tableData, setTableData] = useState([]);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Game Id",
        field: "gameId",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },

      {
        label: "1st Bet Amount",
        field: "betAmountA",
        sort: "asc",
        width: 150,
      },

      {
        label: "2nd Bet Amount",
        field: "betAmountB",
        sort: "asc",
        width: 150,
      },

      {
        label: "1st Winning Amount",
        field: "winningAmountA",
        sort: "asc",
        width: 150,
      },
      {
        label: "2nd Winning Amount",
        field: "winningAmountB",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  tableData?.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      gameId: elem?.gameId,
      userId: elem?.userId,
      betAmountA:
        elem?.betA < 10
          ? "₹0" + elem?.betA?.toFixed(2)
          : "₹" + elem?.betA?.toFixed(2),
      betAmountB:
        elem?.betB < 10
          ? "₹0" + elem?.betB?.toFixed(2)
          : "₹" + elem?.betB?.toFixed(2),
      winningAmountA:
        elem?.winA < 10
          ? "₹0" + elem?.winA?.toFixed(2)
          : "₹" + elem?.winA?.toFixed(2),
      winningAmountB:
        elem?.winB < 10
          ? "₹0" + elem?.winB?.toFixed(2)
          : "₹" + elem?.winB?.toFixed(2),
    });
  });

  useEffect(() => {
    fetchLiveBetReportData();
  }, []);

  function fetchLiveBetReportData() {
    token
      .get("/aviator/get-last-game-record")
      .then((res) => {
        if (res?.data?.status == "success") {
          setTableData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  useEffect(() => {
    socket.on("update_aviator_live_bet_record", () => {
      fetchLiveBetReportData();
      return () => {
        socket.off("update_aviator_live_bet_record");
      };
    });
  }, []);

  return (
    <>
      <div class="page-titles">
        <h1 className="text-light">Dice Game Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  <div class="table table-responsive mbdtable">
                    <MDBDataTable bordered small data={table} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
