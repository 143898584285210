import { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { humanReadableDate, humanReadableTimeAndDate } from "../../../Helper/function";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";

export default function FundRequesReport() {
  const [fundRequest, setFundRequest] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  function fetchRequestData() {
    setLoadingTable(true);
    token
      .get("/fund-request/fund-request")
      .then((res) => {
        if (res.data.status == "success") {
          setFundRequest(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  useEffect(() => {
    fetchRequestData();
  }, []);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Mode",
        field: "paymentMode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Converted Amount",
        field: "convertedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Transaction Id",
        field: "transactionId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Date",
        field: "requestDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Respond Date",
        field: "respondDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remark",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  function requestStatus(status) {
    if (status == -1) {
      return <p className="text-danger">Rejected</p>;
    } else if (status == 1) {
      return <p className="text-success">Accepted</p>;
    } else {
      return <p className="text-warning">Pending</p>;
    }
  }

  fundRequest.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      paymentMode: (elem?.paymentMode).toUpperCase(),
      convertedAmount:
        elem?.paymentMode == "upi"
          ? `₹ ${elem?.amount + (elem?.amount * 5) / 100}`
          : elem?.paymentMode == "usdt"
            ? `₹ ${elem?.amount * elem?.usdtRate +
            (elem?.amount * elem?.usdtRate) / 10
            }`
            : elem?.paymentMode == "kmt"
              ? `₹ ${elem?.amount * elem?.kmtRate +
              (elem?.amount * elem?.kmtRate * 15) / 100
              }`
              : null,
      amount:
        elem?.paymentMode == "upi"
          ? `₹ ${elem?.amount}`
          : elem?.paymentMode == "kmt"
            ? `${elem?.amount} KMT`
            : elem?.paymentMode == "usdt"
              ? `${elem?.amount} USDT`
              : null,
      transactionId: elem.transactionId,
      requestDate: humanReadableDate(elem.createdAt),
      respondDate:
        elem.status != 0 ? humanReadableDate(elem.updatedAt) : null,
      status: requestStatus(elem.status),
      action: elem.remark,
    });
  });

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <h1 className="text-light">Fund Deposit Report</h1>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Amount
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Payment Mode
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Converted Amount
                          </th>
                          <th className="border" scope="col">
                            Transaction Id
                          </th>
                          <th className="border" scope="col">
                            Request Date
                          </th>
                          <th className="border" scope="col">
                            Respond Date
                          </th>
                          <th className="border" scope="col">
                            Status
                          </th>
                          <th className="border" scope="col">
                            Remark
                          </th>
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={9} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
