import { io } from "socket.io-client";
import { getUserIDFromToken } from "../function/Helper.function";
let socketId = "";
if (sessionStorage.getItem("token")) {
  socketId = {
    query: {
      userId: getUserIDFromToken(),
    },
  };
}


export const baseHeader = {
  "Content-Type": "application/json",
  Authorization: sessionStorage.getItem("token"),
};
