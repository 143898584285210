import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { countryCodes } from "../../../App/Pages/countryCodes";
import token from "../../../Helper/Token";
import {
  CheckPhoneNo,
  CheckPinCode,
  characterValidation,
  emailValidation,
} from "../../../Helper/function";
const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    pinCode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    countryCode: "",
  });

  const [validationObj, setValidationObj] = useState({
    name: "",
    email: "",
    phone: "",
    pinCode: "",
    address: "",
    city: "",
    state: "",
  });

  useEffect(() => {
    setLoading(true);
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          setFormData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoading(false);
  }, []);


  function updateProfile(e) {
    e.preventDefault();
    if (!formValidation()) {
      return;
    }

    setLoading(true);
    token
      .put("/user/update-profile", formData)
      .then((res) => {
        if (res.data.status == "success") {
          setFormData(res.data.data);
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoading(false);
  }





  function formValidation() {
    if (!emailValidation(formData.email)) {
      setValidationObj({ ...validationObj, email: "Invalid email" });
      return false;
    }

    if (formData.phone.length < 10) {
      setValidationObj({ ...validationObj, phone: "Invalid phone number" });
      return false;
    }

    return true;
  }

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Profile</h2>
            </div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active ms-auto">
                <a class="d-flex align-self-center">
                  <span>
                    <i class="bi bi-ui-checks"></i>
                  </span>
                  Profile
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="">Profile Element</a>
              </li>
            </ol>
          </div>
        </div>
      </div>




      <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-lg-4">
              <div class="clearfix">
                <div class="card card-bx profile-card author-profile m-b30">
                  <div class="card-body">
                    <div class="p-5">
                      <div class="author-profile">
                        <div class="author-media">
                          <img src="/images/tab/1.jpg" alt="" />
                        </div>
                        <div class="author-info">
                          <h3 className="text-light">{formData?.name}</h3>
                          <h5 className="text-light">{formData?.userId}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-lg-8">
              <div class="card profile-card card-bx m-b30">
                <div class="card-header">
                  <h4 class="card-title">Account</h4>
                </div>

                <form class="profile-form" onSubmit={updateProfile}>
                  <div class="card-body">
                    <div class="row">
                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="name">
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="name"
                          value={formData.name}
                          onChange={(e) => {
                            if (!characterValidation(e)) {
                              setValidationObj({
                                ...validationObj,
                                name: "Only characters are allowed",
                              });
                            } else {
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              });
                              setValidationObj({ ...validationObj, name: "" });
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.name}
                        </p>
                      </div>

                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="email"
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.email}
                        </p>
                      </div>
                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="phone">
                          Phone
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          value={formData.phone}
                          onChange={(e) => {


                            setValidationObj({
                              ...validationObj,
                              phone: CheckPhoneNo(e),
                            });

                            setFormData({
                              ...formData,
                              phone: e.target.value,
                            });


                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.phone}
                        </p>
                      </div>

                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="pin">
                          Pin Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="pin"
                          value={formData.pinCode}
                          onChange={(e) => {
                            setValidationObj({
                              ...validationObj,
                              pinCode: CheckPinCode(e),
                            });

                            setFormData({
                              ...formData,
                              pinCode: e.target.value,
                            });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.pinCode}
                        </p>
                      </div>

                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="address">
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          value={formData.address}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            });
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.address}
                        </p>
                      </div>
                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="city">
                          City
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          value={formData.city}
                          onChange={(e) => {
                            if (!characterValidation(e)) {
                              setValidationObj({
                                ...validationObj,
                                city: "Only characters are allowed",
                              });
                            } else {
                              setFormData({
                                ...formData,
                                city: e.target.value,
                              });
                              setValidationObj({ ...validationObj, city: "" });
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.city}
                        </p>
                      </div>
                      <div class="mb-3 col-sm-6">
                        <label class="form-label text-light" htmlFor="state">
                          State
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="state"
                          value={formData.state}
                          onChange={(e) => {
                            if (!characterValidation(e)) {
                              setValidationObj({
                                ...validationObj,
                                state: "Only characters are allowed",
                              });
                            } else {
                              setFormData({
                                ...formData,
                                state: e.target.value,
                              });
                              setValidationObj({ ...validationObj, state: "" });
                            }
                          }}
                        />
                        <p className="text-center text-danger">
                          {validationObj.state}
                        </p>
                      </div>

                      <div class="mb-3  col-sm-6">
                        <label
                          htmlFor="country"
                          class="form-label text-light required"
                        >
                          Country
                        </label>

                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              country: countryCodes[e.target.value]?.name,
                              countryCode: countryCodes[e.target.value]?.code,
                            });
                          }}
                        >
                          <option selected>Select your country</option>

                          {countryCodes.map((elem, index) => {
                            return (
                              <option
                                key={index}
                                value={index}


                                selected={
                                  formData.country === elem?.name ? true : false
                                }



                              >
                                {elem?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div class="mb-3  col-sm-6">
                        <label class="form-label text-light required">
                          Country Code
                        </label>
                        <input
                          type="text"
                          class="form-control text-light"
                          value={formData.countryCode}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    {loading ? (
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-lod"
                        disabled
                      >
                        <div class="spinner-border sz-3" role="status"></div>{" "}
                        <span>Loading...</span>
                      </button>
                    ) : (
                      <button type="submit" class="btn btn-primary btn-sm">
                        UPDATE
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
