import { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import {
  getUserIDFromToken,
  humanReadableTimeAndDate,
} from "../../../Helper/function";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";

export default function GameReport() {
  const [gameData, setGameData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleGameData, setSingleGameData] = useState({});

  useEffect(() => {
    setLoadingTable(true);
    token
      .get("/dice/game-record")
      .then((res) => {
        if (res.data.status == "success") {
          setGameData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }, []);

  function fetchSingleGamedata(gameId) {
    setLoading(true);
    document.getElementById("open-single-game-details").click();
    token
      .get(`/dice/game-record/${gameId}`)
      .then((res) => {
        if (res.data.status == "success") {
          setSingleGameData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Game Id",
        field: "gameId",
        sort: "asc",
        width: 150,
      },
      {
        label: "No. Of Player",
        field: "playerCount",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Winning Amount",
        field: "winningAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Details",
        field: "details",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  gameData.map((elem, i) => {
    table.rows.push({
      index: i + 1,

      playerCount: 4,
      gameId: elem?.gameId,
      amount: <span className="text-danger">{elem?.amount} INR</span>,
      winningAmount:
        getUserIDFromToken() == elem?.winner ? (
          <span className="text-success">{elem?.amount * 4} INR</span>
        ) : (
          <span className="text-success">00 INR</span>
        ),
      date: humanReadableTimeAndDate(elem?.createdAt),

      status:
        getUserIDFromToken() == elem?.winner ? (
          <span className="text-success">Winner</span>
        ) : (
          <span className="text-danger">Looser</span>
        ),

      details: (
        <p
          className="text-warning"
          onClick={(e) => {
            e.preventDefault();

            fetchSingleGamedata(elem._id);
          }}
        >
          Details
        </p>
      ),
    });
  });

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <h1 className="text-light">Dice Game Report</h1>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Amount
                          </th>
                          <th className="border" scope="col">
                            Reciever Id
                          </th>
                          <th className="border" scope="col">
                            Reciever Name
                          </th>
                          <th className="border" scope="col">
                            Date
                          </th>
                          <th className="border" scope="col">
                            Remark
                          </th>
                          <th className="border" scope="col">
                            Details
                          </th>
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={7} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id="open-single-game-details"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered  ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Game Details
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body px-3 py-3">
                {loading ? (
                  <>
                    <div class="spinner-border text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 className="text-center">
                      Game Id : {singleGameData?.gameId}
                    </h3>
                    <ul class="list-group ">
                      <li class="list-group-item d-flex justify-content-between">
                        Game Admin :
                        <strong> {singleGameData?.player1?.name}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Game Admin Id :
                        <strong> {singleGameData?.player1?.userId}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Player 2 :
                        <strong> {singleGameData?.player2?.name}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        player 2 Id :
                        <strong> {singleGameData?.player2?.userId}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        player 3 :
                        <strong>{singleGameData?.player3?.name}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        player 3 Id :
                        <strong>{singleGameData?.player3?.userId}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        player 4 Name:
                        <strong>{singleGameData?.player4?.name}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        player 4 Id:
                        <strong>{singleGameData?.player4?.userId}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Winner Name:
                        <strong>{singleGameData?.winner?.name}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Winner Id:
                        <strong>{singleGameData?.winner?.userId}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Bet Amount:
                        <strong>₹ {singleGameData?.amount}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Score 1:
                        <strong> {singleGameData?.score1}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Score 2:
                        <strong> {singleGameData?.score2}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Score 3:
                        <strong> {singleGameData?.score3}</strong>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        Score 4:
                        <strong> {singleGameData?.score4}</strong>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div class="modal-footer  px-3 py-3">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
