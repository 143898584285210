import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { socket } from "../../../Helper/Variables";

const PlayQuiz = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [submitBtn, setSubmitBtn] = useState({
    loading: false,
    isDisabled: sessionStorage.getItem("answered") == "true" ? true : false,
  });
  const [timers, setTimers] = useState({
    quizTimer: 60,
    answerTimer: 30,
  });
  const [optionButtons, setOptionButtons] = useState({
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });

  const [playerAnswer, setPlayerAnswer] = useState({
    playerAnswer: "",
    questionId: "",
  });

  const [quizData, setQuizData] = useState({
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    category: "",
    correctOption: "",
  });
  const [validateQuiz, setValidateQuiz] = useState({
    playerAnswer: "",
  });

  const [quizResult, setQuizResult] = useState({
    win: false,
    loose: false,
    timeUp: false,
  });

  const { quizCategory } = useParams();
  const { quizLevel } = useParams();
  const { questionId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchBalance();
    fetchSingleQuestion(questionId);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("isGameRunning") == "false") {
      document.getElementById("open-left-time-popup")?.click();
    }

    if (sessionStorage.getItem("game-over") == "true") {
      if (
        sessionStorage.getItem("game-result") == "timeUp" &&
        !sessionStorage.getItem("answered") == "true"
      ) {
        setQuizResult({
          win: false,
          loose: false,
          timeUp: true,
        });
      }

      if (sessionStorage.getItem("game-result") == "looser") {
        setQuizResult({
          win: false,
          loose: true,
          timeUp: false,
        });
      }
      if (sessionStorage.getItem("game-result") == "winner") {
        setQuizResult({
          win: true,
          loose: false,
          timeUp: false,
        });
        fetchBalance();
      }

      document.getElementById("open-result-banner")?.click();
    }
  }, []);

  useEffect(() => {
    socket.on("update-game-timers", (timer) => {
      setTimers({
        ...timers,
        quizTimer: timer?.quizTimer,
        answerTimer: timer?.answerTimer,
      });

      if (
        timer.quizTimer == 30 &&
        !sessionStorage.getItem("answered") &&
        sessionStorage.getItem("isGameRunning") == "true"
      ) {
        setQuizResult({ win: false, loose: false, timeUp: true });
        document.getElementById("open-result-banner")?.click();
        sessionStorage.setItem("game-over", true);
        sessionStorage.setItem("game-result", "timeUp");
      }

      if (
        timer.quizTimer == 30 &&
        sessionStorage.getItem("answered") &&
        sessionStorage.getItem("isGameRunning") == "true" &&
        sessionStorage.getItem("game-result") == "timeUp"
      ) {
        setQuizResult({ win: false, loose: true, timeUp: false });
        document.getElementById("open-result-banner")?.click();
        sessionStorage.setItem("game-over", true);
        sessionStorage.setItem("game-result", "looser");
      }

      if (timer?.quizTimer < 2) {
        document.getElementById("close-left-time-popup")?.click();
        sessionStorage.setItem("isGameRunning", true);
      }

      if (timer?.answerTimer == 1) {
        if (sessionStorage.getItem("answered") == "true") {
          fetchBalance();
        }
      }
    });
    return () => {
      socket.off("update-game-timers");
    };
  });

  useEffect(() => {
    socket.on("declare-quiz-winner", (result) => {
      if (sessionStorage.getItem("answered")) {
        setQuizResult({ win: true, loose: false, timeUp: false });
        document.getElementById("open-result-banner")?.click();
        sessionStorage.setItem("game-over", true);
        sessionStorage.setItem("game-result", "winner");
      }
    });

    return () => {
      socket.off("declare-quiz-winner");
    };
  });

  useEffect(() => {
    socket.on("declare-quiz-looser", (result) => {
      console.log("I AM A LOOSER");
      if (sessionStorage.getItem("answered")) {
        setQuizResult({ win: false, loose: true, timeUp: false });
        document.getElementById("open-result-banner")?.click();
        sessionStorage.setItem("game-over", true);
        sessionStorage.setItem("game-result", "looser");
      }
    });

    return () => {
      socket.off("declare-quiz-looser");
    };
  });

  function fetchSingleQuestion(questionId) {
    token
      .get(`/quiz-game/get-single-question/${questionId}`)
      .then((res) => {
        if (res.data.status == "success") {
          setQuizData({
            question: res?.data?.data?.question,
            optionA: res?.data?.data?.optionA,
            optionB: res?.data?.data?.optionB,
            optionC: res?.data?.data?.optionC,
            optionD: res?.data?.data?.optionD,
            category: res?.data?.data?.category?.name,
            correctOption: "",
          });

          setOptionButtons({
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
          });

          setPlayerAnswer({
            ...playerAnswer,
            questionId: res?.data?.data?._id,
          });

          setSubmitBtn({ ...submitBtn, isDisabled: false });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function checkPlayerAnswer() {
    if (!playerAnswer?.playerAnswer) {
      setValidateQuiz({
        ...validateQuiz,
        playerAnswer: "Please choose one option",
      });
      return;
    }

    setSubmitBtn({ ...submitBtn, loading: true });
    token
      .post("/quiz-game/check-player-answer", playerAnswer)
      .then((res) => {
        if (res?.data?.status == "success") {
          sessionStorage.setItem("answered", true);
          setQuizData({
            question: res?.data?.data?.question,
            optionA: res?.data?.data?.optionA,
            optionB: res?.data?.data?.optionB,
            optionC: res?.data?.data?.optionC,
            optionD: res?.data?.data?.optionD,
            category: res?.data?.data?.category?.name,
            correctOption: res?.data?.data?.correctOption,
          });

          setOptionButtons({
            optionA:
              playerAnswer?.playerAnswer === res?.data?.data?.optionA &&
              playerAnswer?.playerAnswer === res?.data?.data?.correctOption
                ? "pass"
                : "",

            optionB:
              playerAnswer?.playerAnswer === res?.data?.data?.optionB &&
              playerAnswer?.playerAnswer === res?.data?.data?.correctOption
                ? "pass"
                : "",
            optionC:
              playerAnswer?.playerAnswer === res?.data?.data?.optionC &&
              playerAnswer?.playerAnswer === res?.data?.data?.correctOption
                ? "pass"
                : "",
            optionD:
              playerAnswer?.playerAnswer === res?.data?.data?.optionD &&
              playerAnswer?.playerAnswer === res?.data?.data?.correctOption
                ? "pass"
                : "",
          });
        } else {
          sessionStorage.setItem("answered", true);
          if (res?.data?.message == "Already answered") {
            toast.error("Already answered", {
              position: "top-right",
              autoClose: 900,
            });
          }

          setOptionButtons({
            optionA:
              playerAnswer?.playerAnswer === res?.data?.data?.optionA &&
              playerAnswer?.playerAnswer !== res?.data?.data?.correctOption
                ? "fail"
                : res?.data?.data?.optionA == res?.data?.data?.correctOption
                ? "pass"
                : "",

            optionB:
              playerAnswer?.playerAnswer === res?.data?.data?.optionB &&
              playerAnswer?.playerAnswer !== res?.data?.data?.correctOption
                ? "fail"
                : res?.data?.data?.optionB == res?.data?.data?.correctOption
                ? "pass"
                : "",

            optionC:
              playerAnswer?.playerAnswer === res?.data?.data?.optionC &&
              playerAnswer?.playerAnswer !== res?.data?.data?.correctOption
                ? "fail"
                : res?.data?.data?.optionC == res?.data?.data?.correctOption
                ? "pass"
                : "",
            optionD:
              playerAnswer?.playerAnswer === res?.data?.data?.optionD &&
              playerAnswer?.playerAnswer !== res?.data?.data?.correctOption
                ? "fail"
                : res?.data?.data?.optionD == res?.data?.data?.correctOption
                ? "pass"
                : "",
          });
        }

        setSubmitBtn({ loading: false, isDisabled: true });
      })
      .catch((err) => {
        setSubmitBtn({ loading: false, isDisabled: true });

        setSubmitBtn({ ...submitBtn, isDisabled: true });

        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchBalance() {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          console.log("DASHBOARD DATA =>", res.data);
          setTotalBalance(res?.data?.data?.availableBalance);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function upgradeLevel(quizCategory, quizLevel) {
    sessionStorage.removeItem("game-over");
    token
      .get(
        `/quiz-game/get-unique-question-id?categoryId=${quizCategory}&difficultyLevel=${quizLevel}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          const questionId = res?.data?.data?._id;
          sessionStorage.removeItem("answered");

          applyQuizGame(quizLevel, questionId);
          fetchSingleQuestion(questionId);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function repeatLevel(quizCategory, difficultyLevel) {
    sessionStorage.removeItem("game-over");
    token
      .get(
        `/quiz-game/get-unique-question-id?categoryId=${quizCategory}&difficultyLevel=${difficultyLevel}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          const questionId = res?.data?.data?._id;
          sessionStorage.removeItem("answered");

          applyQuizGame(quizLevel, questionId);
          fetchSingleQuestion(questionId);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function applyQuizGame(difficultyLevel, questionId) {
    token
      .post("/quiz-game/apply-for-game", {
        difficultyLevel,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          deductBalance(difficultyLevel);
          navigate(
            `/user/play-quiz/${quizCategory}/${questionId}/${difficultyLevel}`
          );
          sessionStorage.setItem("isGameRunning", false);
          if (sessionStorage.getItem("isGameRunning") == "false") {
            document.getElementById("open-left-time-popup")?.click();
          }
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function deductBalance(difficultyLevel) {
    let amount = 0;

    if (difficultyLevel == 1) {
      amount = -10;
    } else if (difficultyLevel == 2) {
      amount = -20;
    } else if (difficultyLevel == 3) {
      amount = -40;
    } else if (difficultyLevel == 4) {
      amount = -80;
    } else if (difficultyLevel == 5) {
      amount = -160;
    } else if (difficultyLevel == 6) {
      amount = -320;
    } else if (difficultyLevel == 7) {
      amount = -640;
    } else {
      amount = 0;
    }

    token
      .put("/user/update-available-balance", { amount })
      .then((res) => {
        if (res.data.status == "success") {
          fetchBalance();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">Quiz</h2>
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  <i class="bi bi-ui-checks"></i>
                </span>
                Earning Details
              </a>
            </li>
            <li class="breadcrumb-item">
              <a>Binary Income</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-8 offset-md-2">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Play Quiz</h4>
                  <h4 class="card-title">
                    Available Balance ₹
                    {totalBalance < 10
                      ? "0" + totalBalance?.toFixed(2)
                      : totalBalance?.toFixed(2)}
                  </h4>
                </div>
                <div class="card-body">
                  <div className="quiz-box mt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="question">
                          <span className="quiz-title text-light">
                            {sessionStorage.getItem("isGameRunning") ==
                            "false" ? (
                              <p class="placeholder-glow text-white">
                                <span class="placeholder col-12"></span>
                              </p>
                            ) : (
                              <>Quiz - {quizData.category} </>
                            )}
                          </span>
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <p>{quizData?.question}</p>
                          )}
                        </div>
                        <p className="text-center text-danger">
                          {validateQuiz.playerAnswer}
                        </p>
                      </div>
                      <div className="my-2 ">
                        <span className="quiz-title text-center text-light">
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <>
                              Time Left :{" "}
                              {timers?.answerTimer < 10
                                ? "0" + timers?.answerTimer
                                : timers?.answerTimer}{" "}
                              Secs
                            </>
                          )}
                        </span>
                      </div>
                      <div
                        className="col-md-6 "
                        onClick={(e) => {
                          e.preventDefault();

                          setPlayerAnswer({
                            ...playerAnswer,
                            playerAnswer: quizData?.optionA,
                          });

                          setOptionButtons({
                            optionA: "active",
                            optionB: "",
                            optionC: "",
                            optionD: "",
                          });
                          setValidateQuiz({
                            ...validateQuiz,
                            playerAnswer: "",
                          });
                        }}
                      >
                        <div
                          className={`answer button-8 ${optionButtons?.optionA}`}
                        >
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <>A. {quizData?.optionA}</>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-md-6 "
                        onClick={(e) => {
                          e.preventDefault();

                          setPlayerAnswer({
                            ...playerAnswer,
                            playerAnswer: quizData?.optionB,
                          });

                          setOptionButtons({
                            optionA: "",
                            optionB: "active",
                            optionC: "",
                            optionD: "",
                          });
                          setValidateQuiz({
                            ...validateQuiz,
                            playerAnswer: "",
                          });
                        }}
                      >
                        <div
                          className={`answer button-8 ${optionButtons?.optionB}`}
                        >
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <>B. {quizData?.optionB}</>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-md-6 "
                        onClick={(e) => {
                          e.preventDefault();

                          setPlayerAnswer({
                            ...playerAnswer,
                            playerAnswer: quizData?.optionC,
                          });
                          setOptionButtons({
                            optionA: "",
                            optionB: "",
                            optionC: "active",
                            optionD: "",
                          });
                          setValidateQuiz({
                            ...validateQuiz,
                            playerAnswer: "",
                          });
                        }}
                      >
                        <div
                          className={`answer button-8 ${optionButtons?.optionC}`}
                        >
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <>C. {quizData?.optionC}</>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        onClick={(e) => {
                          e.preventDefault();

                          setPlayerAnswer({
                            ...playerAnswer,
                            playerAnswer: quizData?.optionD,
                          });
                          setOptionButtons({
                            optionA: "",
                            optionB: "",
                            optionC: "",
                            optionD: "active",
                          });
                          setValidateQuiz({
                            ...validateQuiz,
                            playerAnswer: "",
                          });
                        }}
                      >
                        <div
                          className={`answer button-8 ${optionButtons?.optionD}`}
                        >
                          {sessionStorage.getItem("isGameRunning") ==
                          "false" ? (
                            <p class="placeholder-glow">
                              <span class="placeholder col-12"></span>
                            </p>
                          ) : (
                            <>D. {quizData?.optionD}</>
                          )}
                        </div>
                      </div>

                      <hr className="mt-2 mb-3"></hr>

                      <div className="col-md-12 mt-2 text-center">
                        {!submitBtn?.loading && !submitBtn?.isDisabled ? (
                          <div
                            className="btn btn-success"
                            onClick={(e) => {
                              e.preventDefault();
                              checkPlayerAnswer();
                            }}
                          >
                            Submit
                          </div>
                        ) : null}

                        {submitBtn?.loading ? (
                          <button
                            class="btn btn-success"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span role="status">Loading...</span>
                          </button>
                        ) : null}

                        {submitBtn?.isDisabled ? (
                          <button
                            class="btn btn-success"
                            type="button"
                            disabled
                          >
                            <span role="status">Submited</span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="open-left-time-popup"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
             
              </h1>
            </div>
            <div class="modal-body">
              <h3 className="text-light text-center">
                Remaining Time{" "}
                {timers?.quizTimer < 10
                  ? "0" + timers?.quizTimer
                  : timers?.quizTimer}{" "}
                Secs
              </h3>
            </div>

            <div class="modal-footer d-none">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="close-left-time-popup"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        id="open-result-banner"
      >
        Launch static backdrop modal
      </button>

      <div
        class="blur modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered noticepop">
          <div class="modal-content">
            <div class="modal-header bgpop">
              <div className="rolar">
                {" "}
                <img src="/images/result.png" alt="" />
              </div>

              <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
                Quiz Result
              </h1>
            </div>
            <div class="modal-body">
              {quizResult?.win ? (
                <>
                  <h1 className="text-center">Wow!!</h1>
                  <p className="text-light text-center">
                    Congratulation you won ₹
                    {quizLevel == 1
                      ? 20
                      : quizLevel == 2
                      ? 40
                      : quizLevel == 3
                      ? 80
                      : quizLevel == 4
                      ? 160
                      : quizLevel == 5
                      ? 320
                      : quizLevel == 6
                      ? 640
                      : quizLevel == 7
                      ? 1280
                      : ""}
                  </p>
                </>
              ) : null}

              {quizResult?.loose ? (
                <>
                  <h1 className="text-center">Better Luck</h1>
                  <p className="text-light text-center">Try again</p>
                </>
              ) : null}

              {quizResult?.timeUp ? (
                <>
                  <h1 className="text-center">Time UP</h1>

                  <h5 className="text-light text-center">
                    You did not submit your answers before the end of time
                  </h5>
                </>
              ) : null}
              <div className="col mt-2 text-center">
                <button
                  type="button"
                  class="btn button-9 m-auto btn-warning"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("close-result-model")?.click();
                    repeatLevel(quizCategory, quizLevel);
                  }}
                >
                  <i class="bi bi-arrow-clockwise"></i> Repeat Level
                </button>
              </div>
            </div>
            <div class="modal-footer d-block text-center">
              <button
                type="button"
                class="btn btn-danger d-none"
                data-bs-dismiss="modal"
                id="close-result-model"
              >
                Exit
              </button>
              <button
                type="button"
                class="btn button-9 btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("close-result-model")?.click();

                  navigate(`/user/dfficuly-level/${quizCategory}`);
                }}
              >
                <i class="bi bi-backspace"></i> Exit
              </button>

              {quizLevel < 7 ? (
                <button
                  type="button"
                  class="btn button-9 btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("close-result-model")?.click();
                    upgradeLevel(quizCategory, Number(quizLevel) + 1);
                  }}
                >
                  <i class="bi bi-bar-chart"></i> Upgrade Level
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayQuiz;
