export default function PrivacyPolicy (){


    return <>
    <h1 className="text-light">Privacy Policy</h1>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    <p className="text-light">This is the pricay policy I am privacy policy you are privacy policy we are privacy policy everyone is privacy policy</p>
    
    </>
}