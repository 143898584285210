import { MDBDataTable } from 'mdbreact';
import { useState, useEffect } from 'react';
import Token from '../../../Helper/Token';
const MyTeam = () => {
    const [loader, setLoader] = useState(true);
    const [Record, setRecord] = useState([]);
    const [Filterdata, setFilterdata] = useState([]);
    const table = {
        columns: [
            {
                label: 'S.No',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Reg No',
                field: 'regno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Sponsor Id',
                field: 'sponsorid',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Sponsor Name',
                field: 'sname',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Package',
                field: 'package',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Reg Date',
                field: 'regdate',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Active Date',
                field: 'activedate',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Left/Right',
                field: 'position',
                sort: 'asc',
                width: 150
            },
        ],
        rows: []
    };
    const NewDate = (e) => {
        let d = new Date(e);
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][d.getMonth()];
        return d.getDate() + '/' + months + '/' + d.getFullYear();
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        Token.get('/AllMembers').then(res => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setFilterdata(res.data.Payload); setRecord(res.data.Payload); }
            setLoader(false)
        }).catch(err => { setLoader(false) })
    }, [])
    Filterdata.map((tr, i) => {
        table.rows.push({
            id: i < 10 ? "0" + (i + 1) : (i + 1),
            regno: tr.appmstregno,
            name: tr.appmstfname,
            sponsorid: tr.sponsorid,
            sname: tr.appmstfname,
            package: tr.JoinType,
            regdate: NewDate(tr.appmstdoj),
            activedate: NewDate(tr.ActiveDate),
            position: tr.leftright,
        })
    })
    return (
        <>
            <div class="page-titles">
                <div className="container-fluid">
                    <div class="sub-dz-head">
                        <div class="d-flex align-items-center dz-head-title">
                            <h2 class="text-white m-0">My Network</h2>
                        </div>

                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active ms-auto">
                                <a class="d-flex align-self-center">
                                    <span> <i class="bi bi-ui-checks"></i> </span> My Network
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="">My Team</a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">My Team</h4>
                                    <select class="btn light btn-secondary" aria-label="Default select example" onChange={(e) => { if (e.currentTarget.value) { setFilterdata(Record.filter((item, i) => item.leftright.toLowerCase() == e.currentTarget.value.toLowerCase())) } else { setFilterdata(Record) } }}>
                                        <option value="">All Members</option>
                                        <option value="Left">Left</option>
                                        <option value="Right">Right</option>
                                    </select>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        {loader ?
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-8"></span>
                                            </p> :
                                            <>

                                                <MDBDataTable bordered small data={table} />
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyTeam;
