import { useCallback, useEffect, useState } from "react";
import { socket } from "../../../Helper/Variables";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { humanReadableTimeAndDate, isNumeric } from "../../../Helper/function";

let autoCutHitA = false;
let autoCutHitB = false;

export default function AviatorGame() {
  const [planeAnimationClass, setPlaneAnimationClass] = useState("myplane");

  const [validateAutoCut, setValidateAutoCut] = useState({
    autoCutA: "",
    autoCutB: "",
  });

  const [recentBetsTable, setRecentBetsTable] = useState({
    allBets: true,
    myBets: false,
    top: false,
  });

  const [allBets, setAllBets] = useState([]);

  const [myBets, setMyBets] = useState([]);

  const [autoCutLoading, setAutoCutLoading] = useState(false);

  const [autoCutBtn, setAutoCutBtn] = useState({
    autoCutBtnA: true,
    autoCutBtnB: true,
  });

  const [btnPlaceholder, setBtnPlaceholder] = useState({
    betA: false,
    betACancel: true,
    betACash: true,
    betB: false,
    betBCancel: true,
    betBCash: true,
  });

  // const [btnDisable, setBtnDisable] = useState(false);

  const [balance, setBalance] = useState(0);
  const [timeX, setTimeX] = useState(1);
  const [loading, setLoading] = useState(100);

  const [bet, setBet] = useState({ betA: 10, betB: 10 });
  const [amountUpdatedBy, setAmountUpdatedBy] = useState({ betA: 0, betB: 0 });

  const [gameState, setGameState] = useState({
    loading: false,
    flying: false,
    flew: true,
    result: true,
  });

  const [gameRecord, setGameRecord] = useState([]);

  const [autoCutFormData, setAutoCutFormData] = useState({
    autoCut: null,
    betA: null,
    betB: null,
  });

  useEffect(() => {
    if (timeX < 1.31) {
      setPlaneAnimationClass("myplane");
    } else {
      setPlaneAnimationClass("animateplane");
    }
  }, [timeX]);

  //persisting initial state
  useEffect(() => {
    const tempAutoCutBtnA = sessionStorage.getItem("autoCutBtnA");
    const tempAutoCutBtnB = sessionStorage.getItem("autoCutBtnB");

    const tempBetA = sessionStorage.getItem("betA");
    const tempBetACancel = sessionStorage.getItem("betACancel");
    const tempBetACash = sessionStorage.getItem("betACash");

    const tempBetB = sessionStorage.getItem("betB");
    const tempBetBCancel = sessionStorage.getItem("betBCancel");
    const tempBetBCash = sessionStorage.getItem("betBCash");

    // const tempBtnDisable = sessionStorage.getItem("btnDisable");
    const tempTimeX = Number(sessionStorage.getItem("timeX"));

    const tempAmountBetA = sessionStorage.getItem("amountBetA");
    const tempAmountBetB = sessionStorage.getItem("amountBetB");

    setAutoCutBtn({
      autoCutBtnA: tempAutoCutBtnA == "true" ? true : false,
      autoCutBtnB: tempAutoCutBtnB == "true" ? true : false,
    });

    setBtnPlaceholder({
      betA: !tempBetA ? false : tempBetA == "true" ? true : false,
      betACancel: !tempBetACancel
        ? true
        : tempBetACancel == "true"
        ? true
        : false,
      betACash: !tempBetACash ? true : tempBetACash == "true" ? true : false,
      betB: !tempBetB ? false : tempBetB == "true" ? true : false,
      betBCancel: !tempBetBCancel
        ? true
        : tempBetBCancel == "true"
        ? true
        : false,
      betBCash: !tempBetBCash ? true : tempBetBCash == "true" ? true : false,
    });

    setBet({
      betA: tempAmountBetA ? Number(tempAmountBetA) : 10,
      betB: tempAmountBetB ? Number(tempAmountBetB) : 10,
    });

    // setBtnDisable(!tempBtnDisable ? false : true);
    setTimeX(!tempTimeX ? 0 : tempTimeX);
  }, []);

  //start loading
  useEffect(() => {
    socket.on("start_loading", (loadingPercent) => {
      setGameState({
        loading: true,
        flying: false,
        result: false,
        flew: false,
      });
      setTimeX(1);
      setLoading(loadingPercent);
      // setBtnDisable(false);
      // sessionStorage.setItem("btnDisable", false);
    });
    return () => {
      socket.off("start_loading");
    };
  }, []);

  useEffect(() => {
    socket.on("pending_bet_placed", () => {
      console.log("PENDING BET PLACED");
    });

    return () => {
      socket.off("pending_bet_placed");
    };
  }, []);

  //bet-placed
  useEffect(() => {
    socket.on("bet-placed", () => {
      fetchAllBets();
      fetchMyBets();
    });
    return () => {
      socket.off("bet-placed");
    };
  }, []);

  //aviator-game-started
  useEffect(() => {
    socket.on("aviator-game-started", () => {
      fetchAllBets();
      fetchMyBets();
    });
    return () => {
      socket.off("aviator-game-started");
    };
  }, []);

  //cash out
  useEffect(() => {
    socket.on("cash-out", () => {
      fetchAllBets();
      fetchMyBets();
    });
    return () => {
      socket.off("cash-out");
    };
  }, []);

  //update X
  useEffect(() => {
    socket.on("updateX", (initialX) => {
      if (
        sessionStorage?.autoCutA &&
        initialX?.toFixed(2) == Number(sessionStorage?.autoCutA)?.toFixed(2) &&
        autoCutHitA == false
        // &&
        // sessionStorage.getItem("Next-Round") != "true"
      ) {
        autoCutHitA = true;

        cashOut({
          betA: true,
          autoCutA: Number(sessionStorage?.autoCutA)?.toFixed(2),
        });

        toast.success("Auto cut succefull", {
          position: "top-right",
          autoClose: 900,
        });
      }
      if (
        sessionStorage?.autoCutB &&
        initialX?.toFixed(2) == Number(sessionStorage?.autoCutB)?.toFixed(2) &&
        autoCutHitB == false
        // &&
        // sessionStorage.getItem("Next-Round") != "true"
      ) {
        autoCutHitB = true;

        cashOut({
          betB: true,
          autoCutB: Number(sessionStorage?.autoCutB)?.toFixed(2),
        });

        toast.success("Auto cut succefull", {
          position: "top-right",
          autoClose: 900,
        });
      }

      setGameState({
        loading: false,
        flying: true,
        result: false,
        flew: false,
      });

      setTimeX(initialX);
      fetchAllBets();
    });
    return () => {
      socket.off("updateX");
    };
  }, []);

  //take off
  useEffect(() => {
    socket.on("take_off_plan", () => {
      // setBtnDisable(true);
      // sessionStorage.setItem("btnDisable", true);

      const tempBetA =
        sessionStorage.getItem("betACancel") == "false" ? true : false;

      const tempBetACancel = true;
      const tempBetACash =
        sessionStorage.getItem("betACancel") == "false" ? false : true;
      const tempBetB =
        sessionStorage.getItem("betBCancel") == "false" ? true : false;
      const tempbetBCancel = true;
      const tempBetBCash =
        sessionStorage.getItem("betBCancel") == "false" ? false : true;

      setBtnPlaceholder({
        betA: tempBetA,
        betACancel: tempBetACancel,
        betACash: tempBetACash,
        betB: tempBetB,
        betBCancel: tempbetBCancel,
        betBCash: tempBetBCash,
      });

      sessionStorage.setItem("betA", tempBetA);
      sessionStorage.setItem("betACancel", tempBetACancel);
      sessionStorage.setItem("betACash", tempBetACash);
      sessionStorage.setItem("betB", tempBetB);
      sessionStorage.setItem("betBCancel", tempbetBCancel);
      sessionStorage.setItem("betBCash", tempBetBCash);

      setGameState({
        loading: false,
        flying: true,
        result: false,
        flew: false,
      });

      setLoading(100);
    });
    return () => {
      socket.off("take_off_plan");
    };
  }, []);

  //flew away
  useEffect(() => {
    socket.on("flew_away", (x) => {
      setGameState({ loading: false, flying: true, result: false, flew: true });
      setTimeX(x);
      sessionStorage.setItem("timeX", x);
    });
    return () => {
      socket.off("flew_away");
    };
  }, []);

  //result shown
  useEffect(() => {
    socket.on("result_shown", () => {
      const tempBetACancel = sessionStorage.getItem("betACancel");
      const tempBetBCancel = sessionStorage.getItem("betBCancel");

      if (tempBetACancel == "false" && tempBetBCancel == "false") {
        setBtnPlaceholder({
          betA: true,
          betACancel: false,
          betACash: true,
          betB: true,
          betBCancel: false,
          betBCash: true,
        });

        sessionStorage.setItem("betA", true);
        sessionStorage.setItem("betACancel", false);
        sessionStorage.setItem("betACash", true);
        sessionStorage.setItem("betB", true);
        sessionStorage.setItem("betBCancel", false);
        sessionStorage.setItem("betBCash", true);
      } else if (tempBetACancel == "false") {
        setBtnPlaceholder({
          ...btnPlaceholder,
          betA: true,
          betACancel: false,
          betACash: true,
        });

        sessionStorage.setItem("betA", true);
        sessionStorage.setItem("betACancel", false);
        sessionStorage.setItem("betACash", true);
      } else if (tempBetBCancel == "false") {
        setBtnPlaceholder({
          ...btnPlaceholder,

          betB: true,
          betBCancel: false,
          betBCash: true,
        });

        sessionStorage.setItem("betB", true);
        sessionStorage.setItem("betBCancel", false);
        sessionStorage.setItem("betBCash", true);
      } else if (tempBetACancel != "false" && tempBetBCancel != "false") {
        setBtnPlaceholder({
          betA: false,
          betACancel: true,
          betACash: true,
          betB: false,
          betBCancel: true,
          betBCash: true,
        });

        sessionStorage.setItem("betA", false);
        sessionStorage.setItem("betACancel", true);
        sessionStorage.setItem("betACash", true);
        sessionStorage.setItem("betB", false);
        sessionStorage.setItem("betBCancel", true);
        sessionStorage.setItem("betBCash", true);
      }

      setGameState({ loading: false, flying: false, result: true, flew: true });
      fetchAviatorRecord();

      // if (sessionStorage.getItem("Next-Round") != "true") {
      autoCutHitA = false;
      setAutoCutBtn({ ...autoCutBtn, autoCutBtnA: true });
      sessionStorage.setItem("autoCutBtnA", true);
      sessionStorage.setItem("autoCutA", null);

      autoCutHitB = false;
      setAutoCutBtn({ ...autoCutBtn, autoCutBtnB: true });
      sessionStorage.setItem("autoCutBtnB", true);
      sessionStorage.setItem("autoCutB", null);
      // } else {
      //   sessionStorage.setItem("Next-Round", false);
      // }
    });
    return () => {
      socket.off("result_shown");
    };
  }, []);

  //fetch account balance
  useEffect(() => {
    fetchAccountBalance();
    fetchAviatorRecord();
    fetchAllBets();
    fetchMyBets();
  }, []);

  function cancelAutoCut(autoCut) {
    sessionStorage.setItem(autoCut, null);
    toast.success("Auto cut cancelled", {
      position: "top-left",
      autoClose: 900,
    });

    if (autoCut == "autoCutA") {
      setAutoCutBtn((prev) => ({ ...prev, autoCutBtnA: true }));
      sessionStorage.setItem("autoCutBtnA", true);
    }
    if (autoCut == "autoCutB") {
      setAutoCutBtn((prev) => ({ ...prev, autoCutBtnB: true }));
      sessionStorage.setItem("autoCutBtnB", true);
    }
  }

  function autoCut() {
    if (!autoCutFormData?.autoCut) {
      toast.error("Autocut value is required", {
        position: "top-left",
        autoClose: 900,
      });
      return;
    }

    setAutoCutLoading(true);

    token
      .post("/aviator/auto-cut", autoCutFormData)
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });

          if (res?.data?.data?.autoCutA) {
            sessionStorage?.setItem("autoCutA", res?.data?.data?.autoCutA);
            setAutoCutBtn((prev) => ({ ...prev, autoCutBtnA: false }));
            sessionStorage.setItem("autoCutBtnA", false);
          }

          if (res?.data?.data?.autoCutB) {
            sessionStorage?.setItem("autoCutB", res?.data?.data?.autoCutB);
            setAutoCutBtn((prev) => ({ ...prev, autoCutBtnB: false }));
            sessionStorage.setItem("autoCutBtnB", false);
          }

          // if (res.data.message == "Auto cut set for next round") {
          //   sessionStorage.setItem("Next-Round", true);
          // } else {
          //   sessionStorage.setItem("Next-Round", false);
          // }
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setAutoCutLoading(false);
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
        setAutoCutLoading(false);
      });
  }

  function putBet(betObj) {
    token
      .post("/aviator/put-bet", betObj)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });

          setBtnPlaceholder({
            betA: betObj.betA || btnPlaceholder.betA ? true : false,
            betACancel: betObj.betA || btnPlaceholder.betA ? false : true,
            betACash: true,
            betB: betObj.betB || btnPlaceholder.betB ? true : false,
            betBCancel: betObj.betB || btnPlaceholder.betB ? false : true,
            betBCash: true,
          });

          sessionStorage.setItem(
            "betA",
            betObj.betA || btnPlaceholder.betA ? true : false
          );
          sessionStorage.setItem(
            "betACancel",
            betObj.betA || btnPlaceholder.betA ? false : true
          );
          sessionStorage.setItem("betACash", true);
          sessionStorage.setItem(
            "betB",
            betObj.betB || btnPlaceholder.betB ? true : false
          );
          sessionStorage.setItem(
            "betBCancel",
            betObj.betB || btnPlaceholder.betB ? false : true
          );
          sessionStorage.setItem("betBCash", true);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }

        fetchAccountBalance();
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function cancelBet(betObj) {
    token
      .put("/aviator/cancel-bet", betObj)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });

          if (betObj.betA) {
            setBtnPlaceholder({
              ...btnPlaceholder,
              betA: false,
              betACancel: true,
              betACash: true,
            });
            sessionStorage.setItem("betA", false);
            sessionStorage.setItem("betACancel", true);
            sessionStorage.setItem("betACash", true);
          }

          if (betObj.betB) {
            setBtnPlaceholder({
              ...btnPlaceholder,
              betB: false,
              betBCancel: true,
              betBCash: true,
            });
            sessionStorage.setItem("betB", false);
            sessionStorage.setItem("betBCancel", true);
            sessionStorage.setItem("betBCash", true);
          }
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        fetchAccountBalance();
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function cashOut(betObj) {
    token
      .put("/aviator/cash-out", betObj)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });

          if (betObj.betA) {
            setBtnPlaceholder({
              ...btnPlaceholder,
              betA: false,
              betACancel: true,
              betACash: true,
            });

            sessionStorage.setItem("betA", false);
            sessionStorage.setItem("betACancel", true);
            sessionStorage.setItem("betACash", true);
          }

          if (betObj.betB) {
            setBtnPlaceholder({
              ...btnPlaceholder,
              betB: false,
              betBCancel: true,
              betBCash: true,
            });
            sessionStorage.setItem("betB", false);
            sessionStorage.setItem("betBCancel", true);
            sessionStorage.setItem("betBCash", true);
          }
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        fetchAccountBalance();
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchAccountBalance() {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          setBalance(res?.data?.data?.availableBalance);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchAviatorRecord() {
    token
      .get("/aviator/game-record")
      .then((res) => {
        if (res.data.status == "success") {
          setGameRecord(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchAllBets() {
    token
      .get("/aviator/all-bets")
      .then((res) => {
        if (res.data.status == "success") {
          setAllBets(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchMyBets() {
    token
      .get("/aviator/user-bets")
      .then((res) => {
        if (res.data.status == "success") {
          setMyBets(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }
  const [isgameRecordView, setIsgameRecordView] = useState(false);

  const gameRecord_view = () => {
    setIsgameRecordView(!isgameRecordView);
  };

  return (
    <>
      <div class="content-body mt-5 pt-4">
        <div class="container-fluid px-2">
          <div class="row ">
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-12">
                  <h6 class="mb-3">
                    <img src="/images/inr.png" style={{ width: "22px" }} />{" "}
                    {balance.toFixed(2)} INR
                    <span
                      className="pull-right aviator-re-btn text-black"
                      onClick={gameRecord_view}
                    >
                      {isgameRecordView ? (
                        <i class="fa-solid fa-sort-up text-black"></i>
                      ) : (
                        <i class="fa-solid fa-sort-down"></i>
                      )}
                    </span>
                  </h6>
                  <div class="card">
                    <div class="card-header flex-wrap aviator-re">
                      <div
                        className={isgameRecordView ? "" : "gameRecord-avator"}
                      >
                        {gameRecord?.map((elem, index) => {
                          return (
                            <span
                              class={`badge badge-more badge-pill badge-dark ${
                                elem?.score <= 2
                                  ? "text-info"
                                  : elem?.score <= 5
                                  ? "text-warning"
                                  : elem?.score <= 10
                                  ? "text-danger"
                                  : "text-danger-emphasis"
                              }`}
                            >
                              {(elem?.score).toFixed(2)}X
                            </span>
                          );
                        })}
                      </div>

                      <button
                        class="btn btn-success btn-sm d-none"
                        id="open-winner-banner"
                        data-bs-toggle="modal"
                        data-bs-target="#Result"
                      >
                        Result
                      </button>
                    </div>
                    <div class="card-body Aviator-box spin-box d-flex align-items-center justify-content-center ">
                      <div class="clearfix"></div>

                      {/* <div className="glowplane"></div>
                          <div className="finalAviator ">
                         

                           
                            <div className="leftdots">
                              <div className="bdots">
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                              </div>
                            </div>
                            <div className="bottomdots">
                              <div className="dots">
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                              </div>
                            </div> */}



                            {/* <img
                              src="/images/loadimg.png"
                              className={`startgraph ${planeAnimationClass}`}
                              alt=""
                            /> */}


{/* <img
                              src="/images/loadimg.png"
                              className="startgraph myplane"
                              alt=""
                            /> */}



                      {/* animateplane */}

                      {gameState?.loading ? (
                        <>
                          <div className="loader " >
                            <div className="loadsection">
                              <img
                                src="/images/spin.png"
                                className="spin fa-spin"
                                alt=""
                              />
                              <h3 className="text-uppercase">
                                Waiting For The next Round
                              </h3>

                              <div class="bar">
                                <div class="in"></div>
                              </div>

                              {/* <div class="progress " style={{ width: "300px" }}>
                                <div
                                  class="progress-bar bg-danger "
                                  aria-valuenow="85"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: `${loading}%` }}
                                  role="progressbar"
                                ></div>
                              </div> */}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {gameState?.loading ? (
                        <>
                          <img
                            src="/images/loadimg.png"
                            className="loadimg"
                            alt=""
                           hidden/>
                        </>
                      ) : null}

                      {/* PLANE FLYING */}
                      {gameState?.flying ? (
                        < >
                          <div className="glowplane" ></div>
                          <div className="finalAviator " >
                          <div className="planemaoin">


<div className="themewidth checkplane">

<div className="imggraph animatechart "></div>

  </div>
<div className="image-bazar bazaranim"> 
<img src="/images/loadimg.png" className="myanimplane" alt="" /></div>
   

   </div>


                            {/* <div className="outerchart themewidth  ">
                              <div className="imggraph animatechart "></div>
                            </div> */}


                            <div className="leftdots">
                              <div className="bdots">
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                                <span className="bdotted"></span>
                              </div>
                            </div>
                            <div className="bottomdots">
                              <div className="dots">
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                                <span className="dotted"></span>
                              </div>
                            </div>

                            {/* <img
                              src="/images/loadimg.png"
                              className={`startgraph ${planeAnimationClass}`}
                              alt=""
                            /> */}

                            {/* <img
                              src="/images/loadimg.png"
                              className="startgraph  animateplane"
                              alt=""
                            />  */}

                            <div className="resultsection text-center">
                              <h1 className="finalresultvalue">
                                {timeX?.toFixed(2)}
                                <span>X</span>
                              </h1>
                            </div>
                            {/* <img
                              src="/images/loadimg.png"
                              className="loadimg myplane animateplane"
                              alt=""
                            /> */}
                            <div className="leftdots"></div>
                            <div className="bottomdots"></div>
                          </div>
                        </>
                      ) : null}

                      {/* FLEW AWAY */}
                      {gameState?.flew && gameState?.result ? (
                        <>
                          <div className="result ">
                            <div className="resultsection text-center">
                              <h2>FLEW AWAY!</h2>
                              <h1 className="resultvalue">
                                {" "}
                                {timeX?.toFixed(2)}X
                              </h1>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* <img src="/images/Aviatorbg.png" alt="" / > */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div class="col-md-6 col-xs-12">
                  <div class="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                    {/* {!btnPlaceholder?.betA ? ( */}

                    <div className="row">
                      <div class="col-md-6 col-6">
                        <div
                          class=""
                          role="presentation"
                          style={{ position: "relative" }}
                        >
                          <i
                            class="bi bi-dash-circle icon-left"
                            onClick={() => {
                              if (bet.betA > 10) {
                                setBet({
                                  ...bet,
                                  betA: Number(bet.betA) - 1,
                                });
                                sessionStorage.setItem(
                                  "amountBetA",
                                  Number(bet.betA) - 1
                                );
                              } else {
                                toast.error("Minimum bet amount is 10 INR", {
                                  position: "top-right",
                                  autoClose: 900,
                                });
                              }
                            }}
                          ></i>
                          <input
                            type="text"
                            class="form-control form-control-sm fs-16 avibet me-2 w-100"
                            onChange={(e) => {
                              setBet({ ...bet, betA: e.target.value });
                            }}
                            value={bet?.betA}
                          />
                          <i
                            class="bi bi-plus-circle icon-right"
                            onClick={() => {
                              if (bet.betA + 1 <= 1000) {
                                setBet({
                                  ...bet,
                                  betA: Number(bet.betA) + 1,
                                });
                                sessionStorage.setItem(
                                  "amountBetA",
                                  Number(bet.betA) + 1
                                );
                              } else {
                                toast.error("Maximum bet amount is 1000 INR", {
                                  position: "top-right",
                                  autoClose: 900,
                                });
                              }
                            }}
                          ></i>
                        </div>
                        <ul class="nav nav-pills" role="tablist">
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy?.betA == 10) {
                                  if (bet.betA + 10 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betA: Number(bet.betA) + 10,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetA",
                                      Number(bet.betA) + 10
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betA: 10 });
                                  sessionStorage.setItem("amountBetA", 10);
                                  setAmountUpdatedBy({
                                    ...amountUpdatedBy,
                                    betA: 10,
                                  });
                                }
                              }}
                            >
                              10+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy?.betA == 20) {
                                  if (bet.betA + 20 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betA: Number(bet.betA) + 20,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetA",
                                      Number(bet.betA) + 20
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betA: 20 });
                                  setAmountUpdatedBy({
                                    ...amountUpdatedBy,
                                    betA: 20,
                                  });
                                  sessionStorage.setItem("amountBetA", 20);
                                }
                              }}
                            >
                              20+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy?.betA == 50) {
                                  if (bet.betA + 50 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betA: Number(bet.betA) + 50,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetA",
                                      Number(bet.betA) + 50
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betA: 50 });
                                  sessionStorage.setItem("amountBetA", 50);
                                  setAmountUpdatedBy({
                                    ...amountUpdatedBy,
                                    betA: 50,
                                  });
                                }
                              }}
                            >
                              50+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy?.betA == 100) {
                                  if (bet.betA + 100 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betA: Number(bet.betA) + 100,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetA",
                                      Number(bet.betA) + 100
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betA: 100 });
                                  sessionStorage.setItem("amountBetA", 100);
                                  setAmountUpdatedBy({
                                    ...amountUpdatedBy,
                                    betA: 100,
                                  });
                                }
                              }}
                            >
                              100+
                            </button>
                          </li>
                        </ul>

                        <input
                          type="text"
                          className="form-control mt-1"
                          onChange={(e) => {
                            setValidateAutoCut({
                              ...validateAutoCut,
                              autoCutA: "",
                            });
                            setAutoCutFormData({
                              autoCut: e.target.value,
                              betA: true,
                              betB: null,
                            });
                          }}
                        />
                        <span className="text-danger text-center small">
                          {validateAutoCut?.autoCutA}
                        </span>
                      </div>
                      <div class="col-md-6 col-6">
                        {autoCutBtn?.autoCutBtnA ? (
                          <button
                            class="btn btn-block text-center btn-bet"
                            onClick={autoCut}
                          >
                            Auto Cut
                          </button>
                        ) : (
                          <button
                            class="btn btn-block text-center btn-bet"
                            onClick={() => {
                              cancelAutoCut("autoCutA");
                            }}
                          >
                            Cancel Auto Cut
                          </button>
                        )}

                        {!btnPlaceholder?.betA ? (
                          <>
                            <button
                              class="btn btn-block btn-bet"
                              onClick={(e) => {
                                e.preventDefault();
                                putBet({ betA: bet?.betA });
                              }}
                              // disabled={btnDisable}
                            >
                              BET -{" "}
                              <span>
                                {" "}
                                {bet?.betA && bet?.betA < 10
                                  ? "0" + bet?.betA
                                  : bet?.betA
                                  ? bet?.betA
                                  : "00"}
                              </span>
                            </button>
                          </>
                        ) : null}

                        {!btnPlaceholder?.betACancel ? (
                          <>
                            <button
                              class="btn btn-block text-center btn-danger mt-3 mb-2 fs-16 dont-w600"
                              onClick={(e) => {
                                e.preventDefault();
                                cancelBet({ betA: true });
                              }}
                            >
                              CANCEL
                            </button>
                          </>
                        ) : null}
                        {!btnPlaceholder?.betACash ? (
                          <>
                            <button
                              class="btn btn-block text-center btn-warning mt-3 mb-2 fs-16 dont-w600"
                              onClick={(e) => {
                                e.preventDefault();
                                cashOut({ betA: true });
                              }}
                            >
                              CASH OUT -{" "}
                              <span>{(bet?.betA * timeX).toFixed(2)} INR</span>
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <div class="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                    <div className="row">
                      <div class="col-md-6 col-6">
                        <div class=" nav-item relative" role="presentation">
                          <i
                            class="bi bi-dash-circle icon-left"
                            onClick={() => {
                              if (bet.betB > 10) {
                                setBet({ ...bet, betB: Number(bet.betB) - 1 });
                                sessionStorage.setItem(
                                  "amountBetB",
                                  Number(bet.betB) - 1
                                );
                              } else {
                                toast.error("Minimum bet amount is 10 INR", {
                                  position: "top-right",
                                  autoClose: 900,
                                });
                              }
                            }}
                          ></i>
                          <input
                            type="text"
                            class="form-control form-control-sm fs-16 avibet me-2 w-100"
                            onChange={(e) => {
                              setBet({ ...bet, betB: e.target.value });
                            }}
                            value={bet?.betB}
                          />
                          <i
                            class="bi bi-plus-circle icon-right"
                            onClick={() => {
                              if (bet.betB + 1 <= 1000) {
                                setBet({ ...bet, betB: Number(bet.betB) + 1 });
                                sessionStorage.setItem(
                                  "amountBetB",
                                  Number(bet.betB) + 1
                                );
                              } else {
                                toast.error("Maximum bet amount is 1000 INR", {
                                  position: "top-right",
                                  autoClose: 900,
                                });
                              }
                            }}
                          ></i>
                        </div>
                        <ul class="nav nav-pills" role="tablist">
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy == 10) {
                                  if (bet.betB + 10 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betB: Number(bet.betB) + 10,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetB",
                                      Number(bet.betB) + 10
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betB: 10 });
                                  sessionStorage.setItem("amountBetB", 10);
                                  setAmountUpdatedBy(10);
                                }
                              }}
                            >
                              10+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy == 20) {
                                  if (bet.betB + 20 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betB: Number(bet.betB) + 20,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetB",
                                      Number(bet.betB) + 20
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betB: 20 });
                                  sessionStorage.setItem("amountBetB", 20);
                                  setAmountUpdatedBy(20);
                                }
                              }}
                            >
                              20+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy == 50) {
                                  if (bet.betB + 50 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betB: Number(bet.betB) + 50,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetB",
                                      Number(bet.betB) + 50
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betB: 50 });
                                  sessionStorage.setItem("amountBetB", 50);
                                  setAmountUpdatedBy(50);
                                }
                              }}
                            >
                              50+
                            </button>
                          </li>
                          <li class="nav-item addbid" role="presentation">
                            <button
                              class="nav-link"
                              onClick={() => {
                                if (amountUpdatedBy == 100) {
                                  if (bet.betB + 100 <= 1000) {
                                    setBet({
                                      ...bet,
                                      betB: Number(bet.betB) + 100,
                                    });
                                    sessionStorage.setItem(
                                      "amountBetB",
                                      Number(bet.betB) + 100
                                    );
                                  } else {
                                    toast.error(
                                      "Maximum bet amount is 1000 INR",
                                      {
                                        position: "top-right",
                                        autoClose: 900,
                                      }
                                    );
                                  }
                                } else {
                                  setBet({ ...bet, betB: 100 });
                                  sessionStorage.setItem("amountBetB", 100);
                                  setAmountUpdatedBy(100);
                                }
                              }}
                            >
                              100+
                            </button>
                          </li>
                        </ul>

                        <input
                          type="text"
                          className="form-control mt-1"
                          onChange={(e) => {
                            setValidateAutoCut({
                              ...validateAutoCut,
                              autoCutB: "",
                            });

                            setAutoCutFormData({
                              autoCut: e.target.value,
                              betA: null,
                              betB: true,
                            });
                          }}
                        />
                        <span className="text-danger text-center small">
                          {validateAutoCut?.autoCutB}
                        </span>
                      </div>
                      <div class="col-md-6 col-6">
                        {autoCutBtn?.autoCutBtnB ? (
                          <button
                            class="btn btn-block text-center btn-bet"
                            onClick={autoCut}
                          >
                            Auto Cut
                          </button>
                        ) : (
                          <button
                            class="btn btn-block text-center btn-bet"
                            onClick={() => {
                              cancelAutoCut("autoCutB");
                            }}
                          >
                            Cancel Auto Cut
                          </button>
                        )}

                        {!btnPlaceholder?.betB ? (
                          <button
                            class="btn btn-block text-center btn-bet"
                            onClick={(e) => {
                              e.preventDefault();
                              putBet({ betB: bet?.betB });
                            }}
                            // disabled={btnDisable}
                          >
                            BET -{" "}
                            <span>
                              {" "}
                              {bet?.betB && bet?.betB < 10
                                ? "0" + bet?.betB
                                : bet?.betB
                                ? bet?.betB
                                : "00"}
                            </span>
                          </button>
                        ) : null}

                        {!btnPlaceholder?.betBCancel ? (
                          <button
                            class="btn btn-block text-center btn-danger mt-3 mb-2  fs-16 dont-w600"
                            onClick={(e) => {
                              e.preventDefault();
                              cancelBet({ betB: true });
                            }}
                          >
                            CANCEL
                          </button>
                        ) : null}

                        {!btnPlaceholder?.betBCash ? (
                          <button
                            class="btn btn-block text-center btn-warning mt-3 mb-2 fs-16 dont-w600"
                            onClick={(e) => {
                              e.preventDefault();
                              cashOut({ betB: true });
                            }}
                          >
                            CASH OUT -{" "}
                            <span>{(bet?.betB * timeX).toFixed(2)} INR</span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Recent Winners</h4>
                </div>
                <div class="card-body py-2 px-2 ">
                  <button
                    className="btn btn-show-data"
                    onClick={(e) => {
                      e.preventDefault();
                      setRecentBetsTable({
                        allBets: true,
                        myBets: false,
                        top: false,
                      });
                    }}
                  >
                    All Bets {allBets.length}
                  </button>
                  <button
                    className="btn btn-show-data"
                    onClick={(e) => {
                      e.preventDefault();
                      setRecentBetsTable({
                        allBets: false,
                        myBets: true,
                        top: false,
                      });
                    }}
                  >
                    My Bets
                  </button>
                  <button
                    className="btn btn-show-data"
                    onClick={(e) => {
                      e.preventDefault();
                      setRecentBetsTable({
                        allBets: false,
                        myBets: false,
                        top: true,
                      });
                    }}
                  >
                    Top Bets
                  </button>
                  <div className="table-responsive mt-2 card-height">
                    <table class="table  text-nowrap text-white  table-winner  order-tbl">
                      <thead className="sticky-md-top head-cash">
                        <th className="px-1 py-2 head-cash">
                          {recentBetsTable.allBets ? "User" : "Date/Time"}
                        </th>

                        <th className="px-1 py-2 head-cash">Bet</th>

                        <th className="px-1 py-2 head-cash">X</th>

                        <th className="px-1 py-2 head-cash">Cash Out</th>
                      </thead>

                      {recentBetsTable.allBets ? (
                        <tbody>
                          {allBets?.map((elem, index) => {
                            return (
                              <tr
                                className={
                                  elem.cashed ||
                                  elem.paymentStatusA ||
                                  elem.paymentStatusB
                                    ? "CashOut"
                                    : ""
                                }
                                key={index}
                              >
                                <td className="text-start px-1 py-2 font-10">
                                  {elem?.name}
                                </td>
                                <td className="px-1 py-2">{elem?.bet} INR</td>

                                <td className="px-1 py-2">
                                  {elem.cashed ||
                                  elem.paymentStatusA ||
                                  elem.paymentStatusB ? (
                                    <>{(elem?.X).toFixed(2)}x</>
                                  ) : null}
                                </td>
                                <td className="px-1 py-2">
                                  {elem.cashed ||
                                  elem.paymentStatusA ||
                                  elem.paymentStatusB ? (
                                    <>{(elem?.X * elem?.bet).toFixed(2)} INR</>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : null}

                      {recentBetsTable.myBets ? (
                        <tbody>
                          {myBets?.map((elem, index) => {
                            if (elem) {
                              return (
                                <tr>
                                  <td class="text-start">
                                    {humanReadableTimeAndDate(elem?.createdAt)}
                                  </td>
                                  <td class={elem.X ? "text-success" : ""}>
                                    {elem?.bet} INR
                                  </td>
                                  <td class={elem.X ? "text-success" : ""}>
                                    {elem?.X ? elem?.X : "00"}
                                  </td>
                                  <td class={elem.X ? "text-success" : ""}>
                                    {(elem?.X * elem?.bet).toFixed(2)} INR
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      ) : null}

                      {recentBetsTable.top ? (
                        <tbody>
                          <tr>
                            <td class="text-start">top</td>
                            <td class="text-success">519.21</td>
                            <td class="text-success">519.21</td>
                            <td class="text-success">519.21 </td>
                          </tr>
                        </tbody>
                      ) : null}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
