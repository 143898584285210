import mongoose from "mongoose";
import { jwtDecode } from "jwt-decode";

export function getUserIDFromToken() {
  if (sessionStorage.getItem("token")) {
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);
    return decoded.userId;
  } else {
    window.location.href = "/";
  }
}

export function getRoleFromToken() {
  if (sessionStorage.getItem("token")) {
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);

    return decoded;
  } else {
    window.location.href = "/";
  }
}

export function breakPath(path) {
  const pathArr = [];
  let pathStr = "";
  for (let i = 1; i < path?.length; i++) {
    if (path?.charAt(i) != "/") {
      pathStr += path?.charAt(i);
      if (i == path?.length - 1) {
        pathArr?.push(pathStr);
      }
    } else {
      pathArr?.push(pathStr);
      pathStr = "";
    }
  }
  return pathArr;
}

function isAlphabet(char) {
  return /^[a-zA-Z]$/.test(char);
}

export function filterHeading(heading) {
  let filteredHeading = "";
  for (let i = 0; i < heading?.length; i++) {
    if (i == 0) {
      filteredHeading += heading?.charAt(i)?.toUpperCase();
    } else if (!isAlphabet(heading?.charAt(i))) {
      filteredHeading += " " + heading?.charAt(i + 1).toUpperCase();
      i++;
    } else if (isAlphabet(heading?.charAt(i))) {
      filteredHeading += heading?.charAt(i);
    }
  }

  return filteredHeading;
}

//This function returns an object indecating if the input time belongs to past and the time difference between current time and the input time
export function checkTimeAndDifference(inputTimeString) {
  const inputTime = new Date(inputTimeString);
  const currentTime = new Date();

  // Determine if the input time is in the past
  const isPast = inputTime < currentTime;

  // Calculate the difference in milliseconds
  let diffInMilliseconds = Math.abs(currentTime - inputTime);

  // Convert the difference to HH:MM:SS
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  diffInMilliseconds -= hours * 1000 * 60 * 60;
  const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
  diffInMilliseconds -= minutes * 1000 * 60;
  const seconds = Math.floor(diffInMilliseconds / 1000);

  // Format the time difference as HH:MM:SS
  const timeDifference = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  // Return the result as an object
  return {
    isPast: isPast,
    timeDifference: timeDifference,
  };
}

export function formatDate(inputTime) {
  const dubaiOffset = 4 * 60; // Dubai is UTC+4 (4 hours offset)

  const date = new Date(inputTime);
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60 * 1000; // Convert to UTC
  const dubaiTime = new Date(utcTime + dubaiOffset * 60 * 1000); // Apply Dubai offset

  // Format the date as "12 Sept 2024, 02:00 PM"
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for day
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Get hours and minutes in 12-hour format with AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero for minutes
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight as 12 AM
  hours = hours.toString().padStart(2, "0"); // Add leading zero for hours

  return `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
}

// Subtract a given time from the time difference and return in HH:MM:SS format
export function subtractTimeFromDifference(givenTimeStr) {
  // Convert the input time to a Date object
  const givenTime = new Date(givenTimeStr);

  // Get the current time
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  let diffInMs = Math.abs(givenTime - currentTime);

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  diffInMs %= 1000 * 60 * 60;
  const minutes = Math.floor(diffInMs / (1000 * 60));
  diffInMs %= 1000 * 60;
  const seconds = Math.floor(diffInMs / 1000);

  // Format the difference in HH:MM:SS format
  const formattedTime =
    String(hours).padStart(2, "0") +
    ":" +
    String(minutes).padStart(2, "0") +
    ":" +
    String(seconds).padStart(2, "0");

  return formattedTime;
}

// Function to check if a given date string is in the past after adding a duration
export function isInPastWithAddedDuration(dateString) {
  const currentTime = new Date();
  const departureTime = new Date(dateString);
  return currentTime > departureTime;
}

export function isValidObjectId(id) {
  return mongoose.Types.ObjectId.isValid(id) && /^[a-fA-F0-9]{24}$/.test(id);
}

//Returns current time after adding 05:30:00 HH:MM:SS in that
export function getCurrentTimeInISOFormatWithOffset() {
  const date = new Date();
  // Add 5 hours and 30 minutes to the current time
  date.setUTCMinutes(date.getUTCMinutes() + 330); // 5 hours and 30 minutes = 330 minutes
  // Format the date to 'YYYY-MM-DDTHH:mm:ss.000Z'
  return date.toISOString();
}

export function checkIfOnlyFiveMinutesLeft(departureTimeStr) {
  const currentTimeStr = getCurrentTimeInISOFormatWithOffset();

  const currentTime = new Date(currentTimeStr);
  const departureTime = new Date(departureTimeStr);

  const timeDifferenceInMs = departureTime - currentTime;
  const timeDifferenceInMinutes = timeDifferenceInMs / (1000 * 60);

  if (timeDifferenceInMinutes > 0 && timeDifferenceInMinutes < 5) {
    return true;
  } else {
    return false;
  }
}

export function getDubaiTime() {
  const options = {
    timeZone: "Asia/Dubai",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  // Get the Dubai time parts
  const dubaiTimeParts = new Intl.DateTimeFormat(
    "en-US",
    options
  ).formatToParts(new Date());

  // Extract the relevant parts for custom formatting
  const day = dubaiTimeParts
    .find((part) => part.type === "day")
    .value.padStart(2, "0");
  const month = dubaiTimeParts.find((part) => part.type === "month").value;
  const year = dubaiTimeParts.find((part) => part.type === "year").value;
  const hour = dubaiTimeParts
    .find((part) => part.type === "hour")
    .value.padStart(2, "0");
  const minute = dubaiTimeParts
    .find((part) => part.type === "minute")
    .value.padStart(2, "0");
  const second = dubaiTimeParts
    .find((part) => part.type === "second")
    .value.padStart(2, "0");
  const dayPeriod = dubaiTimeParts.find(
    (part) => part.type === "dayPeriod"
  ).value;

  // Return the custom formatted string with leading zeros
  return `${day} ${month} ${year}, ${hour}:${minute}:${second} ${dayPeriod}`;
}




export function convertToISODateString(dateString) {
  // Create a new Date object from the given YYYY-MM-DD string
  const date = new Date(dateString);

  // Set the time to 00:00:00.000 UTC (start of the day)
  const isoDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));

  // Return the date in ISO 8601 format with UTC timezone
  return isoDate.toISOString().replace('Z', '+00:00');
}