import { useState } from "react";
import Token from '../../../Helper/Token';
import { toast } from 'react-toastify';
const PinRequest = () => {
    const [Reqdata, setReqdata] = useState();
    const [btnLoader, setbtnloader] = useState();
    const [Res, setRes] = useState({status: 0, message: ''});
    const ColelectInp = (e) => setReqdata({ ...Reqdata, [e.currentTarget.name]: e.currentTarget.value });
    const getReceipt = (e) => {
        const fildName = e.currentTarget.name;
        if (e.currentTarget.files[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(e.currentTarget.files[0]);
            reader.onload = () => { setReqdata({ ...Reqdata, [fildName]: reader.result.split(",")[1] }) }
            reader.onerror = (error) => { setReqdata({ ...Reqdata, [fildName]: '' }) }
        } else { setReqdata({ ...Reqdata, [fildName]: '' }) }
    }
    const OnlinePayment = () =>{
        setRes({status: 2, message: 'Online Payment Comming Soon..!!'});
    }
    const FormRequest = (e) => {
        e.preventDefault();
        if (Reqdata && Reqdata.PMode === 'Online Payment') {
            OnlinePayment();
        } else {
            setbtnloader(true);
            setRes({});
            Token.post('/Epinrequest', Reqdata).then((res) => {
                if(res.status===200 && parseInt(res.data.Status)===1){
                    setRes({status: 1, message: 'Thank you! Your Request Successfully Submitted.'});
                    toast.success('Thank you! Your Request Successfully Submitted.', { position: "top-left", autoClose: 900 });
                }else{ 
                    setRes({status: 2, message: res.data.Message});
                    toast.error(res.data.Message, { position: "top-left", autoClose: 900 }) 
                }
                setbtnloader(false);
            }).catch((error) => { 
                setRes({status: 2, message: 'Internal Server Error.'});
                toast.error('Internal Server Error.', { position: "top-left", autoClose: 900 }); 
                setbtnloader(false); })
        }
    }
    return (<>
        <div class="page-titles">
            <div class="sub-dz-head">
                <div class="d-flex align-items-center dz-head-title">
                    <h2 class="text-white m-0">Pin Details</h2>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active ms-auto">
                        <a class="d-flex align-self-center">
                            <span>
                                <i class="bi bi-ui-checks"></i>
                            </span>
                            Pin Details
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a>Pin Request</a>
                    </li>
                </ol>
            </div>
        </div>
        <div class="content-body">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Pin Request</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="panel panel-default card-view pt-0">
                                            <div class="clearfix"></div>
                                            <div class="panel-wrapper">
                                                <div class="panel-body">
                                                    <div class="form-wrap">
                                                        { Res && Res.status===2?<div class="alert alert-danger" role="alert">{Res.message}</div>: Res && Res.status===1?<div class="alert alert-success" role="alert">{Res.message}</div>:<></>}
                                                        <form onSubmit={FormRequest}>
                                                            <div class="form-horizontal">
                                                                <div class="form-group row">
                                                                    <label htmlFor="Quantity" class="col-sm-4 control-label">Pin Quantity</label>
                                                                    <div class="col-sm-8">
                                                                        <input type="number" id="Quantity" name="PinNo" class="form-control" placeholder="Quantity" min='1' onInput={ColelectInp} required />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label htmlFor="pinvalue" class="col-sm-4 control-label" >Pin Value</label>
                                                                    <div class="col-sm-8">
                                                                        <select class="form-control" name="Amount" id="pinvalue" onChange={ColelectInp} required>
                                                                            <option value="" required>--Select Pin Value--</option>
                                                                            <option value="599">599</option>
                                                                            <option value="699">699</option>
                                                                            <option value="799">799</option>
                                                                            <option value="899">899</option>
                                                                            <option value="999">999</option>
                                                                            <option value="1001">1001</option>
                                                                            <option value="1197">1197</option>
                                                                            <option value="1199">1199</option>
                                                                            <option value="1999">1999</option>
                                                                            <option value="1499">1499</option>
                                                                            <option value="1498">1498</option>
                                                                            <option value="1501">1501</option>
                                                                            <option value="1599">1599</option>
                                                                            <option value="2999">2999</option>
                                                                            <option value="3500">3500</option>
                                                                            <option value="3701">3701</option>
                                                                            <option value="4000">4000</option>
                                                                            <option value="4500">4500</option>
                                                                            <option value="4499">4499</option>
                                                                            <option value="5500">5500</option>
                                                                            <option value="20000">20000</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label htmlFor="Payable Amount" class="col-sm-4 control-label" >Payable Amount</label>
                                                                    <div class="col-sm-8">
                                                                        <input type="number" id="PayableAmount" value={Reqdata && Reqdata.Amount ? parseFloat(Reqdata.Amount).toFixed(2) : ""} class="form-control" placeholder="Payable Amount" readOnly />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <label htmlFor="pmode" class="col-sm-4 control-label" >Payment Mode</label>
                                                                    <div class="col-sm-8">
                                                                        <select class="form-control" name="PMode" id="pmode" onChange={ColelectInp} required>
                                                                            <option value="" selected={Reqdata && Reqdata.PMode === '' ? true : false}>--Select Payment Mode--</option>
                                                                            <option value="Online Payment" selected={Reqdata && Reqdata.PMode === 'Online Payment' ? true : false}>Online Payment</option>
                                                                            <option value="Through Bank" selected={Reqdata && Reqdata.PMode === 'Through Bank' ? true : false}>Through Bank</option>
                                                                            <option value="Cash" selected={Reqdata && Reqdata.PMode === 'Cash' ? true : false}>Cash</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {Reqdata && Reqdata.PMode === 'Through Bank' ?
                                                                    <>
                                                                        <div class="form-group row">
                                                                            <label htmlFor="bname" class="col-sm-4 control-label" >Bank Name</label>
                                                                            <div class="col-sm-8">
                                                                                <input type="text" id="bname" name="BankName" class="form-control" placeholder="Bank Name" onInput={ColelectInp} required />
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row">
                                                                            <label htmlFor="acholdername" class="col-sm-4 control-label" >Account Holder Name</label>
                                                                            <div class="col-sm-8">
                                                                                <input type="text" id="acholdername" name="AccountHName" class="form-control" placeholder="Account Holder Name" onInput={ColelectInp} required />
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row">
                                                                            <label htmlFor="UTRN" class="col-sm-4 control-label" >UTR No/ Transaction No</label>
                                                                            <div class="col-sm-8">
                                                                                <input type="number" id="UTRN" name="UtrNo" class="form-control" placeholder="UTR No" onInput={ColelectInp} required />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>}
                                                                <div class="form-group row">
                                                                    <label class="col-sm-4 control-label " htmlFor="remark">Remark</label>
                                                                    <div class="col-sm-8">
                                                                        <textarea rows="4" cols="20" id="remark" class="form-control" placeholder="Remark" name="Remarks" onInput={ColelectInp} required></textarea>
                                                                    </div>
                                                                </div>
                                                                {Reqdata && Reqdata.PMode && Reqdata.PMode !== 'Online Payment' ?
                                                                    <>
                                                                        <div class="form-group row">
                                                                            <label htmlFor="Receipt" class="col-sm-4 control-label" >Upload Receipt</label>
                                                                            <div class="col-sm-8">
                                                                                <input type="file" id="Receipt" name="Receipt" class="form-control" onChange={getReceipt} required />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>}
                                                                <div class="form-group row">
                                                                    <label class="col-sm-4 control-label" ></label>
                                                                    <div class="col-sm-8">
                                                                        {btnLoader ? <button type="button" className="btn btn-warning btnloader" disabled><div class="spinner-border" role="status"></div> Wait...</button> : <button type="submit" className="btn btn-warning">Submit</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Bank Details</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="panel panel-default card-view pt-0">
                                            <div class="panel-wrapper">
                                                <div class="panel-body">
                                                    <div class="table-wrap">
                                                        <div class="table-responsive">
                                                            <table class="table" style={{ fontSize: "17px" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Company Bank Name</td>
                                                                        <td>NV SHOPPE SALES &amp; MARKETING PVT LTD</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Bank Name</td>
                                                                        <td>IDFC FIRST BANK</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Acc No.</td>
                                                                        <td>10061935382</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>IFSC Code</td>
                                                                        <td>IDFB0020138</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Bank Address</td>
                                                                        <td>MAYUR VIHAR DELHI-92</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>UPI</td>
                                                                        <td>BHARATPE.0101677107@INDUS</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default PinRequest;