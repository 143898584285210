import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import token from "../../../../Helper/Token";

export default function UpdateKYCAdmin() {
  const [showPasswords, setShowPasswords] = useState({
    bankPassword: "password",
    upiPassword: "password",
    kmtPassword: "password",
    usdtPassword: "password",
  });

  const [loading, setLoading] = useState({
    bankLoading: false,
    upiLoading: false,
    kmtLoading: false,
    usdtLoading: false,
  });

  const [formData, setFormData] = useState({
    bankName: "",
    branchName: "",
    ifsc: "",
    accountNumber: "",
    upiAddress: "",
    usdtAddress: "",
    kmtAddress: "",
    kmtQR: "",
    upiQR: "",
    usdtQR: "",
  });

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    branchName: "",
    ifsc: "",
    accountNumber: "",
    transactionPassword: "",
  });

  const [upiDetails, setupiDetails] = useState({
    upiAddress: "",
    upiQR: "",
    transactionPassword: "",
  });

  const [kmtDetails, setkmtDetails] = useState({
    kmtAddress: "",
    kmtQR: "",
    transactionPassword: "",
  });

  const [usdtDetails, setUsdtDetails] = useState({
    usdtAddress: "",
    usdtQR: "",
    transactionPassword: "",
  });

  useEffect(() => {
    fetch_kyc_data();
  }, []);

  function fetch_kyc_data() {
    token
      .get("/admin/kyc-details")
      .then((res) => {
        if (res.data.status == "success") {
          setFormData(res?.data?.data);
          setBankDetails(res?.data?.data);
          setupiDetails(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateBankDetails(e) {
    e.preventDefault();

    setLoading({ ...loading, bankLoading: true });
    token
      .put("/admin/bank-details", bankDetails)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });

          setBankDetails({ ...bankDetails, transactionPassword: "" });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading({ ...loading, bankLoading: false });
      });
  }

  function updateUPIDetails(e) {
    e.preventDefault();
    setLoading({ ...loading, upiLoading: true });

    const formInfo = new FormData();
    formInfo.append("upiAddress", upiDetails.upiAddress);
    formInfo.append("transactionPassword", upiDetails.transactionPassword);
    formInfo.append("image", upiDetails.upiQR);

    token
      .put("/admin/upi-details", formInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetch_kyc_data();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading({ ...loading, upiLoading: false });
        setupiDetails({ ...upiDetails, transactionPassword: "" });
      });
  }

  function updateKMTDetails(e) {
    e.preventDefault();
    setLoading({ ...loading, kmtLoading: true });
    const formInfo = new FormData();
    formInfo.append("kmtAddress", kmtDetails.kmtAddress);
    formInfo.append("transactionPassword", kmtDetails.transactionPassword);
    formInfo.append("image", kmtDetails.kmtQR);

    token
      .put("/admin/kmt-details", formInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetch_kyc_data();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading({ ...loading, kmtLoading: false });
        setkmtDetails({ ...kmtDetails, transactionPassword: "" });
      });
  }

  function updateUSDTDetails(e) {
    e.preventDefault();

    setLoading({ ...loading, usdtLoading: true });
    const formInfo = new FormData();
    formInfo.append("usdtAddress", usdtDetails.usdtAddress);
    formInfo.append("transactionPassword", usdtDetails.transactionPassword);
    formInfo.append("image", usdtDetails.usdtQR);

    token
      .put("/admin/usdt-details", formInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetch_kyc_data();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading({ ...loading, usdtLoading: false });
        setUsdtDetails({ ...usdtDetails, transactionPassword: "" });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">My Account</h2>
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  <i class="bi bi-ui-checks"></i>
                </span>
                My Account
              </a>
            </li>
            <li class="breadcrumb-item">
              <a>Kyc</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">UPI KYC</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={updateUPIDetails}>
                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="upi">
                          UPI Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="upi"
                          defaultValue={formData.upiAddress}
                          onChange={(e) => {
                            setupiDetails({
                              ...upiDetails,
                              upiAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label
                          class="form-label text-light"
                          htmlFor="upi-code-pic"
                        >
                          UPI QR Code
                        </label>

                        <input
                          type="file"
                          accept="image/*"
                          class="form-control form-control-sm"
                          id="upi-code-pic"
                          onChange={(e) => {
                            setupiDetails({
                              ...upiDetails,
                              upiQR: e.target.files[0],
                            });
                          }}
                        />
                      </div>

                      <img
                        src={formData.upiQR}
                        className="qr-image m-5"
                        alt="UPI QR CODE"
                      />

                      <div class="position-relative mt-4">
                        <label
                          htmlFor="transactionPassword"
                          class="form-label text-light required"
                        >
                          Transaction Password
                        </label>
                        <input
                          type={showPasswords.upiPassword}
                          id="transactionPassword"
                          class="form-control text-light"
                          placeholder="Enter your transaction password"
                          value={upiDetails?.transactionPassword}
                          onChange={(e) => {
                            setupiDetails({
                              ...upiDetails,
                              transactionPassword: e.target.value,
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.upiPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  upiPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  upiPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      {!loading?.upiLoading ? (
                        <button
                          type="submit"
                          class="btn btn-primary btn-sm mt-5"
                        >
                          Update UPI Details
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary  mt-5"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Tron KYC</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={updateKMTDetails}>
                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="kmt">
                           Tron Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="kmt"
                          defaultValue={formData.kmtAddress}
                          onChange={(e) => {
                            setkmtDetails({
                              ...kmtDetails,
                              kmtAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label
                          class="form-label text-light"
                          htmlFor="kmt-code-pic"
                        >
                          Tron QR Code
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          class="form-control form-control-sm"
                          id="kmt-code-pic"
                          onChange={(e) => {
                            setkmtDetails({
                              ...kmtDetails,
                              kmtQR: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                      <img
                        src={formData.kmtQR}
                        className="qr-image m-5"
                        alt="Tron QR CODE"
                      />

                      <div class="position-relative mt-4">
                        <label
                          htmlFor="transactionPassword"
                          class="form-label text-light required"
                        >
                          Transaction Password
                        </label>
                        <input
                          type={showPasswords.kmtPassword}
                          id="transactionPassword"
                          class="form-control text-light"
                          placeholder="Enter your transaction password"
                          value={kmtDetails?.transactionPassword}
                          onChange={(e) => {
                            setkmtDetails({
                              ...kmtDetails,
                              transactionPassword: e.target.value,
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.kmtPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  kmtPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  kmtPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      {!loading?.kmtLoading ? (
                        <button
                          type="submit"
                          class="btn btn-primary btn-sm mt-5"
                        >
                          Update Tron Details
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary mt-5"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">USDT KYC</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={updateUSDTDetails}>
                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="usdt">
                          USDT Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="usdt"
                          defaultValue={formData.usdtAddress}
                          onChange={(e) => {
                            setUsdtDetails({
                              ...usdtDetails,
                              usdtAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label
                          class="form-label text-light"
                          htmlFor="usdt-code-pic"
                        >
                          USDT QR Code
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          class="form-control form-control-sm"
                          id="usdt-code-pic"
                          onChange={(e) => {
                            setUsdtDetails({
                              ...usdtDetails,
                              usdtQR: e.target.files[0],
                            });
                          }}
                        />
                      </div>

                      <img
                        src={formData.usdtQR}
                        className="qr-image m-5"
                        alt="USDT QR CODE"
                      />

                      <div class="position-relative mt-4">
                        <label
                          htmlFor="transactionPassword"
                          class="form-label text-light required"
                        >
                          Transaction Password
                        </label>
                        <input
                          type={showPasswords.usdtPassword}
                          id="transactionPassword"
                          class="form-control text-light"
                          placeholder="Enter your transaction password"
                          value={usdtDetails?.transactionPassword}
                          onChange={(e) => {
                            setUsdtDetails({
                              ...usdtDetails,
                              transactionPassword: e.target.value,
                            });
                          }}
                        />

                        <span class=" eye">
                          {showPasswords.usdtPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  usdtPassword: "password",
                                });
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowPasswords({
                                  ...showPasswords,
                                  usdtPassword: "text",
                                });
                              }}
                            ></i>
                          )}
                        </span>
                      </div>

                      {!loading?.usdtLoading ? (
                        <button
                          type="submit"
                          class="btn btn-primary btn-sm mt-5"
                        >
                          Update USDT Details
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary mt-5"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
