import { useEffect } from 'react';
import Token from './Token';
import {User} from './Variables';
export const AutoLoad = () => {
    const Info = User();
    useEffect(()=>{
        if(Info.Authorized.Auth){
            Token.get('/Dashboarddetails2').then((res)=>{
                if(res.status===200 && parseInt(res.data.Status)===1){ Info.setAuth(res.data.Payload[0])}
            }).catch((err)=>{ console.log(err)})
        }
    },[])
    return(<></>)
}