import { Link } from "react-router-dom";
import { checkIfOnlyFiveMinutesLeft, getCurrentTimeInISOFormatWithOffset, isInPastWithAddedDuration} from "../../helper/function/Helper.function";
import { useEffect, useState } from "react";

export default function UserFlycrickFlightCardButtons({
  departureTime,
  flightId,
  betCount,
}) {
  const [currentTime, setCurrentTime] = useState("");

  const [button, setButton] = useState({
    betNow: false,
    landed: false,
    bookingClosed: false,
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        !isInPastWithAddedDuration(departureTime) &&
        !checkIfOnlyFiveMinutesLeft(departureTime)
      ) {
        setButton({ betNow: true });
      }

      if (isInPastWithAddedDuration(departureTime)) {
        setButton({ landed: true });
      }

      if (checkIfOnlyFiveMinutesLeft(departureTime)) {
        setButton({ bookingClosed: true });
      }

      setCurrentTime(getCurrentTimeInISOFormatWithOffset());
    }, 1000);
  }, [currentTime]);

  return (
    <>
      {button?.betNow ? (
        <>
          {betCount != 0 ? (
            <span className="btn  button-9 btn-success  btn-sm">
              Booked {betCount < 10 ? "0" + betCount : betCount}
            </span>
          ) : null}

          <Link
            to={`/user/random-flight/bet-plane/${flightId}`}
            className="text-center border-0 pt-1"
          >
            <span className="btn btn-block button-9 btn-primary  btn-sm">
              {betCount ? <i class="bi bi-plus-lg"></i> : "Book Now"}
            </span>
          </Link>
        </>
      ) : null}

      {button?.landed ? (
        <Link className="text-center border-0 pt-1">
          <span className="btn btn-block button-9 btn-success  btn-sm">
            Landed
          </span>
        </Link>
      ) : null}

      {button?.bookingClosed ? (
        <Link className="text-center border-0 pt-1">
          <span className="btn btn-block button-9 btn-danger  btn-sm">
            Booking Closed
          </span>
        </Link>
      ) : null}
    </>
  );
}
