import { MDBDataTable } from 'mdbreact';
import { useState, useEffect } from 'react';
import Token from '../../../Helper/Token';
const UnuseWallet = () => {
    const [loader, setLoader] = useState(true);
    const [Record, setRecord] = useState([]);
    const table = {
        columns: [
            {
                label: 'S.No',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Reg No',
                field: 'regno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Order Id',
                field: 'ordid',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Amount',
                field: 'amt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Walet',
                field: 'walet',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Net Amount',
                field: 'netbankamt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'date',
                sort: 'asc',
                width: 150
            }
        ],
        rows: []
    };
    const NewDate = (e) => {
        let d = new Date(e);
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][d.getMonth()];
        return d.getDate() + '/' + months + '/' + d.getFullYear();
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        Token.get('/UnUsedCoupon').then(res => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setRecord(res.data.Payload); }
            setLoader(false)
        }).catch(err => { setLoader(false) })
    }, [])
    Record.map((tr, i) => {
        table.rows.push({
            id: i < 10 ? "0" + (i + 1) : (i + 1),
            regno: tr.regno,
            ordid: tr.Orderid,
            amt: tr.Amount.toFixed(2),
            walet: tr.CouponWalet.toFixed(2),
            netbankamt: tr.NetBankAmt.toFixed(2),
            date: NewDate(tr.createdate)
        })
    })
    return (
        <>
            <div class="page-titles">
                <div class="sub-dz-head">
                    <div class="d-flex align-items-center dz-head-title">
                        <h2 class="text-white m-0">Discount Wallet</h2>
                    </div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active ms-auto">
                            <a class="d-flex align-self-center">
                                <span>
                                    <i class="bi bi-ui-checks"></i>
                                </span>
                                Discount Wallet
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a>Unused</a>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="content-body">
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Unused</h4>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        {loader ?
                                            <p className="card-text placeholder-glow">
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-4"></span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-8"></span>
                                            </p> :
                                            <MDBDataTable bordered small data={table} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnuseWallet;
