import { useState, useEffect } from "react";
import { socket } from "../../../Helper/Variables";
import { useNavigate, useParams } from "react-router";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { getUserIDFromToken } from "../../../Helper/function";

export default function QuizDifficultyLevel() {
  const [totalBalance, setTotalBalance] = useState(0);
  const [quizTimer, setQuizTimer] = useState(0);
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  let [questionId, setQuestionId] = useState("");
  const { quizCategory } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("game-over");
  }, []);

  useEffect(() => {
    socket.on("update-game-timers", (timers) => {
      setQuizTimer(timers?.quizTimer);
    });
    return () => {
      socket.off("update-quiz-timer");
    };
  });

  useEffect(() => {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          const totBal = res?.data?.data?.availableBalance.toFixed(2)
          setTotalBalance(totBal);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);



  useEffect(() => {
    token
      .get(
        `/quiz-game/get-unique-question-id?categoryId=${quizCategory}&difficultyLevel=${difficultyLevel}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          setQuestionId(res?.data?.data?._id);
          sessionStorage.removeItem("answered");
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, [difficultyLevel]);









  function applyQuizGame(difficultyLevel) {
    sessionStorage.removeItem("answered");
    token
      .post("/quiz-game/apply-for-game", {
        difficultyLevel,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          deductBalance();
          navigate(
            `/user/play-quiz/${quizCategory}/${questionId}/${difficultyLevel}`
          );
          sessionStorage.setItem("isGameRunning", false);
          socket.emit("quiz-joined", getUserIDFromToken());
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }


  

  function deductBalance() {
    let amount = 0;

    if (difficultyLevel == 1) {
      amount = -10;
    } else if (difficultyLevel == 2) {
      amount = -20;
    } else if (difficultyLevel == 3) {
      amount = -40;
    } else if (difficultyLevel == 4) {
      amount = -80;
    } else if (difficultyLevel == 5) {
      amount = -160;
    } else if (difficultyLevel == 6) {
      amount = -320;
    } else if (difficultyLevel == 7) {
      amount = -640;
    } else {
      amount = 0;
    }

    token
      .put("/user/update-available-balance", { amount })
      .then((res) => {
        if (res.data.status == "success") {
          // toast.success(res?.data?.message, {
          //   position: "top-left",
          //   autoClose: 900,
          // });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">Quiz Level</h2>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12 col-xxl-12">
              <div class="row quizee">
                <div class="col-xl-4 col-sm-6 col-6 ">
                  <a href="/admin/all-users-report">
                    <div class="card banking-card pull-up pull-up">
                      <div class="card-header border-0 flex-wrap pb-0">
                        <div class="revenue-date text-light">
                          <span>Total Balance</span>
                          <h4>
                            {" "}
                            ₹
                            {totalBalance < 10
                              ? "0" + totalBalance
                              : totalBalance}
                          </h4>
                        </div>
                        <div class="setting bg-primary">
                          <i class="bi bi-cash-stack text-light"></i>
                        </div>
                      </div>
                      <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                    </div>
                  </a>
                </div>

                <div class="col-xl-4 col-sm-6 col-6">
                  <a href="/admin/fund-request-report/1">
                    <div class="card banking-card pull-up pull-up">
                      <div class="card-header border-0 flex-wrap pb-0">
                        <div class="revenue-date text-light">
                          <span> Total Win</span>
                          <h4> 20540.00</h4>
                        </div>
                        <div class="setting bg-success">
                        <i class="bi bi-trophy text-light"></i>
                        </div>
                      </div>
                      <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                    </div>
                  </a>
                </div>

                <div class="col-xl-4 col-sm-6 col-12">
                  <a href="/admin/all-users-report">
                    <div class="card banking-card pull-up">
                      <div class="card-header border-0 flex-wrap pb-0">
                        <div class="revenue-date text-light">
                          <span>Quiz Play</span>
                          <h4> 10</h4>
                        </div>
                        <div class="setting bg-warning">
                        <i class="bi bi-collection-play text-light"></i>
                        </div>
                      </div>
                      <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                    </div>
                  </a>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level One</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹20
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(1);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹10
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Two</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹40
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(2);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹20
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Three</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹80
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(3);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹40
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Four</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹160
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(4);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹80
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Five</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹360
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(5);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹160
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Five</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹640
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(6);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹320
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-12">
                  <div class="card card-box pull-up">
                    <div class="card-header  border-0">
                      <h6 class="mb-0">Level Seven</h6>
                    </div>
                    <div class="card-body text-center pt-3">
                      <div className="row">
                        <div className="col">
                          <span className="text-light">Prize</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-success  btn-sm">
                              ₹1280
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-light">Next Quiz</span>
                          <div class="text-center border-0 pt-3">
                            <span class=" text-warning">
                              {quizTimer < 10 ? "0" + quizTimer : quizTimer}{" "}
                              seconds
                            </span>
                          </div>
                        </div>
                        <div
                          className="col "
                          onClick={() => {
                            setDifficultyLevel(7);
                            document
                              ?.getElementById("open-join-now-alert")
                              ?.click();
                          }}
                        >
                          <span className="text-light">Entry</span>
                          <div class="text-center border-0 pt-1">
                            <span class="btn btn-block button-9 btn-primary  btn-sm">
                              ₹640
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-xxl-4">
              <div class="row"></div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="open-join-now-alert"
      >
        Launch demo modal
      </button>

      <div
        class=" blur modal  fade"
        id="exampleModal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered noticepop">
          <div class="modal-content">
            <div class="modal-header bgpop">
              <div className="rolar">
                {" "}
                <img src="../../images/star.png" alt="" />
              </div>

              <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
                Joining Confirmation
              </h1>
            </div>
            <div class="modal-body">
              <h3 className="text-light text-center">
                {" "}
                Are you sure you want to join the quiz for{" "}
                <span className="text-success">
                  ₹
                  {difficultyLevel == 1
                    ? 10
                    : difficultyLevel == 2
                    ? 20
                    : difficultyLevel == 3
                    ? 40
                    : difficultyLevel == 4
                    ? 80
                    : difficultyLevel == 5
                    ? 160
                    : difficultyLevel == 6
                    ? 320
                    : difficultyLevel == 7
                    ? 640
                    : ""}
                </span>{" "}
                joining fees ?
              </h3>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn col button-9 btn-danger"
                data-bs-dismiss="modal"
                id="close-quiz-confirm-popup"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn col button-9 btn-primary"
                onClick={() => {
                  applyQuizGame(difficultyLevel);
                  document?.getElementById("close-quiz-confirm-popup")?.click();
                }}
              >
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
