import { useEffect, useState } from "react";
import { isInPastWithAddedDuration, subtractTimeFromDifference} from "../../helper/function/Helper.function";

export default function UserFlycrickBookedTicketCount({
  departureTime,
  count,
}) {
  const [timeDeifference, setTimeDifference] = useState("");
  const [isLanded, setIsLanded] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTimeDifference(() => {
        return subtractTimeFromDifference(departureTime);
      });

      setIsLanded(() => {
        return isInPastWithAddedDuration(departureTime);
      });
    }, 1000);
  }, []);

  return (
    <>
      {!isLanded ? (
        <div className="Departure">
          <i class="bi bi-ticket-perforated"></i> Total Booked Tickets{" "}
          <span className="text-end text-success">{count}</span>
        </div>
      ) : null}
    </>
  );
}
