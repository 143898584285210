import { useEffect, useState } from "react";
import { useParams } from "react-router";
import token from "../../../../Helper/Token";
import { toast } from "react-toastify";

export default function SingleUserReport() {
  const [loading, setLoading] = useState({
    btnLoading: false,
    reportLoading: false,
  });
  const [userReport, setUserReport] = useState({});
  const { id } = useParams();





  useEffect(() => {
    setLoading({ ...loading, reportLoading: true });
    token
      .get(`admin/get-single-user/${id}`)
      .then((res) => {
        if (res?.data?.status == "success") {
          setUserReport(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading({ ...loading, reportLoading: false });
      });
  }, [id]);




  function updateUserStatus(reqBody) {
    token
      .put("admin/update-user-status", reqBody)
      .then((res) => {
        if (res?.data?.status == "success") {
          setUserReport(res.data.data);
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {});
  }

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">{userReport?.name} Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {loading?.reportLoading ? (
                    <>
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <h1>{userReport?.name}</h1>
                      <p>User Id : {userReport?.userId}</p>
                      <p>
                        Sponser Id :{" "}
                        {userReport?.sponserId?.userId
                          ? userReport?.sponserId?.userId
                          : userReport.reffrenceId}{" "}
                      </p>
                      <p>Email Id : {userReport?.email}</p>
                      <p>Account Number : {userReport?.accountNumber}</p>
                      <p>Address : {userReport?.address}</p>
                      <p>
                        Available Balance : {userReport?.availableBalance} INR
                      </p>
                      <p>Bank Name : {userReport?.bankName}</p>
                      <p>Branch Name : {userReport?.branchName}</p>
                      <p>City : {userReport?.city}</p>
                      <p>Country : {userReport?.country}</p>
                      <p>Country Code : {userReport?.countryCode}</p>
                      <p>IFSC : {userReport?.ifsc}</p>
                      <p>KMT Address : {userReport?.kmtAddress}</p>
                      <p>Phone : {userReport?.phone}</p>
                      <p>Pin Code : {userReport?.pinCode}</p>
                      <p>
                        Refference Income : {userReport?.refferalIncome} INR
                      </p>
                      <p>State : {userReport?.state}</p>
                      <p>Total Deposite : {userReport?.totalDeposite}</p>
                      <p>
                        Total Fund Transfer : {userReport?.totalFundTransfer}
                      </p>
                      <p>Total Withdrawal : {userReport?.totalWithdrawal}</p>
                      <p>UPI Address : {userReport?.upiAddress}</p>
                      <p>USDT Address : {userReport?.usdtAddress}</p>
                      <p>
                        Status :{" "}
                        {userReport?.status == 0 ? "Blocked" : "Unblocked"}
                      </p>

                      {userReport?.status == 0 ? (
                        <>
                          {!loading.btnLoading ? (
                            <button
                              className="btn btn-success"
                              onClick={(e) => {
                                e.preventDefault();

                                updateUserStatus({
                                  userId: userReport?._id,
                                  status: 1,
                                });
                              }}
                            >
                              Unblock{" "}
                            </button>
                          ) : (
                            <button
                              class="btn btn-success"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                              <span role="status">Loading...</span>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {!loading?.btnLoading ? (
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault();

                                updateUserStatus({
                                  userId: userReport?._id,
                                  status: 0,
                                });
                              }}
                            >
                              Block{" "}
                            </button>
                          ) : (
                            <button
                              class="btn btn-danger"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                              <span role="status">Loading...</span>
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
