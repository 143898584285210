import React, { useState } from "react";
import Token from '../../../Helper/Token';
import { toast } from 'react-toastify';
const WalletTransferReq = () => {
  const [data, setData] = useState({OldPassword:"",NewPassword:"",cpass: ""})
  const [btnloder,setBtnloder] = useState()
  const ChangeInput = (e) => setData({ ...data, [e.currentTarget.id]: e.currentTarget.value })
  const SubmitNow= (e) => {
    e.preventDefault();
    setBtnloder(true);
      Token.post('/TransferWallet',data).then((res)=>{
        if(res.status === 200 && parseInt(res.data.Status)===1){ toast.success('Password Successfully changed.', { position: "top-left", autoClose: 900}) }else{ toast.error(res.data.Message, { position: "top-left", autoClose: 900});}
        setBtnloder(false);
      }).catch((err)=>{ toast.error('Internal Server Error.', { position: "top-left", autoClose: 900}); setBtnloder(false);})
  }
  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">Profile</h2>
          
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  {" "}
                  <i class="bi bi-ui-checks"></i>
                </span>
                Profile
              </a>
            </li>
            <li class="breadcrumb-item">
              <a href="">Profile Element</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-6 col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Wallet Transfer</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={SubmitNow}>
                      <div class="mb-3 position-relative row">
                        <label class="col-sm-3 col-form-label"> Enter Amount </label>
                        <div class="col-sm-9">
                          <input type="number" id="Amount" value={data?.Amount} name="Amount" class="form-control"  placeholder="Amount" onInput={ChangeInput} required/>
                        </div>
                      </div>
                      <div class="mb-3 position-relative row">
                        <label class="col-sm-3 col-form-label">Enter Remarks </label>
                        <div class="col-sm-9">
                          <input type="text" id="Remarks" value={data?.Remarks} class="form-control" onInput={ChangeInput} placeholder="Remarks" required/>
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <div class="col-sm-10">
                          {
                            btnloder ? <button type="button" class="btn btn-primary btn-sm btn-lod" disabled><div class="spinner-border sz-3" role="status"></div> <span>Loading...</span></button> : <button type="submit" class="btn btn-primary btn-sm">Submit</button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTransferReq;
