import { useEffect } from 'react';
import Token from '../../../Helper/Token';
import { useState } from 'react';
const Mypool1 = () => {
  const [PreLod, setPreLod] = useState();
  const [Members, setMembers] = useState({});
  const [Hostry, setHostry] = useState({});
  const [Prev, setPrev] = useState();
  const Setdata = (tempData) => {
    if (!tempData.Payload) { tempData.Payload = [{ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }] }
    else if (tempData.Payload.length < 1) { tempData.Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }) }
    else if (tempData.Payload.length < 2) { tempData.Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }) }
    for (var i = 0; i < tempData.Payload.length; i++) {
      if (!tempData.Payload[i].Payload) { tempData[i].Payload = [{ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }] }
      else if (tempData.Payload[i].Payload.length < 1) { tempData.Payload[i].Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }) }
      else if (tempData.Payload[i].Payload.length < 2) { tempData.Payload[i].Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }) }
      for (var j = 0; j < tempData.Payload[i].Payload.length; j++) {
        if (!tempData.Payload[i].Payload[j].Payload) { tempData.Payload[i].Payload[j].Payload = [{ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }] } else
          if (tempData.Payload[i].Payload[j].Payload.length < 1) { tempData.Payload[i].Payload[j].Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }, { userid: "", name: "Open", jointype: 0, Payload: [] }) } else
            if (tempData.Payload[i].Payload[j].Payload.length < 2) { tempData.Payload[i].Payload[j].Payload.push({ userid: "", name: "Open", jointype: 0, Payload: [] }) }
      }
    }
    setMembers(tempData);
  }
  const GetData = (e) => {
    if (e.userid) {
      setPrev(true);
      setPreLod(true);
      Token.get('/pooltree1?userid1=' + e.userid).then((res) => {
        if (res.status === 200 && parseInt(res.data.Status) === 1) { Setdata(res.data) } setPreLod(false);
      }).catch((err) => { console.log(err) })
    }
  }
  useEffect(() => {
    setPreLod(true);
    Token.get('/pooltree1').then((res) => {
      if (res.status === 200 && parseInt(res.data.Status) === 1) { setHostry(res.data); Setdata(res.data) } setPreLod(false);
    }).catch((err) => { console.log(err) })
  }, [])
  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">My Network</h2>
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  <i class="bi bi-ui-checks"></i>
                </span>
                My Account
              </a>
            </li>
            <li class="breadcrumb-item">
              <a>Kyc</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title"> Pool Tree 1</h4>
                </div>
                <div class="card-body">

                  <div class="table-responsive p3-4">
                    <div class=""></div>
                    {
                      PreLod ? <div className='d-flex justify-content-center align-items-center' style={{ height: "620px" }}>
                        <div class="spinner-grow text-success mx-2" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-danger mx-2" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-2" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                        :
                        <>
                          <table class="table tree-table border-0 table-responsive mb-5">
                            {Prev?<button className="btn btn-primary"onClick={()=>{setMembers(Hostry);setPrev(false)}}><i class="bi bi-chevron-compact-left"></i>Prev</button>:""}
                            <tbody>
                              <tr>
                                <td colspan="2" class="text-center">
                                  <img src={Members?.userid ? Members?.jointype ? "/images/tree/tree3.png" : "/images/tree/tree1.png" : "/images/tree/tree2.png"} class="tree-img" alt={Members?.userid} /><br />
                                  <span id="ctl00_ContentPlaceHolder1_Label3">{Members?.userid}</span><br />
                                  <span id="ctl00_ContentPlaceHolder1_Label3">{Members?.name}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" class="text-center"><img src="/images/tree/arrow6.png" alt="" class="center-block tree-first" /></td>
                              </tr>
                              <tr>
                                {
                                  Members && Members.Payload && Members.Payload.map((sub, i) => {
                                    if (i < 2) {
                                      return (<td class="text-center">
                                        <table className="table" >
                                          <tr>
                                            <td colspan="3">
                                              <div class="m-auto mb-2 treeid">
                                                <img src={sub.userid ? sub.jointype ? "/images/tree/tree3.png" : "/images/tree/tree1.png" : "/images/tree/tree2.png"} class="tree-img" alt={sub.userid} onClick={() => GetData(sub)} /><br />
                                                <span id="ctl00_ContentPlaceHolder1_Label1">{sub.userid}</span><br />
                                                <span id="ctl00_ContentPlaceHolder1_Label1">{sub.name}</span>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colspan="3"><img src="/images/tree/arrow6.png" class="center-block tree-topone mb-2" /></td>
                                          </tr>
                                          <tr>
                                            {
                                              sub.Payload && sub.Payload.map((chl, j) => {
                                                if (j < 2) {
                                                  return (<td class="text-center">
                                                    <table class="table">
                                                      <tr>
                                                        <td colspan="3"><div class="m-auto mb-2 treeid">
                                                          <img src={chl.userid ? chl.jointype ? "/images/tree/tree3.png" : "/images/tree/tree1.png" : "/images/tree/tree2.png"} class="tree-img" alt={chl.userid} onClick={() => GetData(chl)} />
                                                          <br />
                                                          {chl.userid ? <><span id="ctl00_ContentPlaceHolder1_Label1">{chl.userid}</span><br /></> : ""}
                                                          <span id="ctl00_ContentPlaceHolder1_Label1">{chl.name}</span>
                                                        </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td colspan="3">
                                                          <img src="/images/tree/arrow6.png" alt="" class="center-block tree-topone mb-2" />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        {
                                                          chl && chl.Payload && chl.Payload.map((liv, k) => {
                                                            if (k < 2) {
                                                              return (<td class="text-center"><span class="treeid">
                                                                <img src={liv.userid ? liv.jointype ? "/images/tree/tree3.png" : "/images/tree/tree1.png" : "/images/tree/tree2.png"} class="tree-img" alt={liv.userid} onClick={() => GetData(liv)} /><br />
                                                                {liv.userid ? <><span id="ctl00_ContentPlaceHolder1_Label1">{liv.userid}</span><br /></> : ""}
                                                                <span id="ctl00_ContentPlaceHolder1_Label1">{liv.name}</span>
                                                              </span>
                                                              </td>)
                                                            }
                                                          })
                                                        }
                                                      </tr>
                                                    </table>
                                                  </td>)
                                                }
                                              })
                                            }
                                          </tr>
                                        </table>
                                      </td>)
                                    }
                                  })
                                }
                              </tr>
                            </tbody>
                          </table>
                        </>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mypool1;
