import React, { useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { passwordValidation } from "../../../Helper/function";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState("password");
  const [showNewPassword, setShowNewPassword] = useState("password");
  const [showConPassword, setShowConPassword] = useState("password");

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    conPassword: "",
  });

  const [validationObj, setValidationObj] = useState({
    oldPassword: "",
    newPassword: "",
    conPassword: "",
  });

  function ChangePassword(e) {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }
    setLoading(true);
    token
      .put("/user/change-password", formData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoading(false);
  }

  function formValidation() {
    if (formData.conPassword != formData.newPassword) {
      setValidationObj({
        ...validationObj,
        conPassword: "Confirm password does not match",
      });
      return false;
    }

    const res = passwordValidation(formData.newPassword);
    if (res) {
      setValidationObj({ ...validationObj, newPassword: res });
      return false;
    }
    return true;
  }

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Profile</h2>
            </div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active ms-auto">
                <a class="d-flex align-self-center">
                  <span>
                    {" "}
                    <i class="bi bi-ui-checks"></i>
                  </span>
                  Profile
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="">Profile Element</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-6 col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Change Password</h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={ChangePassword}>
                      <div class="position-relative ">
                        <label
                          htmlFor="password"
                          class="form-label text-light required"
                        >
                          Old Password
                        </label>
                        <input
                          type={showOldPassword}
                          id="password"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.oldPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              oldPassword: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              oldPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showOldPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowOldPassword("password");
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowOldPassword("text");
                              }}
                            ></i>
                          )}
                        </span>
                        <p className="text-center text-danger">
                          {validationObj.oldPassword}
                        </p>
                      </div>
                      <div class="position-relative mt-4 ">
                        <label
                          htmlFor="password"
                          class="form-label text-light required"
                        >
                          New Password
                        </label>
                        <input
                          type={showNewPassword}
                          id="password"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.newPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              newPassword: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              newPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showNewPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowNewPassword("password");
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowNewPassword("text");
                              }}
                            ></i>
                          )}
                        </span>

                        <p className="text-center text-danger">
                          {validationObj.newPassword}
                        </p>
                      </div>

                      <div class="position-relative mt-4">
                        <label
                          htmlFor="password"
                          class="form-label text-light required"
                        >
                          Confirm New Password
                        </label>
                        <input
                          type={showConPassword}
                          id="password"
                          class="form-control text-light"
                          placeholder="Enter your password"
                          value={formData.conPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              conPassword: e.target.value,
                            });
                            setValidationObj({
                              ...validationObj,
                              conPassword: "",
                            });
                          }}
                        />

                        <span class=" eye">
                          {showConPassword != "password" ? (
                            <i
                              class="fa fa-eye-slash"
                              onClick={() => {
                                setShowConPassword("password");
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye"
                              onClick={() => {
                                setShowConPassword("text");
                              }}
                            ></i>
                          )}
                        </span>
                        <p className="text-center text-danger">
                          {validationObj.conPassword}
                        </p>
                      </div>

                      {!loading ? (
                        <input
                          className="btn btn-primary mt-5"
                          type="submit"
                          value={"Change Password"}
                        />
                      ) : (
                        <button
                          class="btn btn-primary mt-5"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
