import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import token from "./Token";







export function getUserIDFromToken() {
  const token = sessionStorage.getItem("token");
  const decoded = jwtDecode(token);
  return decoded.mongooseId;
}





export function emailValidation(email) {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  } else {
    return true;
  }
}

export function isNumeric(input) {
  const numericRegex = /^[0-9]+$/;
  return numericRegex.test(input);
}

export function CheckPhoneNo(e) {
  let lastDigit = e.target.value.charAt(e.target.value.length - 1);

  if (e.target.value == "") {
    return "";
  }

  if (!isNumeric(lastDigit)) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    return "Only numbers are allowed";
  }

  if (e.target.value.charAt(0) < 6) {
    e.target.value = "";
    return "Enter a valid number";
  }

  if (e.target.value.length > 10) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    return "Only 10 digits are allowed";
  }
}

export function CheckPinCode(e) {
  let lastDigit = e.target.value.charAt(e.target.value.length - 1);

  if (e.target.value == "") {
    return "";
  }

  if (!isNumeric(lastDigit)) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    return "Only numbers are allowed";
  }

  if (e.target.value.length > 6) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    return "Only 6 digits are allowed";
  }
}

function isCharacter(input) {
  const characterRegex = /^[a-zA-Z]+$/;
  return characterRegex.test(input);
}

export function characterValidation(e) {
  if (e.target.value == "") {
    return true;
  }
  if (e.target.value.charAt(e.target.value.length - 1) == " ") {
    return true;
  }
  let lastCharacter = e.target.value.charAt(e.target.value.length - 1);
  if (!isCharacter(lastCharacter)) {
    e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    return false;
  } else {
    return true;
  }
}

export function passwordValidation(password) {
  if (password.length < 8) {
    return "Password must be at least 8 characters long";
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return "Password must contain at least 2 special characters";
  }
  return false;
}

export function humanReadableTimeAndDate(date) {
  const dateTime = new Date(date);
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return dateTime.toLocaleString("en-US", options);
}

export function humanReadableDate(date) {
  const dateTime = new Date(date);
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };
  return dateTime.toLocaleDateString("en-US", options);
}

export function getCurrentSeconds() {
  const now = new Date();
  return now.getSeconds();
}

export function getCurrentSecondsPlusThirty() {
  const now = new Date();
  const currentSeconds = now.getSeconds();
  let updatedSeconds = currentSeconds + 30;

  if (updatedSeconds >= 60) {
    updatedSeconds -= 60;
  }

  return updatedSeconds;
}
