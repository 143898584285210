import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/function/Helper.function";
import { MDBDataTable } from "mdbreact";
import token from "../../../../../Helper/Token";
export default function UserFlycrickTicketReport() {
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetchFlycrickReport();
  }, []);

  async function fetchFlycrickReport() {
    setTableLoading(true);
    token.get('/random-flight/flight-tickets').then((res) => {
      if (res.data.status === "success") {
        setTableData(res.data.data);
        setTableLoading(false);
      } else {
        toast.error(res.data.message);
        setTableLoading(false);
      }
      setTableLoading(false);
    }).catch((error) => { setTableLoading(false); toast.error("Connection issue"); }).finally(()=>{
      setTableLoading(false);
    })
  }

  const table = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Id",
        field: "flightId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ticket Id",
        field: "ticketId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Departure",
        field: "departure",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Destination",
        field: "flightDestination",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bet Destination",
        field: "betDestination",
        sort: "asc",
        width: 150,
      },

      {
        label: "Bet Amount",
        field: "betAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Win Amount",
        field: "winningAmount",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  tableData?.map((elem, i) => {
    table.rows.push({
      sno: i + 1 < 10 ? "0" + Number(i + 1) : i + 1,
      flightId: elem?.flightId?.flightNumber,
      ticketId: elem?.betId,
      departure: formatDate(elem?.flightId?.departureTime),
      flightDestination: elem?.flightId?.finalDestination
        ? elem?.flightId?.finalDestination?.destinationNumber < 10
          ? "0" +
          elem?.flightId?.finalDestination?.destinationNumber +
          ". " +
          elem?.flightId?.finalDestination?.destination
          : elem?.flightId?.finalDestination?.destinationNumber +
          ". " +
          elem?.flightId?.finalDestination?.destination
        : "----",

      betDestination:
        elem?.destinationId?.destinationNumber < 10
          ? "0" +
          elem?.destinationId?.destinationNumber +
          ". " +
          elem?.destinationId?.destination
          : elem?.destinationId?.destinationNumber +
          ". " +
          elem?.destinationId?.destination,

      betAmount: " ₹" + Number(elem?.betAmount)?.toFixed(2),

      winningAmount:
        elem?.destinationId?.destinationNumber ==
          elem?.flightId?.finalDestination?.destinationNumber
          ? " ₹" + Number(elem?.betAmount * 10)?.toFixed(2)
          : "----",

      status: !elem?.flightId?.finalDestination?.destinationNumber ? (
        <span className="text-warning">Pending</span>
      ) : elem?.flightId?.finalDestination?.destinationNumber ==
        elem?.destinationId?.destinationNumber ? (
        <span className="badge  style-1 badge-success">Winner</span>
      ) : (
        <span className=" badge  style-1 badge-danger">Looser</span>
      ),
    });
  });

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Ticket Report</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive mbd-table striped">
                    {
                      !tableLoading?
                      <MDBDataTable bordered small data={table} />
                      :
                      <table class="table table-responsive">
                        <tbody>
                          <tr>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                          </tr>
                          <tr>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                            <td><p className="placeholder-glow"><span className="placeholder col-12"></span></p></td>
                          </tr>
                        </tbody>
                      </table>
                    }
                      
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
