import React, { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";

function Spin() {
  const [data, setData] = useState([
    { class: 'spin1', amount: 2 },
    { class: 'spin2', amount: 5 },
    { class: 'spin3', amount: 10 },
    { class: 'spin4', amount: 50 },
    { class: 'spin5', amount: 100 },
    { class: 'spin6', amount: 1000 },
    { class: 'spin7', amount: 10000 },
    { class: 'spin8', amount: 50000 },
  ])
  const [account, setAccount] = useState({ winningBalance: 0, numberOfSpins: 0, availableBalance: 0 })
  const [target, setTarget] = useState({ currentclass: '', prevClass: '', amount: '', action: false, totalSpin: 2 });
  const getdata = () => {
    token.get("/user/get-spin").then((res) => {
      if (res.data.status == 1) {
        setAccount(res.data.data);
      } else {
        toast.error(res?.data?.message, { position: "top-right", autoClose: 900 });
      }
    }).catch((err) => {
      toast.error("Internal Server Error.", { position: "top-right", autoClose: 900 });
    });
  }
  const spinWheel = () => {
    const num = target.totalSpin;
    let rand = Math.floor(Math.random() * num);
    const win = data[rand]
    setTarget((prev) => ({ ...prev, currentclass: win.class, prevClass: win.class, amount: win.amount, action: true }))
    setTimeout(() => { 
      setTarget((prev) => ({ ...prev, currentclass: '', amount: 0, action: false }))
      getSpinBonus(win); 
    }, 9000);
  };


  async function getSpinBonus(data) {
    token.post("/user/get-spin-amount", data).then((res) => {
      if (res.data.status == "success") {
        getdata()
        toast.success(res?.data?.message, { position: "top-left", autoClose: 900, });
      } else {
        toast.error(res?.data?.message, { position: "top-right", autoClose: 900, });
      }
    }).catch((err) => {
      toast.error("Internal Server Error.", { position: "top-right", autoClose: 900, });
    });
  }
  useEffect(() => { getdata() }, []);
  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Spin Wheel</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-7 ">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Spin ({account?.numberOfSpins ?? 0})
                  </h4>
                  <h4 class="card-title"> Game Balance : {account.availableBalance} INR</h4>
                </div>
                <div class="card-body">
                  <div className="spin-wheel-container">
                    {
                      target.action ?
                        <div className={`wheel spinning ${target.currentclass}`} ><img src="/images/spin1.png" alt="" /></div>
                        :
                        <div className={`wheel ${target.prevClass}`}><img src="/images/spin1.png" alt=""/></div>
                    }
                    {/* <div className={`wheel spinning ${target.class}`} >
                      <img src="/images/spin1.png" alt="" />
                    </div> */}
                    {account.numberOfSpins <= 0 ? (<button disabled>No Spins</button>) : (<button onClick={spinWheel} disabled={target.action}>Spin the Wheel</button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Spin;
