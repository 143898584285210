import { useState, useEffect } from "react";
import token from "../../../../Helper/Token";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const AddQuiz = () => {
  const { id } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const [addQuestionForm, setAddQuestionForm] = useState({
    questionId: "",
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctOption: "",
    isCheckedA: false,
    isCheckedB: false,
    isCheckedC: false,
    isCheckedD: false,
    isCheckedE: false,
    category: "",
    difficultyLevel: "",
  });

  const [validateAddQuestionForm, setValidateAddQuestionForm] = useState({
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctOption: "",
    category: "",
    difficultyLevel: "",
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    token
      .get("/quiz-category/get-all-category")
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(res?.data?.data);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    if (id) {
      token
        .get(`/quiz-question/get-single-questions/${id}`)
        .then((res) => {
          if (res.data.status == "success") {
            setAddQuestionForm({
              questionId: id,
              question: res?.data?.data?.question,
              optionA: res?.data?.data?.optionA,
              optionB: res?.data?.data?.optionB,
              optionC: res?.data?.data?.optionC,
              optionD: res?.data?.data?.optionD,
              correctOption: res?.data?.data?.correctOption,
              isCheckedA:
                res?.data?.data?.correctOption == res?.data?.data?.optionA
                  ? true
                  : false,
              isCheckedB:
                res?.data?.data?.correctOption == res?.data?.data?.optionB
                  ? true
                  : false,
              isCheckedC:
                res?.data?.data?.correctOption == res?.data?.data?.optionC
                  ? true
                  : false,
              isCheckedD:
                res?.data?.data?.correctOption == res?.data?.data?.optionD
                  ? true
                  : false,
              isCheckedE:
                res?.data?.data?.correctOption == res?.data?.data?.optionE
                  ? true
                  : false,
              category: res?.data?.data?.category,
              difficultyLevel: res?.data?.data?.difficultyLevel,
            });
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 900,
            });
          }
        })

        .catch((err) => {
          toast.error("Internal Server Error.", {
            position: "top-right",
            autoClose: 900,
          });
        });
    } else {
      setAddQuestionForm({
        questionId: "",
        question: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        correctOption: "",
        isCheckedA: false,
        isCheckedB: false,
        isCheckedC: false,
        isCheckedD: false,
        isCheckedE: false,
        category: "",
        difficultyLevel: "",
      });
    }
  }, [id]);

  function updateQuestion() {
    if (
      !addQuestionForm?.question ||
      !addQuestionForm?.optionA ||
      !addQuestionForm?.optionB ||
      !addQuestionForm?.optionC ||
      !addQuestionForm?.optionD ||
      !addQuestionForm?.correctOption ||
      !addQuestionForm?.difficultyLevel ||
      !addQuestionForm?.category
    ) {
      setValidateAddQuestionForm({
        ...validateAddQuestionForm,
        question: addQuestionForm?.question ? "" : "Question is required",
        optionA: addQuestionForm?.optionA ? "" : "Option A is required",
        optionB: addQuestionForm?.optionB ? "" : "Option B is required",
        optionC: addQuestionForm?.optionC ? "" : "Option C is required",
        optionD: addQuestionForm?.optionD ? "" : "Option D is required",
        correctOption: addQuestionForm?.correctOption
          ? ""
          : "Correct option is required",
        difficultyLevel: addQuestionForm?.difficultyLevel
          ? ""
          : "Difficulty level is required",
        category: addQuestionForm?.category ? "" : "Category is requred",
      });
      return;
    }
    setLoadingButton(true);
    token
      .put("/quiz-question/update-question", addQuestionForm)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function addQuestion(e) {
    e.preventDefault();

    if (id) {
      updateQuestion();
      return;
    }
    if (
      !addQuestionForm?.question ||
      !addQuestionForm?.optionA ||
      !addQuestionForm?.optionB ||
      !addQuestionForm?.optionC ||
      !addQuestionForm?.optionD ||
      !addQuestionForm?.correctOption ||
      !addQuestionForm?.category ||
      !addQuestionForm?.difficultyLevel
    ) {
      setValidateAddQuestionForm({
        ...validateAddQuestionForm,
        question: addQuestionForm?.question ? "" : "Question is required",
        optionA: addQuestionForm?.optionA ? "" : "Option A is required",
        optionB: addQuestionForm?.optionB ? "" : "Option B is required",
        optionC: addQuestionForm?.optionC ? "" : "Option C is required",
        optionD: addQuestionForm?.optionD ? "" : "Option D is required",
        correctOption: addQuestionForm?.correctOption
          ? ""
          : "Correct option is required",

        category: addQuestionForm?.category ? "" : "Category is requred",
        difficultyLevel: addQuestionForm?.difficultyLevel
          ? ""
          : "Difficulty level is required",
      });
      return;
    }
    setLoadingButton(true);
    token
      .post("/quiz-question/add-question", addQuestionForm)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
          setAddQuestionForm({
            question: "",
            optionA: "",
            optionB: "",
            optionC: "",
            optionD: "",
            correctOption: "",
            isCheckedA: false,
            isCheckedB: false,
            isCheckedC: false,
            isCheckedD: false,
            isCheckedE: true,
            category: "",
            difficultyLevel: "",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title">
            <h2 class="text-white m-0">
              {id ? "Update Question" : "Add Question"}
            </h2>
          </div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  <i class="bi bi-ui-checks"></i>
                </span>
                Earning Details
              </a>
            </li>
            <li class="breadcrumb-item">
              <a>Binary Income</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-md-8  offset-md-2">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    {id ? "Update Question" : "Add Quiz"}
                  </h4>
                </div>
                <div class="card-body">
                  <div class="basic-form Quiz">
                    <form onSubmit={addQuestion}>
                      <div class="mb-3 row">
                        <label
                          htmlFor="category"
                          class="col-sm-2 col-form-label col-form-label-sm"
                        >
                          Category
                        </label>
                        <div class="col-sm-10">
                          <select
                            id="category"
                            className="form-control"
                            onChange={(e) => {
                              setAddQuestionForm({
                                ...addQuestionForm,
                                category: e.target.value,
                              });
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                category: "",
                              });
                            }}
                          >
                            <option value="">Select Category</option>
                            {categories?.map((elem, index) => {
                              return (
                                <option
                                  key={index}
                                  value={elem.name}
                                  selected={
                                    addQuestionForm?.category?.name ==
                                    elem?.name
                                      ? true
                                      : false
                                  }
                                >
                                  {elem.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.category}
                        </p>
                      </div>
                      <div class="mb-3 row">
                        <label
                          htmlFor="difficultyLevel"
                          class="col-sm-2 col-form-label col-form-label-sm"
                        >
                          Difficulty
                        </label>
                        <div class="col-sm-10">
                          <select
                            id="difficultyLevel"
                            className="form-control"
                            onChange={(e) => {
                              setAddQuestionForm({
                                ...addQuestionForm,
                                difficultyLevel: e.target.value,
                              });
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                difficultyLevel: "",
                              });
                            }}
                          >
                            <option value="">Select Difficult</option>
                            <option
                              value="1"
                              selected={
                                addQuestionForm?.difficultyLevel == 1
                                  ? true
                                  : false
                              }
                            >
                              Level One
                            </option>
                            <option
                              value="2"
                              selected={
                                addQuestionForm?.difficultyLevel == 2
                                  ? true
                                  : false
                              }
                            >
                              Level Two
                            </option>
                            <option
                              value="3"
                              selected={
                                addQuestionForm?.difficultyLevel == 3
                                  ? true
                                  : false
                              }
                            >
                              Level Three
                            </option>
                            <option
                              value="4"
                              selected={
                                addQuestionForm?.difficultyLevel == 4
                                  ? true
                                  : false
                              }
                            >
                              Level Four
                            </option>
                            <option
                              value="5"
                              selected={
                                addQuestionForm?.difficultyLevel == 5
                                  ? true
                                  : false
                              }
                            >
                              Level Five
                            </option>
                            <option
                              value="6"
                              selected={
                                addQuestionForm?.difficultyLevel == 6
                                  ? true
                                  : false
                              }
                            >
                              Level Six
                            </option>
                            <option
                              value="7"
                              selected={
                                addQuestionForm?.difficultyLevel == 7
                                  ? true
                                  : false
                              }
                            >
                              Level Seven
                            </option>
                          </select>
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.difficultyLevel}
                        </p>
                      </div>

                      <div class="mb-3 row">
                        <label
                          htmlFor="question"
                          class="col-sm-2 col-form-label col-form-label-sm"
                        >
                          Question
                        </label>
                        <div class="col-sm-10">
                          <textarea
                            class="form-control"
                            rows="5"
                            id="question"
                            value={addQuestionForm?.question}
                            placeholder="Enter question"
                            onChange={(e) => {
                              setAddQuestionForm({
                                ...addQuestionForm,
                                question: e.target.value,
                              });
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                question: "",
                              });
                            }}
                          ></textarea>
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.question}
                        </p>
                      </div>

                      <div class="mb-3 row">
                        <div class="col-sm-2 form-check radio checkbox-success">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={addQuestionForm?.isCheckedA}
                            onClick={() => {
                              if (addQuestionForm?.optionA) {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  correctOption: "",
                                });

                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  correctOption: addQuestionForm?.optionA,
                                  isCheckedA: true,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              } else {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  optionA: "Option A is required",
                                });

                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              }
                            }}
                          />
                          <label
                            class="col-form-label col-form-label-sm"
                            for="flexRadioDefault1"
                          >
                            Option A
                          </label>
                        </div>
                        <div class="col-sm-10">
                          <input
                            type="text"
                            class="form-control form-control"
                            placeholder="Option A"
                            value={addQuestionForm?.optionA}
                            onChange={(e) => {
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                optionA: "",
                              });
                              setAddQuestionForm({
                                ...addQuestionForm,
                                optionA: e.target.value,
                                isCheckedE: true,
                                correctOption: "",
                              });
                            }}
                          />
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.optionA}
                        </p>
                      </div>
                      <div class="mb-3 row">
                        <div class=" col-sm-2 form-check radio checkbox-success">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={addQuestionForm?.isCheckedB}
                            onClick={() => {
                              if (addQuestionForm?.optionB) {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  correctOption: "",
                                });

                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  correctOption: addQuestionForm?.optionB,
                                  isCheckedA: false,
                                  isCheckedB: true,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              } else {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  optionB: "Option B is required",
                                });
                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              }
                            }}
                          />
                          <label
                            class="col-form-label col-form-label-sm "
                            for="flexRadioDefault2"
                          >
                            Option B
                          </label>
                        </div>
                        <div class="col-sm-10">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Option B"
                            value={addQuestionForm?.optionB}
                            onChange={(e) => {
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                optionB: "",
                              });
                              setAddQuestionForm({
                                ...addQuestionForm,
                                optionB: e.target.value,
                                isCheckedE: true,
                                correctOption: "",
                              });
                            }}
                          />
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.optionB}
                        </p>
                      </div>
                      <div class="mb-3 row">
                        <div class=" col-sm-2 form-check radio  checkbox-success">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            checked={addQuestionForm?.isCheckedC}
                            onClick={() => {
                              if (addQuestionForm?.optionC) {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  correctOption: "",
                                });

                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  correctOption: addQuestionForm?.optionC,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: true,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              } else {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  optionC: "Option C is required",
                                });
                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              }
                            }}
                          />
                          <label
                            class="col-form-label col-form-label-sm "
                            for="flexRadioDefault3"
                          >
                            Option C
                          </label>
                        </div>
                        <div class="col-sm-10">
                          <input
                            type="text"
                            class="form-control form-control"
                            placeholder="Option C"
                            value={addQuestionForm?.optionC}
                            onChange={(e) => {
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                optionC: "",
                              });
                              setAddQuestionForm({
                                ...addQuestionForm,
                                optionC: e.target.value,
                                isCheckedE: true,
                                correctOption: "",
                              });
                            }}
                          />
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.optionC}
                        </p>
                      </div>
                      <div class="mb-3 row">
                        <div class=" col-sm-2 form-check radio  checkbox-success">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                            checked={addQuestionForm?.isCheckedD}
                            onClick={() => {
                              if (addQuestionForm?.optionD) {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  correctOption: "",
                                });

                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  correctOption: addQuestionForm?.optionD,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: true,
                                  isCheckedE: false,
                                });
                              } else {
                                setValidateAddQuestionForm({
                                  ...validateAddQuestionForm,
                                  optionD: "Option D is required",
                                });
                                setAddQuestionForm({
                                  ...addQuestionForm,
                                  isCheckedA: false,
                                  isCheckedB: false,
                                  isCheckedC: false,
                                  isCheckedD: false,
                                  isCheckedE: false,
                                });
                              }
                            }}
                          />
                          <label
                            class="col-form-label col-form-label-sm"
                            for="flexRadioDefault4"
                          >
                            Option D
                          </label>
                        </div>
                        <div class="col-sm-10">
                          <input
                            type="text"
                            class="form-control form-control"
                            placeholder="Option D"
                            value={addQuestionForm?.optionD}
                            onChange={(e) => {
                              setValidateAddQuestionForm({
                                ...validateAddQuestionForm,
                                optionD: "",
                              });
                              setAddQuestionForm({
                                ...addQuestionForm,
                                optionD: e.target.value,
                                isCheckedE: true,
                                correctOption: "",
                              });
                            }}
                          />
                        </div>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.optionD}
                        </p>
                        <p className="text-danger text-center">
                          {validateAddQuestionForm?.correctOption}
                        </p>
                      </div>
                      <div class="mb-3 row">
                        <div class="col-lg-12 text-right">
                          {!loadingButton ? (
                            <button type="submit" class="btn btn-primary">
                              {id ? "Update Question" : "Add Question"}
                            </button>
                          ) : (
                            <button
                              class="btn btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                              <span role="status">Loading...</span>
                            </button>
                          )}
                        </div>
                      </div>

                      <input
                        class="form-check-input d-none"
                        type="radio"
                        name="flexRadioDefault"
                        checked={addQuestionForm?.isCheckedE}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuiz;
