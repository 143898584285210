export default function TermsAndConditions (){


    return <>
    <h1 className="text-light">Privacy Policy</h1>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
    <p className="text-light">This is Terms and Conditions you are Terms and conditions we are terms and coditions I am terms and conditions everyone is terms and conditions MOYE MOYE is terms and conditions </p>
   
    </>
}