import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../../../../Helper/Token";

export default function NewForm() {
  const [newsData, setNewsData] = useState({ news: "", newsTitle: "" });
  const [validationObj, setValidationObj] = useState({
    news: "",
    newsTitle: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id != 0) {
      token
        .get(`/news/get-news/${id}`)
        .then((res) => {
          if (res.data.status == "success") {
            setNewsData({
              ...newsData,
              newsId: res?.data?.data?._id,
              news: res?.data?.data?.news,
              newsTitle: res?.data?.data?.newsTitle,
            });
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 900,
            });
          }
        })
        .catch((err) => {
          toast.error("Internal Server Error.", {
            position: "top-right",
            autoClose: 900,
          });
        })
        .finally(() => {});
    }
  }, []);

  function addNews(e) {
    e.preventDefault();
    if (id != 0) {
      updateNews();
      return;
    }

    if (!newsData?.news || !newsData?.newsTitle) {
      setValidationObj({
        ...validationObj,
        news: newsData?.news ? "" : "News feed is required",
        newsTitle: newsData?.newsTitle ? "" : "News title is required",
      });

      return;
    }
    setBtnLoading(true);

    token
      .post(`/news/add-news-feeds`, newsData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setBtnLoading(false);
        setValidationObj({ news: "", newsTitle: "" });
        setNewsData({ news: "", newsTitle: "" });
      });
  }

  function updateNews() {
    if (!newsData?.news || !newsData?.newsTitle) {
      setValidationObj({
        ...validationObj,
        news: newsData?.news ? "" : "News feed is required",
        newsTitle: newsData?.newsTitle ? "" : "News title is required",
      });

      return;
    }
    setBtnLoading(true);

    token
      .put(`/news/update-news-feed`, newsData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setBtnLoading(false);
        setValidationObj({ news: "", newsTitle: "" });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head"></div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-md-6 offset-md-3">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    {" "}
                    {id == 0 ? "Add News" : "Update News"}
                  </h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={addNews}>
                      <div className="mt-4">
                        <label
                          class="form-label text-light"
                          htmlFor="newsTitle"
                        >
                          News Title
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="newsTitle"
                          value={newsData?.newsTitle}
                          onChange={(e) => {
                            setNewsData({
                              ...newsData,
                              newsTitle: e.target.value,
                            });
                          }}
                        />
                        <p className="text-danger text-center">
                          {validationObj.newsTitle}
                        </p>
                      </div>

                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="news">
                          News
                        </label>

                        <textarea
                          class="form-control form-control-sm"
                          id="news"
                          value={newsData?.news}
                          onChange={(e) => {
                            setNewsData({
                              ...newsData,
                              news: e.target.value,
                            });
                          }}
                        ></textarea>
                        <p className="text-danger text-center">
                          {validationObj.news}
                        </p>
                      </div>
                      <div className="mt-5">
                        {!btnLoading ? (
                          <>
                            {id == 0 ? (
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value={"Add News"}
                              />
                            ) : (
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value={"Update News"}
                              />
                            )}
                          </>
                        ) : (
                          <button
                            class="btn btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span role="status">Loading...</span>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
