import { useEffect, useState } from "react";
import { subtractTimeFromDifference } from "../../helper/function/Helper.function";
export default function UserFlycrickTimeLeft({ departureTime }) {
  const [timeDifference, setTimeDifference] = useState();
  useEffect(() => {
    setTimeout(() => {
      const leftTime = subtractTimeFromDifference(departureTime);
      setTimeDifference(leftTime);
    }, 1000);
  }, [timeDifference,departureTime]);

  return (
    <>
      <div className="loader">
        <div className="loadsection">
          <div class="counters margetop">
            <div class="counter">
              <span id="hours">{timeDifference?.slice(0, 2)}</span>
              <p>Hours</p>
            </div>

            <div class="counter">
              <span id="minutes">{timeDifference?.slice(3, 5)}</span>
              <p>Minutes</p>
            </div>

            <div class="counter">
              <span id="seconds">{timeDifference?.slice(6, 8)}</span>
              <p>Seconds</p>
            </div>
          </div>

          {/* <h3 className="text-uppercase text-center">
            Waiting For The next Round
          </h3> */}
        </div>
      </div>
    </>
  );
}
