import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/function/Helper.function";
import TablePlaceholder from "../../../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";
import token from "../../../../../Helper/Token";
export default function AdminFlycrickTicketReport() {
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10))
  useEffect(() => {
    fetchFlycrickReport();
  }, [date]);

  async function fetchFlycrickReport() {
    setTableLoading(true);
    token.get(`/admin/random-flight/get-tickets?date=${date}`).then((res)=>{
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      toast.error("Connection issue");
    }).finally(()=>{
      setTableLoading(false);
    })
  }

  const table = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Id",
        field: "flightId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ticket Id",
        field: "ticketId",
        sort: "asc",
        width: 150,
      },

      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Name",
        field: "userName",
        sort: "asc",
        width: 150,
      },

      {
        label: "Departure",
        field: "departure",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Destination",
        field: "flightDestination",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bet Destination",
        field: "betDestination",
        sort: "asc",
        width: 150,
      },

      {
        label: "Bet Amount",
        field: "betAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Winning Amount",
        field: "winningAmount",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  tableData?.map((elem, i) => {
    table.rows.push({
      sno: i + 1 < 10 ? "0" + Number(i + 1) : i + 1,
      flightId: elem?.flightId?.flightNumber,
      ticketId: elem?.betId,
      userId: elem?.userId?.userId,
      userName: elem?.userId?.name,

      departure: formatDate(elem?.flightId?.departureTime),

      flightDestination: elem?.flightId?.finalDestination
        ? elem?.flightId?.finalDestination?.destinationNumber < 10
          ? "0" +
          elem?.flightId?.finalDestination?.destinationNumber +
          ". " +
          elem?.flightId?.finalDestination?.destination
          : elem?.flightId?.finalDestination?.destinationNumber +
          ". " +
          elem?.flightId?.finalDestination?.destination
        : "----",

      betDestination:
        elem?.destinationId?.destinationNumber < 10
          ? "0" +
          elem?.destinationId?.destinationNumber +
          ". " +
          elem?.destinationId?.destination
          : elem?.destinationId?.destinationNumber +
          ". " +
          elem?.destinationId?.destination,

      betAmount:
        elem?.betAmount < 10
          ? " ₹0" + Number(elem?.betAmount)?.toFixed(2)
          : " ₹" + Number(elem?.betAmount)?.toFixed(2),

      winningAmount:
        elem?.destinationId?.destinationNumber ==
          elem?.flightId?.finalDestination?.destinationNumber
          ? elem?.betAmount < 10
            ? " ₹0" + Number(elem?.betAmount * 10)?.toFixed(2)
            : " ₹" + Number(elem?.betAmount * 10)?.toFixed(2)
          : "----",

      status:
        !elem?.flightId?.finalDestination?.destinationNumber ? (
          <span className="text-warning">Pending</span>
        ) : elem?.flightId?.finalDestination?.destinationNumber ==
          elem?.destinationId?.destinationNumber ? (
          <span className="text-success">Winner</span>
        ) : (
          <span className="text-danger">Looser</span>
        ),
    });
  });

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header w-100">
                  <div className="w-100 d-flex justify-content-between">
                    <h4 class="card-title">Ticket Report</h4>
                    <input type="date" class="form-control" value={date} onChange={(e) => setDate(e.currentTarget.value)} />
                  </div>
                </div>
                <div class="card-body">
                  <div className="p-3">
                    <div class="table table-responsive mbdtable">
                      {!tableLoading ?
                        <MDBDataTable bordered small data={table} />
                        :
                        <table class="table table-responsive">
                          <TablePlaceholder colNum={4} />
                        </table>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
