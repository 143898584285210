import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../../Helper/Token";
import TablePlaceholder from "../../../Components/TablePlaceholder";
import { useParams } from "react-router-dom";
import { humanReadableTimeAndDate } from "../../../../Helper/function";

export default function AdminkycReport() {
  const [KYCData, setKYCData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const { id } = useParams();



  
  function fetchKYCData() {
    setLoadingTable(true);
    token
      .get(`/admin/get-kyc-report/${id}`)
      .then((res) => {
        if (res.data.status == "success") {
          setKYCData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    fetchKYCData();
  }, [id]);

  function updateKYCRequest(reqBody) {
    token
      .put("/admin/get-kyc-report", reqBody)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        fetchKYCData();
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bank Name",
        field: "bankName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch Name",
        field: "branchName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Account Number",
        field: "accountNumber",
        sort: "asc",
        width: 150,
      },
      {
        label: "IFSC",
        field: "ifsc",
        sort: "asc",
        width: 150,
      },
      {
        label: "UPI Address",
        field: "upiAddress",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tron Address",
        field: "kmtAddress",
        sort: "asc",
        width: 150,
      },
      {
        label: "USDT Address",
        field: "usdtAddress",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Date",
        field: "requestDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Respond Date",
        field: "respondDate",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  if (id == 0) {
    table?.columns?.push({
      label: "Actions",
      field: "action",
      sort: "asc",
      width: 150,
    });
  }

  KYCData.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      name: elem?.userId?.name,
      userId: elem?.userId?.userId,
      bankName: elem?.bankName,
      branchName: elem?.branchName,
      accountNumber: elem?.accountNumber,
      ifsc: elem?.ifsc,
      upiAddress: elem?.upiAddress,
      kmtAddress: elem?.kmtAddress,
      usdtAddress: elem?.usdtAddress,
      requestDate: humanReadableTimeAndDate(elem?.createdAt),
      respondDate: humanReadableTimeAndDate(elem?.updatedAt),

      action: (
        <>
          <button
            className="btn btn-success m-1"
            onClick={(e) => {
              e.preventDefault();
              updateKYCRequest({ requestId: elem?._id, response: 1 });
            }}
          >
            Approve
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={(e) => {
              e.preventDefault();
              updateKYCRequest({ requestId: elem?._id, response: -1 });
            }}
          >
            Reject
          </button>
        </>
      ),
    });
  });

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">
          {id == 0
            ? "Pending KYC Report"
            : id == 1
            ? "Approved KYC Report"
            : id == -1
            ? "Rejected KYC Report"
            : null}
        </h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Name
                            </th>
                            <th className="border" scope="col">
                              User Id
                            </th>

                            <th className="border" scope="col">
                              Bank Name
                            </th>
                            <th className="border" scope="col">
                              Branch Name
                            </th>
                            <th className="border" scope="col">
                              Account Number
                            </th>
                            <th className="border" scope="col">
                              IFSC
                            </th>
                            <th className="border" scope="col">
                              UPI Address
                            </th>
                            <th className="border" scope="col">
                              Tron Address
                            </th>
                            <th className="border" scope="col">
                              USDT Address
                            </th>
                            <th className="border" scope="col">
                              Request Date
                            </th>
                            <th className="border" scope="col">
                              Respond Date
                            </th>

                            <th className="border" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={13} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
