import React, { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
const UpdateKYC = () => {
  const [loading, setLoading] = useState(false);

  const [showTransactionPassword, setShowTransactionPassword] =
    useState("password");

  const [formData, setFormData] = useState({
    bankName: "",
    branchName: "",
    ifsc: "",
    accountNumber: "",
    upiAddress: "",
    usdtAddress: "",
    kmtAddress: "",
    transactionPassword: "",
  });

  const [validationObj, setValidationObj] = useState({
    transactionPassword: "",
  });

  useEffect(() => {
    fetchkycData();
  }, []);

  function updateKYC(e) {
    e.preventDefault();

    if (!formData.transactionPassword) {
      setValidationObj({
        ...validationObj,
        transactionPassword: "Transaction password is required",
      });
      return;
    }

    setLoading(true);
    token
      .put("/user/kyc-details", formData)
      .then((res) => {
        if (res.data.status == "success") {
          setFormData(res.data.data);

          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading(false);
        setFormData({ ...formData, transactionPassword: "" });
        fetchkycData();
      });
  }

  function fetchkycData() {
    token
      .get("/user/kyc-details")
      .then((res) => {
        if (res.data.status == "success") {
          setFormData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Update KYC</h2>
            </div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active ms-auto">
                <a class="d-flex align-self-center">
                  <span>
                    <i class="bi bi-ui-checks"></i>
                  </span>
                </a>
              </li>
              <li class="breadcrumb-item">
                <a>Kyc</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-6 col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title"> KYC</h4>

                  {formData?.isUnderreview == 0 ? (
                    <h4 class="card-title" className="text-warning">
                      {" "}
                      Pending
                    </h4>
                  ) : null}

                  {formData?.isUnderreview == -1 ? (
                    <h4 class="card-title" className="text-danger">
                      {" "}
                      Rejected
                    </h4>
                  ) : null}
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={updateKYC}>
                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="upi">
                          UPI Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="upi"
                          value={formData.upiAddress}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              upiAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="usdt">
                          USDT Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="usdt"
                          value={formData.usdtAddress}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              usdtAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="kmt">
                          Tron Address
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="kmt"
                          value={formData.kmtAddress}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              kmtAddress: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <>
                        <div className="mt-4">
                          <label
                            class="form-label text-light"
                            htmlFor="bankName"
                          >
                            Bank Name
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="bankName"
                            value={formData.bankName}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                bankName: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mt-4">
                          <label
                            class="form-label text-light"
                            htmlFor="branchName"
                          >
                            Branch Name
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="branchName"
                            value={formData.branchName}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                branchName: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mt-4">
                          <label class="form-label text-light" htmlFor="accNum">
                            Account Number
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="accNum"
                            value={formData.accountNumber}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                accountNumber: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mt-4">
                          <label class="form-label text-light" htmlFor="ifsc">
                            IFSC
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="ifsc"
                            value={formData.ifsc}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                ifsc: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div class="position-relative mt-4">
                          <label
                            htmlFor="transactionPassword"
                            class="form-label text-light required"
                          >
                            Transaction Password
                          </label>
                          <input
                            type={showTransactionPassword}
                            id="transactionPassword"
                            class="form-control text-light"
                            placeholder="Enter your transaction password"
                            value={formData.transactionPassword}
                            onChange={(e) => {
                              setValidationObj({
                                ...validationObj,
                                transactionPassword: "",
                              });

                              setFormData({
                                ...formData,
                                transactionPassword: e.target.value,
                              });
                            }}
                          />

                          <span class=" eye">
                            {showTransactionPassword != "password" ? (
                              <i
                                class="fa fa-eye-slash"
                                onClick={() => {
                                  setShowTransactionPassword("password");
                                }}
                              ></i>
                            ) : (
                              <i
                                class="fa fa-eye"
                                onClick={() => {
                                  setShowTransactionPassword("text");
                                }}
                              ></i>
                            )}
                          </span>
                        </div>
                        <p className="text-danger text-center">
                          {validationObj.transactionPassword}
                        </p>
                      </>

                      {loading ? (
                        <button
                          type="button"
                          class="btn btn-primary btn-sm btn-lod mt-5"
                          disabled
                        >
                          <div class="spinner-border sz-3" role="status"></div>{" "}
                          <span>Loading...</span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-primary btn-sm mt-5"
                        >
                          Update KYC
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateKYC;
