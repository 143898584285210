import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AutoLoad } from "./View/Helper/AutoLoad";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <>
    <AutoLoad />
    <ToastContainer />
    <App />
  </>
 </React.StrictMode>
);
reportWebVitals();
