import { useState } from "react";
import { baseHeader, baseURL } from "../../helper/variable/Helper.Variable";
import { toast } from "react-toastify";
// import ToolTip from "../../public/public-component/Public.Tooltip";
import eventBus from "../../helper/custom-events/Custom.Events";
import UserTicketConfirmationModal from "./User.Ticket.Confirmation.Modal";
import token from "../../../../../Helper/Token";
export default function UserFlycrickBookingModal({

  setFlightBetFormData = null,
  flightBetFormData = null,
  fetchFlightData = null,

}) {
  const [ticketData, setTicketData] = useState({
    ticketId: [],
    flightId: null,
    fromPlace: null,
    toPlace: null,
    departureTime: null,
    amount: null,
    destinationNumber: null,
  });

  const [validateFlightBetFormData, setValidateFlightBetFormData] = useState({
    betAmount: null,
  });

  function validateBookFlightTicket() {
    if (!flightBetFormData?.betAmount || flightBetFormData?.betAmount == 0) {
      setValidateFlightBetFormData((prev) => ({
        ...prev,
        betAmount: "Bet amount is required",
      }));
      return false;
    }
    return true;
  }

  async function bookFlightTicket(e) {
    e.preventDefault();
    if (!validateBookFlightTicket()) { return; }
    token.post('/random-flight/place-bet', flightBetFormData).then((res)=>{
      if (res.data.status === "success") {
        setTicketData(res.data.data);
        toast.success(res.data.message);
        fetchFlightData();
        document.getElementById("User_Flycrick_Booking_Modal").click();
        document.getElementById("User_Ticket_Confirmation_Modal").click();
        eventBus.emit("refreshBalance");
      } else {
        toast.error(res.data.message);
      }
    }).catch ((error)=>{ toast.error("Connection issue");})
  }

  return (
    <>
      <UserTicketConfirmationModal ticketData={ticketData} />

      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#User_Flycrick_Booking_Modal$"
        id="User_Flycrick_Booking_Modal"
        hidden
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="User_Flycrick_Booking_Modal$"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered noticepop">
          <div class="modal-content">
            <div class="modal-header bgpop">
              <div class="rolar">
                <img src="/images/star.png" alt="" />
              </div>
              <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
                Booking Confirmation
              </h1>
            </div>

            <div class="modal-body">
              <h3 class="text-light text-center">
                Are you sure you want to book a ticket from{" "}
                {flightBetFormData?.fromPlace} to {flightBetFormData?.toPlace} ?
              </h3>

              <select
                class="form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  setValidateFlightBetFormData((prev) => ({
                    ...prev,
                    betAmount: null,
                  }));

                  setFlightBetFormData((prev) => ({
                    ...prev,
                    betAmount: e?.target?.value,
                  }));
                }}
              >
                <option value={0}>-- Select Amount --</option>
                <option value="18">Economy class ₹18</option>
                <option value="66">Business class ₹66</option>
                <option value="121">VIP class ₹121</option>
              </select>
              {/* <ToolTip content={validateFlightBetFormData?.betAmount} /> */}

              <select
                class="form-control mt-3"
                aria-label="Default select example"
                onChange={(e) => {
                  setFlightBetFormData((prev) => ({
                    ...prev,
                    quantity: e?.target?.value,
                  }));
                }}
              >
                <option value={0}>-- Select Quantity --</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
              </select>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn col button-9 btn-danger"
                data-bs-dismiss="modal"
                id="close-booking-form-popup"
              >
                Cancel
              </button>
              <button
                class="btn col button-9 btn-primary"
                onClick={bookFlightTicket}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
