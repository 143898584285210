import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/function/Helper.function";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import TablePlaceholder from "../../../../Components/TablePlaceholder";
import token from "../../../../../Helper/Token";
export default function AdminFlycrickFlightReport() {
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  async function fetchFlycrickReport() {
    setTableLoading(true);
    token.get(`/admin/random-flight/flight-report/?date=${date}`).then((res)=>{
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err)=>{
      toast.error("Connection issue");
    }).finally(()=>{
      setTableLoading(false);
    })
  }

  const table = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },

      {
        label: "Flight Id",
        field: "flightId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Tickets",
        field: "totalTickets",
        sort: "asc",
        width: 150,
      },

      {
        label: "Total Ticket Amount",
        field: "totalTicketAmount",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Total Ticket commission",
      //   field: "totalTicketCommission",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Total Winners",
        field: "totalWinners",
        sort: "asc",
        width: 150,
      },

      {
        label: "Total Winning Amount",
        field: "totalWinningAmount",
        sort: "asc",
        width: 150,
      },

      {
        label: "Departure Time",
        field: "departureTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Departure Place",
        field: "departurePlace",
        sort: "asc",
        width: 150,
      },

      {
        label: "Destination Place",
        field: "destinationPlace",
        sort: "asc",
        width: 150,
      },
      {
        label: "Profit Loss",
        field: "pl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Details",
        field: "details",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  tableData?.map((elem, i) => {
    table.rows.push({
      details: (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            navigate(`/admin/random-flight/flight-report/${elem?._id}`);
          }}
        >
          Details
        </button>
      ),
      sno: i + 1 < 10 ? "0" + Number(i + 1) : i + 1,
      flightId: elem?.flightId,
      departureTime: formatDate(elem?.departureTime),
      departurePlace: elem?.departurePlace,
      destinationPlace: elem?.destinationPlace,
      destinationNumber: elem?.destinationNumber,
      totalTickets:
        elem?.totalTickets < 10 ? "0" + elem?.totalTickets : elem?.totalTickets,
      totalTicketCommission:
        elem?.totalTicketCommission < 10
          ? "₹0" + Number(elem?.totalTicketCommission)?.toFixed(2)
          : "₹" + Number(elem?.totalTicketCommission)?.toFixed(2),

      totalTicketAmount:
        Number(elem?.totalTicketAmount) + Number(elem?.totalTicketCommission) <
          10 ? (
          <span className="text-success">
            {"₹0" +
              Number(
                Number(elem?.totalTicketAmount) +
                Number(elem?.totalTicketCommission)
              )?.toFixed(2)}
          </span>
        ) : (
          <span className="text-success">
            {"₹" + Number(
              Number(elem?.totalTicketAmount) +
              Number(elem?.totalTicketCommission)
            )?.toFixed(2)}
          </span>
        ),

      totalWinners:
        elem?.totalWinners < 10 ? "0" + elem?.totalWinners : elem?.totalWinners,
      pl: (
        <>
          {Number(elem?.totalTicketAmount) +
            Number(elem?.totalTicketCommission) -
            Number(elem?.totalWinningAmount) >
            0 ? (
            <span className="text-success">
              {"₹" +
                (
                  Number(elem?.totalTicketAmount) +
                  Number(elem?.totalTicketCommission) -
                  Number(elem?.totalWinningAmount)
                )?.toFixed(2)}
            </span>
          ) : elem?.totalTicketAmount - elem?.totalWinningAmount < 0 ? (
            <span className="text-danger">
              {"₹" +
                (
                  Number(elem?.totalTicketAmount) +
                  Number(elem?.totalTicketCommission) -
                  Number(elem?.totalWinningAmount)
                )?.toFixed(2)}
            </span>
          ) : (
            <span className="text-warning">
              {"₹" +
                (
                  Number(elem?.totalTicketAmount) +
                  Number(elem?.totalTicketCommission) -
                  Number(elem?.totalWinningAmount)
                )?.toFixed(2)}
            </span>
          )}
        </>
      ),

      totalWinningAmount:
        elem?.totalWinningAmount < 10 ? (
          <span className="text-danger">
            {"₹0" + Number(elem?.totalWinningAmount)?.toFixed(2)}{" "}
          </span>
        ) : (
          <span className="text-danger">
            {"₹" + Number(elem?.totalWinningAmount)?.toFixed(2)}{" "}
          </span>
        ),
    });
  });
  useEffect(() => {
    fetchFlycrickReport();
  }, [date]);

  return (
    <>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header">
                  <div className="w-100 d-flex justify-content-between">
                    <h4 class="card-title">Flights Report</h4>
                    <input
                      type="date"
                      class="form-control"
                      value={date}
                      onChange={(e) => setDate(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div class="card-body">
                  {!tableLoading ?
                    <MDBDataTable bordered small data={table} />
                    :
                    <table class="table table-responsive">
                      <TablePlaceholder colNum={4} />
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
