import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../../Helper/Token";
import TablePlaceholder from "../../../Components/TablePlaceholder";
import { humanReadableTimeAndDate } from "../../../../Helper/function";
import { useNavigate } from "react-router";

export default function NewReportAdmin() {
  const [loadingTable, setLoadingTable] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [newsDetails, setNewsDeatils] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNewsReport();
  }, []);

  function fetchNewsReport() {
    setLoadingTable(true);
    token
      .get(`/news/get-all-news`)
      .then((res) => {
        if (res.data.status == "success") {
          setNewsData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  function deletenews(newsId) {
    setBtnLoading(true);
    token
      .delete(`/news/delete-news-feed/${newsId}`)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setBtnLoading(false);
        fetchNewsReport();
        document.getElementById("close-news-details").click();
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "News",
        field: "news",
        sort: "asc",
        width: 150,
      },

      {
        label: "Created At",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },

      {
        label: "Updated At",
        field: "updatedAt",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "details",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  newsData.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      news: elem?.newsTitle,
      createdAt: humanReadableTimeAndDate(elem?.createdAt),
      updatedAt: humanReadableTimeAndDate(elem?.updatedAt),


      details: (
        <>
          <button
            className="btn btn-primary m-1"
            onClick={(e) => {
              e.preventDefault();
              setNewsDeatils(elem);
              document.getElementById("open-news-details").click();
            }}
          >
            View
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={(e) => {
              e.preventDefault();
              deletenews(elem._id);
            }}
          >
            Delete
          </button>
        </>
      ),



      
    });
  });

  return (
    <>
      <div class="page-titles ">
        <h1 className="text-light">News Report</h1>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              News
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Created At
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Updated At
                            </th>

                            <th className="border" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={5} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary d-none"
        id="open-news-details"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {newsDetails.newsTitle}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-light">{newsDetails.news}</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary d-none"
                data-bs-dismiss="modal"
                id="close-news-details"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("close-news-details").click();
                  navigate(`/admin/news-form/${newsDetails._id}`);
                }}
              >
                Edit
              </button>
              {!btnLoading ? (
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={() => {
                    deletenews(newsDetails._id);
                  }}
                >
                  Delete
                </button>
              ) : (
                <button class="btn btn-danger" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status">Loading...</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
