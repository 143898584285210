import { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import {
  humanReadableDate,
  humanReadableTimeAndDate,
} from "../../../Helper/function";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";

export default function WithdrawalReport() {
  const [WithdrawalReport, setWithdrawalReport] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [singleWithdrawalDetails, setSingleWithdrawalDetails] = useState({});

  useEffect(() => {
    setLoadingTable(true);
    token
      .get("/withdrawal/withdrawal-request")
      .then((res) => {
        if (res?.data?.status == "success") {
          setWithdrawalReport(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
    setLoadingTable(false);
  }, []);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Received Amount",
        field: "convertedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "payment Mode",
        field: "paymentMode",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Bank Name",
      //   field: "bankName",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Branch Name",
      //   field: "branchName",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Account Number",
      //   field: "accNum",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "IFSC",
      //   field: "ifsc",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "USDT Address",
      //   field: "usdtAddress",
      //   sort: "asc",
      //   width: 150,
      // },

      // {
      //   label: "UPI Address",
      //   field: "upiAddress",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "KMT Address",
      //   field: "kmtAddress",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "USDT Rate",
      //   field: "usdtRate",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "KMT Rate",
      //   field: "kmtRate",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Request Date",
        field: "reqDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Response Date",
        field: "resDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Details",
        field: "details",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  function requestStatus(status) {
    if (status == -1) {
      return <p className="text-danger">Rejected</p>;
    } else if (status == 1) {
      return <p className="text-success">Accepted</p>;
    } else {
      return <p className="text-warning">Pending</p>;
    }
  }

  WithdrawalReport?.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      amount: `₹ ${(elem?.amount).toFixed(2)}`,

      convertedAmount:
        elem?.paymentMode == "upi" || elem?.paymentMode == "bank" ? (
          <span className="text-success">
            ₹ {(elem?.amount - elem?.amount / 10).toFixed(2)}
          </span>
        ) : elem?.paymentMode == "kmt" ? (
          <span className="text-success">
            {(elem?.amount / elem?.kmtRate).toFixed(2)} KMT
          </span>
        ) : elem?.paymentMode == "usdt" ? (
          <span className="text-success">
            {(
              elem?.amount / elem?.usdtRate -
              ((elem?.amount / elem?.usdtRate) * 5) / 100
            ).toFixed(2)}{" "}
            USDT
          </span>
        ) : null,

      paymentMode: elem?.paymentMode.toUpperCase(),
      bankName: elem?.bankName,
      branchName: elem?.branchName,
      accNum: elem?.accountNumber,
      ifsc: elem?.ifsc,
      usdtAddress: elem?.usdtAddress,
      upiAddress: elem?.upiAddress,
      kmtAddress: elem?.kmtAddress,

      usdtRate:
        elem?.usdtRate != 0 && elem?.paymentMode == "usdt"
          ? elem?.usdtRate + " / INR"
          : "",

      kmtRate:
        elem.kmtRate != 0 && elem?.paymentMode == "kmt"
          ? elem.kmtRate + " / INR"
          : "",

      reqDate: humanReadableDate(elem?.createdAt),
      resDate: elem?.status != 0 ? humanReadableDate(elem?.updatedAt) : null,
      status: requestStatus(elem?.status),
      details: (
        <p
          className="text-warning"
          onClick={(e) => {
            e.preventDefault();
            setSingleWithdrawalDetails(elem);
            document.getElementById("open-single-withdrawal-report").click();
          }}
        >
          Details
        </p>
      ),
    });
  });

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <h1 className="text-light">Withdrawal Report</h1>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>

                          <th className="border" scope="col">
                            Amount
                          </th>

                          <th className="border" scope="col">
                            Payment mode
                          </th>
                          <th className="border" scope="col">
                            Bank Name
                          </th>

                          <th className="border" scope="col">
                            Branch Name
                          </th>

                          <th className="border" scope="col">
                            Account Number
                          </th>
                          <th className="border" scope="col">
                            IFSC
                          </th>

                          <th className="border" scope="col">
                            USDT Address
                          </th>
                          <th className="border" scope="col">
                            UPI Address
                          </th>
                          <th className="border" scope="col">
                            KMT Address
                          </th>

                          <th className="border" scope="col">
                            Request Date
                          </th>
                          <th className="border" scope="col">
                            Respond Date
                          </th>
                          <th className="border" scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={13} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id="open-single-withdrawal-report"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered  ">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Withdrawal Details
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body px-3 py-3">
                <>
                  <h3 className="text-center"></h3>
                  <ul class="list-group ">
                    {singleWithdrawalDetails?.paymentMode == "bank" ? (
                      <>
                        <li class="list-group-item d-flex justify-content-between">
                          Bank Name :
                          <strong> {singleWithdrawalDetails?.bankName}</strong>
                        </li>

                        <li class="list-group-item d-flex justify-content-between">
                          Branch Name :
                          <strong>{singleWithdrawalDetails?.branchName}</strong>
                        </li>

                        <li class="list-group-item d-flex justify-content-between">
                          Account Number :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.accountNumber}
                          </strong>
                        </li>

                        <li class="list-group-item d-flex justify-content-between">
                          IFSC Code :
                          <strong> {singleWithdrawalDetails?.ifsc}</strong>
                        </li>
                      </>
                    ) : null}

                    {singleWithdrawalDetails?.paymentMode == "upi" ? (
                      <>
                        <li class="list-group-item d-flex justify-content-between">
                          UPI Address :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.upiAddress}
                          </strong>
                        </li>
                      </>
                    ) : null}

                    {singleWithdrawalDetails?.paymentMode == "kmt" ? (
                      <>
                        <li class="list-group-item d-flex justify-content-between">
                          KMT Address :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.kmtAddress}
                          </strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                          KMT Rate :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.kmtRate} / INR
                          </strong>
                        </li>
                      </>
                    ) : null}

                    {singleWithdrawalDetails?.paymentMode == "usdt" ? (
                      <>
                        <li class="list-group-item d-flex justify-content-between">
                          USDT Address :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.usdtAddress}
                          </strong>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                          USDT Rate :
                          <strong>
                            {" "}
                            {singleWithdrawalDetails?.usdtRate} / INR
                          </strong>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </>
              </div>
              <div class="modal-footer  px-3 py-3">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
