import React, { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { isNumeric } from "../../../Helper/function";

export default function WithdrawelRequest() {
  const [loading, setLoading] = useState(false);
  const [gameBalance, setGameBalance] = useState(0.0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [amountValidation, setAmountValidation] = useState("");
  const [currencyRate, setCurrencyRate] = useState({
    usdtRate: 0,
    kmtRate: 0,
  });

  const [formData, setFormData] = useState({
    bankName: "",
    branchName: "",
    ifsc: "",
    accountNumber: "",
    upiAddress: "",
    usdtAddress: "",
    kmtAddress: "",
    amount: "",
    paymentMode: "bank",
  });

  useEffect(() => {
    if (formData.paymentMode == "kmt") {
      setConvertedAmount(formData.amount / currencyRate.kmtRate);
    }
    if (formData.paymentMode == "usdt") {
      setConvertedAmount(formData.amount / currencyRate.usdtRate);
    }
  }, [formData.amount]);
  useEffect(() => {
    setFormData({ ...formData, amount: "" });
  }, [formData.paymentMode]);

  useEffect(() => {
    token
      .get("/admin/get-currency-rate")
      .then((res) => {
        if (res.data.status == "success") {
          setCurrencyRate(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/user/kyc-details")
      .then((res) => {
        if (res.data.status == "success") {
          setFormData({
            ...formData,

            bankName: res?.data?.data?.bankName,
            branchName: res?.data?.data?.branchName,
            ifsc: res?.data?.data?.ifsc,
            accountNumber: res?.data?.data?.accountNumber,
            upiAddress: res?.data?.data?.upiAddress,
            usdtAddress: res?.data?.data?.usdtAddress,
            kmtAddress: res?.data?.data?.kmtAddress,
            amount: res?.data?.data?.amount,
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          setGameBalance(res?.data?.data?.availableBalance);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  function makeWithdrawalRequest(e) {
    e.preventDefault();

    console.log(formData);
    if (!formData?.amount) {
      setAmountValidation("Amount is required");
      return;
    }

    if (formData?.amount < 1000) {
      setAmountValidation("Minimum 1000 INRs are required");
      return;
    }

    setFormData({
      ...formData,
      usdtRate: currencyRate.usdtRate,
      kmtRate: currencyRate.kmtRate,
    });

    setLoading(true);
    token
      .post("/withdrawal/withdrawal-request", formData)
      .then((res) => {
        console.log("RESPONSE DATA =>", res.data);
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading(false);
        setFormData({ ...formData, amount: "", paymentMode: "bank" });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head">
          <div class="d-flex align-items-center dz-head-title"></div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active ms-auto">
              <a class="d-flex align-self-center">
                <span>
                  <i class="bi bi-ui-checks"></i>
                </span>
                My Account
              </a>
            </li>
            <li class="breadcrumb-item">
              <a>Kyc</a>
            </li>
          </ol>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-xl-6 col-lg-12">
              <h1 class="card-title"> Withdrawal Request</h1>
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    {" "}
                    USDT Rate : {currencyRate?.usdtRate?.toFixed(2)}/INR
                  </h4>
                  <h4 class="card-title">
                    {" "}
                    KMT Rate : {currencyRate?.kmtRate?.toFixed(2)}/INR
                  </h4>
                  <h4 class="card-title">
                    {" "}
                    Game Balance : {gameBalance?.toFixed(2)} INR
                  </h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form onSubmit={makeWithdrawalRequest}>
                      <div className="mt-4">
                        <label class="form-label text-light">
                          Payments Options
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={formData.paymentMode}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              paymentMode: e.target.value,
                            });
                          }}
                        >
                          <option value={"bank"} selected>
                            Bank
                          </option>
                          <option value={"upi"}>UPI </option>
                          <option value={"kmt"}>Tron </option>
                          <option value={"usdt"}>USDT </option>
                        </select>
                      </div>

                      {formData.paymentMode == "upi" ? (
                        <div className="mt-4">
                          <label class="form-label text-light" htmlFor="upi">
                            UPI Address
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="upi"
                            defaultValue={formData.upiAddress}
                            readOnly
                          />
                        </div>
                      ) : null}

                      {formData.paymentMode == "usdt" ? (
                        <div className="mt-4">
                          <label class="form-label text-light" htmlFor="usdt">
                            USDT Address
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="usdt"
                            defaultValue={formData.usdtAddress}
                            readOnly
                          />
                        </div>
                      ) : null}

                      {formData.paymentMode == "kmt" ? (
                        <div className="mt-4">
                          <label class="form-label text-light" htmlFor="kmt">
                            Tron Address
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="kmt"
                            defaultValue={formData.kmtAddress}
                            readOnly
                          />
                        </div>
                      ) : null}

                      {formData.paymentMode != "usdt" &&
                      formData.paymentMode != "upi" &&
                      formData.paymentMode != "kmt" ? (
                        <>
                          <div className="mt-4">
                            <label
                              class="form-label text-light"
                              htmlFor="bankName"
                            >
                              Bank Name
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="bankName"
                              defaultValue={formData?.bankName}
                              readOnly
                            />
                          </div>

                          <div className="mt-4">
                            <label
                              class="form-label text-light"
                              htmlFor="branchName"
                            >
                              Branch Name
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="branchName"
                              defaultValue={formData.branchName}
                              readOnly
                            />
                          </div>

                          <div className="mt-4">
                            <label
                              class="form-label text-light"
                              htmlFor="accNum"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="accNum"
                              defaultValue={formData.accountNumber}
                              readOnly
                            />
                          </div>

                          <div className="mt-4">
                            <label class="form-label text-light" htmlFor="ifsc">
                              IFSC
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="ifsc"
                              defaultValue={formData.ifsc}
                              readOnly
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="usdt">
                          Amount
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="usdt"
                          value={formData.amount}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "" || isNumeric(value)) {
                              setFormData({
                                ...formData,
                                amount: value,
                              });

                              setAmountValidation("");
                            } else {
                              setAmountValidation("Only numbers are allowed");
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.value.length - 1
                              );
                            }
                          }}
                        />
                        <p className="text-danger text-center">
                          {amountValidation}
                        </p>
                      </div>
                      {loading ? (
                        <button
                          type="button"
                          class="btn btn-primary btn-sm btn-lod mt-5"
                          disabled
                        >
                          <div class="spinner-border sz-3" role="status"></div>{" "}
                          <span>Loading...</span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-primary btn-sm mt-5"
                        >
                          Request Withdrawal
                        </button>
                      )}

                      {formData.paymentMode === "kmt" && formData.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            {convertedAmount.toFixed(2)} KMT - 0% ={" "}
                            {convertedAmount.toFixed(2)} KMT
                          </span>
                        </p>
                      ) : null}

                      {formData.paymentMode === "bank" && formData.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            ₹{Number(formData?.amount).toFixed(2)} - 10% = ₹
                            {(
                              Number(formData?.amount) -
                              Number(formData?.amount) / 10
                            ).toFixed(2)}
                          </span>
                        </p>
                      ) : null}

                      {formData.paymentMode === "upi" && formData.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            ₹{Number(formData?.amount).toFixed(2)} - 10% = ₹
                            {(
                              Number(formData?.amount) -
                              Number(formData?.amount) / 10
                            ).toFixed(2)}
                          </span>
                        </p>
                      ) : null}

                      {formData.paymentMode === "usdt" && formData.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            {convertedAmount.toFixed(2)} USDT - 5% ={" "}
                            {(convertedAmount - convertedAmount / 20).toFixed(
                              2
                            )}{" "}
                            USDT
                          </span>
                        </p>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
