import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { User, socket } from "../../Helper/Variables";
import token from "../../Helper/Token";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CheckPhoneNo, getUserIDFromToken } from "../../Helper/function";

const Login = () => {
  const user = User();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState("password");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const [KMTFormData, setKMTFormData] = useState({
    userId: "",
    password: "",
  });

  const [validationObj, setValidationObj] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const [KMTValidationObj, setKMTValidationObj] = useState({
    userId: "",
    password: "",
  });

  const [loginType, setLoginType] = useState("phone");

  const [formToggle, setFormToggle] = useState(false);

  const loginUser = (e) => {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    setLoading(true);
    token.post("/user/login", formData).then((res) => {
        if (res.data.status == "success") {
          sessionStorage.setItem("token", res.data.data.token);
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });


          
          let socketId = "";

          if (sessionStorage.getItem("token")) {
            socketId = {
              query: {
                userId: getUserIDFromToken(),
              },
            };
          }
          user.setAuth(formData);
          socket.emit("cameBackOnWebsite", getUserIDFromToken());



          if (res?.data?.data?.role == 1) {
            navigate("/admin/admin-dashboard");
          } else {
            navigate("/home-page");
            console.log("NAVIGATED");
            document.getElementById("open_deposit_bonus_modal_ID").click();
          }



        }
        
        
        else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoading(false);
      }).catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
        setLoading(false);
      });
  };

  const generateTokenForKMT = (userData) => {
    if (!KMTFormValidation()) {
      return;
    }

    token
      .post("/user/login", userData)

      .then((res) => {
        if (res.data.status == "success") {
          sessionStorage.setItem("token", res.data.data.token);
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });

          user.setAuth(userData);
          navigate("/user/dashboard");
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  };

  function register_KMT_User(userData, KMT_Token) {
    token
      .post(`/user/register/kmt-meta`, userData)

      .then((res) => {
        sessionStorage.setItem("KMT_Token", KMT_Token);
        generateTokenForKMT(userData);
      })
      .catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const loginWithKMTMeta = async (e) => {
    e.preventDefault();

    if (!KMTFormValidation()) {
      return;
    }

    setLoading(true);
    try {
      const res = await fetch("https://apikmt.mnbsoft.co.in/api/gamelogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(KMTFormData),
      });
      const data = await res.json();

      console.log("RESPONSE DATA =>", data);
      const userData = {
        reffrenceId: data?.data?.sponsorId,
        name: data?.data?.name,
        email: data?.data?.email,
        password: data?.data?.password,
        userId: data?.data?.userid,
      };

      if (data?.data?.refreshToken) {
        register_KMT_User(userData, data?.data?.refreshToken);
      } else {
        toast.error("Wrong Credencials", {
          position: "top-right",
          autoClose: 900,
        });
      }
    } catch (error) {
      console.log("ERROR =>", error);
      toast.error("Internal Server Error.", {
        position: "top-right",
        autoClose: 900,
      });
    }
    setLoading(false);
  };

  function formValidation() {
    if (loginType == "email" && !formData?.email) {
      setValidationObj((prev) => ({ ...prev, email: "Email is required" }));
      return false;
    } else if (loginType == "phone" && !formData?.phone) {
      setValidationObj((prev) => ({
        ...prev,
        phone: "Phone number is required",
      }));
      return false;
    } else if (!formData?.password) {
      setValidationObj((prev) => ({
        ...prev,
        password: "Password is required",
      }));
      return false;
    } else {
      return true;
    }
  }

  function KMTFormValidation() {
    if (!KMTFormData.userId || !KMTFormData.password) {
      setKMTValidationObj({
        userId: KMTFormData.userId ? "" : "User Id is required",
        password: KMTFormData.password ? "" : "Password is required",
      });
      return false;
    }
    return true;
  }

  return (
    <>
      <>
        <header class="header-one">
          {/* <!-- mobile-menu-area start --> */}
          <div class="mobile-menu-area hidden-lg hidden-md hidden-sm">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="mobile-menu">
                    <div class="logo">
                      <Link to='/'>
                        <img src="img/logo/logo2.png" alt="" />
                      </Link>
                    </div>
                    <div class="header-right-link">
                      <Link to={"/login"} class="s-menu-2">
                        Login now
                      </Link>
                      <Link to={"/sign-up"} class="s-menu">
                        Join now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- mobile-menu-area end --> */}
        </header>

        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#open_deposit_bonus-modal"
          id="open_deposit_bonus_modal_ID"
          hidden
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="open_deposit_bonus-modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Deposit Bonus
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Bonus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>01</th>
                      <td>UPI</td>
                      <td>05%</td>
                    </tr>
                    <tr>
                      <th>02</th>
                      <td>USDT</td>
                      <td>10%</td>
                    </tr>{" "}
                    <tr>
                      <th>03</th>
                      <td>Tron</td>
                      <td>15%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

      <div class="authincation d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="login-aside text-center  d-flex flex-column flex-row-auto">
          <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center mb-lg-4 mb-2 pt-5 logo-login">
              <Link to="/">
                <img src="images/logo-light.png" style={{ height: "55px" }} />
              </Link>
            </div>
            <h3 class="mb-2 text-white">Welcome back!</h3>
            <p class="mb-4">Play Online Game & Win Money Unlimited</p>
          </div>
          <div
            class="aside-image position-relative"
            style={{ backgroundImage: "url(images/background/pic-2.png)" }}
          >
            <img class="img1 move-1" src="images/background/pic3.png" alt="" />
            <img class="img2 move-2" src="images/background/pic4.png" alt="" />
            <img class="img3 move-3" src="images/background/pic5.png" alt="" />
          </div>
        </div>
        <div class="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto login-body">
          <div class="d-flex justify-content-center h-100 align-items-center">
            <div class="authincation-content style-2">
              <div class="row no-gutters">
                <div class="col-xl-12 tab-content">
                  <div
                    id="sign-up"
                    class="auth-form tab-pane fade show active  form-validation"
                  >
                    {!formToggle ? (
                      <>
                        <form onSubmit={loginUser}>
                          <div class="text-center mb-4">
                            <h3 class="text-center mb-2 text-light">{`Login With ${loginType}`}</h3>
                          </div>

                          {loginType == "email" ? (
                            <div class="mb-3">
                              <label
                                htmlFor="email-id"
                                class="form-label text-light required"
                              >
                                Email Id
                              </label>
                              <input
                                type="text"
                                class="form-control text-light"
                                id="email-id"
                                placeholder="Enter your registered email"
                                value={formData.email}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  });
                                  setValidationObj({
                                    ...validationObj,
                                    email: "",
                                  });
                                }}
                              />
                              <p className="text-center text-danger">
                                {validationObj.email}
                              </p>
                            </div>
                          ) : null}

                          {loginType == "phone" ? (
                            <div class="mb-3">
                              <label
                                htmlFor="phone"
                                class="form-label text-light required"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                class="form-control text-light"
                                id="phone"
                                name="phone"
                                placeholder="Enter your registered phone"
                                value={formData.phone}
                                onChange={(e) => {
                                  const temp = CheckPhoneNo(e);

                                  if (temp) {
                                    setValidationObj((prev) => ({
                                      ...prev,
                                      phone: temp,
                                    }));
                                  } else {
                                    setValidationObj((prev) => ({
                                      ...prev,
                                      phone: null,
                                    }));

                                    setFormData((prev) => ({
                                      ...prev,
                                      phone: e.target.value,
                                    }));
                                  }
                                }}
                              />
                              <p className="text-center text-danger">
                                {validationObj.phone}
                              </p>
                            </div>
                          ) : null}

                          <div class="position-relative">
                            <label
                              htmlFor="password"
                              class="form-label text-light required"
                            >
                              Password
                            </label>
                            <input
                              type={showPassword}
                              id="password"
                              class="form-control text-light"
                              placeholder="Enter your password"
                              value={formData.password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                });
                                setValidationObj({
                                  ...validationObj,
                                  password: "",
                                });
                              }}
                            />

                            <span class=" eye">
                              {showPassword != "password" ? (
                                <i
                                  class="fa fa-eye-slash"
                                  onClick={() => {
                                    setShowPassword("password");
                                  }}
                                ></i>
                              ) : (
                                <i
                                  class="fa fa-eye"
                                  onClick={() => {
                                    setShowPassword("text");
                                  }}
                                ></i>
                              )}
                            </span>
                          </div>
                          <p className="text-center text-danger">
                            {validationObj.password}
                          </p>
                          <div class="form-row d-flex justify-content-between mt-4 mb-2">
                            <div class="mb-4">
                              <Link
                                to="/forgetpassword"
                                class="btn-link text-light"
                              >
                                Forgot Password?{" "}
                              </Link>
                            </div>
                          </div>

                          <div className=" mt-7 mt-lg-8">
                            {loading ? (
                              <button
                                class="btn btn-primary btn-block"
                                type="button"
                                disabled
                              >
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                <span>Loading...</span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-block btn-primary"
                                name="submit"
                                id="submit"
                              >
                                Login
                              </button>
                            )}
                          </div>
                        </form>

                        {/* <button
                          className="btn btn-block btn-primary mt-3"
                          onClick={() => {
                            setFormToggle(!formToggle);
                          }}
                        >
                          Login With KMT Meta
                        </button> */}

                        {loginType == "email" ? (
                          <button
                            className="btn btn-block btn-primary mt-3"
                            onClick={() => {
                              setFormData((prev) => ({ ...prev, email: null }));
                              setLoginType("phone");
                            }}
                          >
                            Login with Phone Number
                          </button>
                        ) : null}

                        {loginType == "phone" ? (
                          <button
                            className="btn btn-block btn-border mt-3"
                            onClick={() => {
                              setFormData((prev) => ({ ...prev, phone: null }));
                              setLoginType("email");
                            }}
                          >
                            Login with Email
                          </button>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <form onSubmit={loginWithKMTMeta}>
                          <div class="text-center mb-4">
                            <h3 class="text-center mb-2 text-light">
                              Login With KMT Meta
                            </h3>
                          </div>

                          <div class="mb-3">
                            <label
                              htmlFor="email-id"
                              class="form-label text-light required"
                            >
                              User Id
                            </label>
                            <input
                              type="text"
                              class="form-control text-light"
                              id="email-id"
                              placeholder="Enter your user id"
                              value={KMTFormData.userId}
                              onChange={(e) => {
                                setKMTFormData({
                                  ...KMTFormData,
                                  userId: e.target.value,
                                });
                                setValidationObj({
                                  ...validationObj,
                                  email: "",
                                });
                              }}
                            />
                            <p className="text-center text-danger">
                              {KMTValidationObj.userId}
                            </p>
                          </div>
                          <div class="position-relative">
                            <label
                              htmlFor="password"
                              class="form-label text-light required"
                            >
                              Password
                            </label>
                            <input
                              type={showPassword}
                              id="password"
                              class="form-control text-light"
                              placeholder="Enter your password"
                              value={KMTFormData.password}
                              onChange={(e) => {
                                setKMTFormData({
                                  ...KMTFormData,
                                  password: e.target.value,
                                });
                                setValidationObj({
                                  ...validationObj,
                                  password: "",
                                });
                              }}
                            />

                            <span class=" eye">
                              {showPassword != "password" ? (
                                <i
                                  class="fa fa-eye-slash"
                                  onClick={() => {
                                    setShowPassword("password");
                                  }}
                                ></i>
                              ) : (
                                <i
                                  class="fa fa-eye"
                                  onClick={() => {
                                    setShowPassword("text");
                                  }}
                                ></i>
                              )}
                            </span>
                          </div>
                          <p className="text-center text-danger">
                            {KMTValidationObj.password}
                          </p>
                          <div class="form-row d-flex justify-content-between mt-4 mb-2">
                            <div class="mb-4">
                              <Link
                                to="/forgetpassword"
                                class="btn-link text-light"
                              >
                                Forgot Password?{" "}
                              </Link>
                            </div>
                          </div>

                          <div className=" mt-7 mt-5">
                            {loading ? (
                              <button
                                class="btn btn-primary btn-block"
                                type="button"
                                disabled
                              >
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                <span>Loading...</span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-block btn-success"
                                name="submit"
                                id="submit"
                              >
                                Login with KMT Meta
                              </button>
                            )}
                          </div>
                        </form>
                        <button
                          className="btn btn-block btn-primary mt-3"
                          onClick={() => {
                            setFormToggle(!formToggle);
                          }}
                        >
                          Login With Game
                        </button>
                      </>
                    )}

                    <div class="mt-3 text-center text-light">
                      <p>
                        {" "}
                        Don't have an account?{" "}
                        <Link to="/sign-up" class="text-success">
                          Sign Up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
