import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <>
     
      <div class="authincation fix-wrapper">
        <div class="container">
          <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-6">
              <div class="error-page">
                <div class="error-inner text-center">
                  <div class="dz-error" data-text="404">
                    404
                  </div>
                  <h2 class="error-head mb-0">
                    <i class="fa fa-exclamation-triangle text-warning me-2"></i>
                    The page you were looking for is not found!
                  </h2>
                  <p>
                    You may have mistyped the address or the page may have
                    moved.
                  </p>
                  <Link to="/" class="btn btn-secondary">
                    {" "}
                    BACK TO HOMEPAGE{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
