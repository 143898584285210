import { useEffect, useState } from 'react';
import Token from '../../../Helper/Token';
import { MDBDataTable } from 'mdbreact';
import { toast } from "react-toastify";
const Support = () => {
    const [Responce, setResponce] = useState({ message: "", status: 0 });
    const [btn, setBtnLoader] = useState(false);
    const [InboxMail, setInboxMail] = useState([]);
    const [OutBoxMail, setOutBoxMail] = useState([]);
    const [delLoader, setDelLoader] = useState(false);
    const [title, setTitle] = useState('Compose')
    const [view, setview] = useState({});
    const Inbox = () => {
        Token.get('/Inbox').then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setInboxMail(res.data.Payload) } else { setInboxMail([]) }
        }).catch((err) => { console.log(err) })
    }
    const OutBox = () => {
        Token.get('/Outbox').then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { setOutBoxMail(res.data.Payload) } else { setOutBoxMail([]) }
        }).catch((err) => { console.log(err) })
    }
    const NewDate = (e) => {
        let d = new Date(e);
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][d.getMonth()];
        return d.getDate() + '/' + months + '/' + d.getFullYear();
    }
    const InboxData = {
        columns: [
            {
                label: '#',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Ticket No',
                field: 'tikectno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'User Id',
                field: 'regno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Subject',
                field: 'subject',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Message',
                field: 'messages',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Reply',
                field: 'rep',
                sort: 'asc',
                width: 150
            },
            {
                label: ' Date',
                field: 'date',
                sort: 'asc',
                width: 150
            },
            {
                label: ' Action',
                field: 'action',
                sort: 'asc',
                width: 150
            },
        ],
        rows: []
    };
    const OutboxData = {
        columns: [
            {
                label: '#',
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Ticket No',
                field: 'tikectno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'User Id',
                field: 'regno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Send To',
                field: 'sendto',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Subject',
                field: 'subject',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Message',
                field: 'messages',
                sort: 'asc',
                width: 150
            },
            {
                label: ' Date',
                field: 'date',
                sort: 'asc',
                width: 150
            },
            {
                label: ' Action',
                field: 'action',
                sort: 'asc',
                width: 150
            },
        ],
        rows: []
    };
    const Del = (e) => {
        setDelLoader(true);
        Token.get('/DeleteInbox?id=' + e).then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { Inbox(); OutBox(); toast.success(res.data.Message, { position: "top-right", autoClose: 700, }); } else { toast.error(res.data.Message, { position: toast.POSITION.TOP_RIGHT, autoClose: 700, }) } setDelLoader(false);
        }).catch((err) => { toast.error('Internal Server Error', { position: "top-right", autoClose: 700, }); setDelLoader(false); })
    }
    OutBoxMail.map((tr, i) => {
        OutboxData.rows.push({
            id: i < 10 ? '0' + (i + 1) : (i + 1),
            tikectno: tr.ticket,
            regno: tr.RegNo,
            sendto: tr.sendto,
            subject: tr.Subject,
            messages: tr.Msg,
            date: NewDate(tr.CreateDate),
            action: delLoader ? <button><div className="spinner-border suportloader" role="status"></div></button> : <div className='vmr'><span onClick={() => Del(tr.id)}><i className="bi bi-trash"></i></span><span data-bs-toggle="modal" data-bs-target="#mailView" onClick={() => setview(tr)}><i className="bi bi-eye"></i></span></div>
        })
    })
    InboxMail.map((tr, i) => {
        InboxData.rows.push({
            id: i < 10 ? '0' + (i + 1) : (i + 1),
            tikectno: tr.Ticket,
            regno: tr.RegNo,
            subject: tr.Subject,
            messages: tr.Message,
            rep: tr.reply,
            date: NewDate(tr.CreateDate),
            action: delLoader ? <button><div className="spinner-border suportloader" role="status"></div></button> : <div className='vmr'><span onClick={() => Del(tr.id)}><i className="bi bi-trash"></i></span><span data-bs-toggle="modal" data-bs-target="#mailView" onClick={() => setview(tr)}><i className="bi bi-eye"></i></span></div>
        })
    })
    const ComposeMail = (e) => {
        e.preventDefault();
        setBtnLoader(true)
        setResponce({ message: '', status: 0 })
        Token.post('/SupportTeam', { sendto: e.currentTarget.sendto.value, mailsendto: e.currentTarget.mailsendto.value, subject: e.currentTarget.Subject.value, message: e.currentTarget.Message.value }).then((res) => {
            if (res.status === 200 && parseInt(res.data.Status) === 1) { OutBox(); setResponce({ message: 'Success.', status: 1 }); } else { setResponce({ message: res.data.Message, status: 2 }); } setBtnLoader(false)
        }).catch((err) => { setResponce({ message: 'Internal Server Error', status: 2 }); setBtnLoader(false) })
    }
    useEffect(() => { Inbox(); OutBox(); }, [])
    return (<>
        <div class="page-titles">
            <div className="container-fluid">
                <div class="sub-dz-head">
                    <div class="d-flex align-items-center dz-head-title">
                        <h2 class="text-white m-0">Support</h2>
                        <p class="ms-2 text-warning">Welcome Back Yatin Sharma!</p>
                    </div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active ms-auto">
                            <a class="d-flex align-self-center">
                                <span>
                                    <i class="bi bi-ui-checks"></i>
                                </span>
                                Support
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a>Support</a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="content-body">
            <div class="container-fluid">
                <div className="card custom-card">
                    <div className="card-header justify-content-between">
                        <div className="card-title"> {title} </div>
                    </div>
                    <div className="card-body">
                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-overview" role="tab" aria-selected="true" onClick={() => setTitle('Compose')}>
                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant"></i></span><span className="d-none d-sm-block">Compose Mail</span>
                                </a >
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab" aria-selected="false" onClick={() => setTitle('Inbox')}>
                                    <span className="d-block d-sm-none"><i className="mdi mdi-account"></i></span><span className="d-none d-sm-block">Inbox</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab" aria-selected="false" onClick={() => setTitle('Outbox')}>
                                    <span className="d-block d-sm-none"><i className="mdi mdi-email"></i></span><span className="d-none d-sm-block">Outbox</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content text-muted w-100">
                            <div className="tab-pane active" id="arrow-overview" role="tabpanel">
                                <form onSubmit={ComposeMail}>
                                    <div className="col-md-8">
                                        {
                                            Responce.status && Responce.status === 2 ?
                                                <div className="alert alert-danger" role="alert">{Responce.message}</div>
                                                : Responce.status && Responce.status === 1 ?
                                                    <div className="alert alert-success" role="alert">{Responce.message}</div> : <></>

                                        }
                                        <div className="row mb-3" hidden>
                                            <div className="col-lg-3">
                                                <label htmlFor="sendto" className="form-label">Send To</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <input type="text" value="Administrator" id="sendto" className="form-control" readonly />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-3">
                                                <label htmlFor="mailsendto" className="form-label">Mail Send To</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <select id="mailsendto" className="form-control" required>
                                                    <option value="">--Select--</option>
                                                    <option>Financial</option>
                                                    <option>Technical</option>
                                                    <option>Adminstration </option>
                                                    <option>Other </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-3">
                                                <label htmlFor="Subject " className="form-label">Subject</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <input type="text" id="Subject" className="form-control" placeholder="Enter your subject" required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-3">
                                                <label htmlFor="Message " className="form-label">Message</label>
                                            </div>
                                            <div className="col-lg-9">
                                                <textarea rows="3" cols="20" id="Message" className="form-control" placeholder="Enter your message" required></textarea>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            {
                                                btn ?
                                                    <button className="btn btn-primary" disabled><div className="spinner-border" role="status"></div></button>
                                                    :
                                                    <input type="submit" value="Send" className="btn btn-primary" />
                                            }
                                        </div>
                                        <span id="ctl00_ContentPlaceHolder1_lblMessage"></span>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                <div className="table-responsive"><MDBDataTable bordered small data={InboxData} /></div>
                            </div>
                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                <div className="table-responsive"><MDBDataTable bordered small data={OutboxData} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="mailView" tabindex="-1" aria-labelledby="mailViewLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="mailViewLabel">Tikect No: {view?.Ticket}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label for="oldpass" className="form-label mb-0">User ID</label>
                                <input type="text" className="form-control" value={view?.RegNo} disabled />
                            </div>
                            {
                                view.sendto ?
                                    <div className="col-md-12 mb-3">
                                        <label for="oldpass" className="form-label mb-0">Send To</label>
                                        <input type="text" className="form-control" value={view?.sendto} disabled />
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="col-md-12 mb-3">
                                <label for="oldpass" className="form-label mb-0">Subject</label>
                                <input type="text" className="form-control" value={view?.Subject} disabled />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label for="oldpass" className="form-label mb-0">Message</label>
                                <textarea className='w-100 bg-body-tertiary border rounded p-2' value={view.reply ? view.Message : view.Msg} disabled></textarea>
                            </div>
                            {
                                view.reply ?
                                    <div className="col-md-12 mb-3">
                                        <label for="oldpass" className="form-label mb-0">Reply</label>
                                        <textarea className='w-100 bg-body-tertiary border rounded p-2' value={view?.reply} disabled></textarea>
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="col-md-12 mb-3">
                                <label for="oldpass" className="form-label mb-0">Date</label>
                                <input type="text" className="form-control" value={NewDate(view?.CreateDate)} disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Support;