import { useEffect } from "react";
import { formatDate } from "../../helper/function/Helper.function";

export default function UserTicketConfirmationModal({ ticketData=null }) {
  useEffect(() => {
    console.log("TICKET DATA =>", ticketData);
  }, []);

  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#User_Ticket_Confirmation_Modal$"
        id="User_Ticket_Confirmation_Modal"
        hidden
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="User_Ticket_Confirmation_Modal$"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered noticepop">
          <div class="modal-content">
            <div class="modal-header bgpop">
              <div class="rolar">
                <img src="/images/star.png" alt="" />
              </div>
              <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
                Ticket Confirmed
                <br></br>
                
                {" "}
                  {ticketData?.destinationNumber < 10
                    ? "0" +
                      ticketData?.destinationNumber +
                      ". " +
                      ticketData?.toPlace
                    : ticketData?.destinationNumber +
                      ". " +
                      ticketData?.toPlace}
              </h1>
            </div>

            <div class="modal-body px-3 py-3">
              <div class="mb-3 mt-3">
              <ul class="list-group">
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Ticket ID: <span class="text-white badge-pill"> {" "}
                  {ticketData?.ticketId?.length < 2
                    ? ticketData?.ticketId[0]
                    : `${ticketData?.ticketId[0]} 
                     to
                     ${ticketData?.ticketId[ticketData?.ticketId?.length - 1]}`}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Flight ID: <span class="text-white badge-pill">{ticketData?.flightId}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            From <span class="text-white badge-pill">{ticketData?.fromPlace}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            To <span class="text-white badge-pill">{" "}
                  {ticketData?.destinationNumber < 10
                    ? "0" +
                      ticketData?.destinationNumber +
                      ". " +
                      ticketData?.toPlace
                    : ticketData?.destinationNumber +
                      ". " +
                      ticketData?.toPlace}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Departure Time: <span class="text-white badge-pill">{formatDate(ticketData?.departureTime)}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Fare: <span class="text-white badge-pill">₹{Number(ticketData?.amount)?.toFixed(2)}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Total Fare: ₹ <span class="text-white badge-pill">{Number(ticketData?.amount * ticketData?.quantity)?.toFixed(
                    2
                  )}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Quantity: <span class="text-white badge-pill">{" "}
                  {ticketData?.quantity < 10
                    ? "0" + ticketData?.quantity
                    : ticketData?.quantity}{" "}
                  Tickets</span>
                                        </li>
                                    </ul>
              
                
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn col button-9 btn-danger"
                data-bs-dismiss="modal"
                id="User_Ticket_Confirmation_Modal$"
              >
                Close
              </button>
              
            </div>



            
          </div>
        </div>
      </div>
    </>
  );
}
