import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import token from "../../../Helper/Token";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { humanReadableTimeAndDate } from "../../../Helper/function";

export default function DirectTeamReportUser() {
  const [playersData, setPlayersData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    setLoadingTable(true);
    token
      .get("/user/get-team-member")
      .then((res) => {
        if (res.data.status == "success") {
          setPlayersData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Joining Date",
        field: "doj",
        sort: "asc",
        width: 150,
      },



      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },

    ],

    rows: [],
  };

  playersData.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      name: elem?.name,
      userId: elem?.userId,
      doj: humanReadableTimeAndDate(elem?.createdAt),

      status:
        elem?.isActive === 0 ? (
          <p className="text-danger">Inactive</p>
        ) : (
          <p className="text-success">Active</p>
        ),
      details: (
        <Link to={`/admin/single-user-report/${elem?._id}`}>
          <p className="text-warning">Details</p>
        </Link>
      ),
    });
  });

  return (
    <>
      <div class="page-titles ">
        <div className="container-fluid">
          <h1 className="text-light">My Direct Team</h1>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}
                  <table class="table table-responsive">
                    {loadingTable ? (
                      <>
                        <thead>
                          <tr className="border">
                            <th className="border" scope="col">
                              S.No
                            </th>
                            <th className="border" scope="col">
                              {" "}
                              Name
                            </th>
                            <th className="border" scope="col">
                              User Id
                            </th>
                            <th className="border" scope="col">
                              Sponser Id
                            </th>
                            <th className="border" scope="col">
                              Total Deposite
                            </th>

                            <th className="border" scope="col">
                              Total Withdrawal
                            </th>
                            <th className="border" scope="col">
                              Available Balance
                            </th>
                            <th className="border" scope="col">
                              Status
                            </th>
                          </tr>
                        </thead>

                        <TablePlaceholder colNum={8} />
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
