import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../../../Helper/Token";
import { isNumeric } from "../../../Helper/function";

export default function FundRequest() {
  const [adminDetails, setAdminDetails] = useState({ upiAddress: "", ifsc: "", accountNumber: "", branchName: "", bankName: "", tronAddress: "", usdtAddress: "", upiAddress: ""});
  const [currencyRate, setCurrencyRate] = useState({usdtRate: 0, tronRate: 0 });

  const [convertedAmount, setConvertedAmount] = useState(0);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    paymentMode: "upi",
    amount: "",
    transactionId: "",
    remark: "",
  });

  const [validationObj, setValidationObj] = useState({
    paymentMode: "",
    amount: "",
    transactionId: "",
    remark: "",
  });

  useEffect(() => {
    if (formData.paymentMode == "tron") {
      setConvertedAmount(formData?.amount * currencyRate?.tronRate);
    }

    if (formData.paymentMode == "usdt") {
      setConvertedAmount(formData?.amount * currencyRate?.usdtRate);
    }
  }, [formData?.amount]);

  useEffect(() => {
    token
      .get("/admin/get-currency-rate")
      .then((res) => {
        if (res.data.status == "success") {
          const data = res?.data?.data;
          setCurrencyRate((prev)=>({...prev, tronRate: data.kmtRate, usdtRate: data.usdtRate }));
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/admin/kyc-details")
      .then((res) => {
        if (res.data.status == "success") {
          setAdminDetails(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  function makeFundRequest(e) {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    setLoading(true);
    token
      .post("/fund-request/fund-request", formData)

      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
          setFormData({
            paymentMode: "",
            amount: "",
            transactionId: "",
            remark: "",
          });
          document.getElementById("close-deposit-form").click();
          setFormData({
            ...formData,
            paymentMode: "upi",
            amount: "",
            transactionId: "",
            remark: "",
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((error) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function formValidation() {
    if (!formData?.remark || !formData?.transactionId) {
      setValidationObj({
        remark: formData?.remark ? "" : "Remark is required",
        transactionId: formData?.transactionId
          ? ""
          : "Transaction id is required",
      });
      return false;
    }
    return true;
  }

  const Copy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied.", { position: "top-right", autoClose: 800 });
  };

  return (
    <>
      <div class="page-titles">
        <div class="sub-dz-head"></div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-7 ">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title"> Deposit Request</h4>
                  <h4 class="card-title">
                    {" "}
                    USDT Rate : {currencyRate.usdtRate}/INR
                  </h4>
                  <h4 class="card-title">
                    {" "}
                    Tron Rate : {currencyRate.tronRate}/INR
                  </h4>
                </div>
                <div class="card-body">
                  <div class="basic-form">
                    <form>
                      <div className="mt-4">
                        <label class="form-label text-light">
                          Payments Type
                        </label>
                        <select
                          class="default-select form-control wide"
                          aria-label="Default select example"
                          value={formData.paymentMode}
                          onChange={(e) => {
                            setValidationObj({
                              ...validationObj,
                              paymentMode: "",
                            });
                            setFormData({
                              ...formData,
                              paymentMode: e.target.value,
                              amount: "",
                            });
                          }}
                        >
                          <option selected value={"upi"}> UPI </option>
                          <option value={"tron"}>Tron </option>
                          <option value={"usdt"}>USDT </option>
                        </select>
                        <p className="text-danger text-center">
                          {validationObj.paymentMode}
                        </p>
                      </div>

                      <div className="mt-4">
                        <label class="form-label text-light" htmlFor="usdt">
                          Amount
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="usdt"
                          value={formData?.amount}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "" || isNumeric(value)) {
                              setFormData({
                                ...formData,
                                amount: value,
                              });
                              setValidationObj({
                                ...validationObj,
                                amount: "",
                              });
                            } else {
                              setValidationObj({
                                ...validationObj,
                                amount: "Only numbers are allowed",
                              });
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.value.length - 1
                              );
                            }
                          }}
                        />
                        <p className="text-danger text-center">
                          {validationObj.amount}
                        </p>
                      </div>

                      <button
                        className="btn btn-primary btn-sm mt-3"
                        onClick={(e) => {
                          e.preventDefault();

                          if (!formData?.amount) {
                            setValidationObj({
                              ...validationObj,
                              amount: formData.amount
                                ? ""
                                : "Amount is required",
                            });

                            return;
                          }

                          if (
                            formData?.amount &&
                            formData?.paymentMode == "upi" &&
                            formData?.amount < 300
                          ) {
                            setValidationObj({
                              ...validationObj,
                              amount: "Mimimum 300 INRs are required",
                            });
                            return;
                          }

                          if (
                            formData?.amount &&
                            formData?.paymentMode == "tron" &&
                            formData?.amount * currencyRate?.tronRate < 300
                          ) {
                            setValidationObj({
                              ...validationObj,
                              amount: `Minimum ${(
                                300 / currencyRate?.tronRate
                              ).toFixed(2)} Trons are required`,
                            });
                            return;
                          }

                          if (
                            formData?.amount &&
                            formData?.paymentMode == "usdt" &&
                            formData.amount * currencyRate.usdtRate < 300
                          ) {
                            setValidationObj({
                              ...validationObj,
                              amount: `Minimum ${(
                                300 / currencyRate?.usdtRate
                              ).toFixed(2)} USDTs are required`,
                            });
                            return;
                          }

                          document.getElementById("forDeposit").click();
                        }}
                      >
                        Proceed
                      </button>

                      <button
                        id="forDeposit"
                        className="btn btn-primary btn-sm mt-3 d-none"
                        data-bs-toggle="modal"
                        data-bs-target="#ForDeposit"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Submit Now
                      </button>

                      {formData?.paymentMode === "tron" && formData?.amount ? (
                        <p className="mt-5">
                          You will receive
                          <span className="text-success">
                            ₹{convertedAmount.toFixed(2)} + 15% = ₹ {((convertedAmount * 15) / 100 + convertedAmount).toFixed(2)}
                          </span>
                        </p>
                      ) : null}

                      {formData?.paymentMode === "upi" && formData?.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            ₹{Number(formData?.amount).toFixed(2)} + 5% = ₹
                            {(
                              (Number(formData?.amount) * 5) / 100 +
                              Number(formData?.amount)
                            ).toFixed(2)}
                          </span>
                        </p>
                      ) : null}

                      {formData?.paymentMode === "usdt" && formData?.amount ? (
                        <p className="mt-5">
                          You will receive{" "}
                          <span className="text-success">
                            ₹{convertedAmount.toFixed(2)} + 10% = ₹
                            {(convertedAmount / 10 + convertedAmount).toFixed(
                              2
                            )}
                          </span>
                        </p>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="ForDeposit"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <form onSubmit={makeFundRequest}>
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Deposit Request
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-xl-12">
                        {formData?.paymentMode == "upi" ? (
                          <div className="address">
                            <img
                              src={adminDetails.upiQR}
                              className="qr-image"
                              alt="UPI QR IMAGE"
                            />
                            <div className="mt-4  relative">
                              <label
                                class="form-label text-light"
                                htmlFor="upi"
                              >
                                UPI Address
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="upi"
                                readOnly
                                value={adminDetails.upiAddress}
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails.upiAddress);
                                }}
                              ></i>
                            </div>
                            <div className="mt-4  relative">
                              <label class="form-label text-light">
                                INR Pay
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                defaultValue={formData.amount}
                                readOnly
                              />
                            </div>
                          </div>
                        ) : null}

                        {formData?.paymentMode == "usdt" ? (
                          <>
                            <div className="address">
                              <img
                                src={adminDetails.usdtQR}
                                className="qr-image"
                                alt="USDT QR IMAGE"
                              />
                              <div className="mt-4  relative">
                                <label
                                  class="form-label text-light"
                                  htmlFor="usdt"
                                >
                                  USDT Address
                                </label>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  id="usdt"
                                  defaultValue={adminDetails.usdtAddress}
                                  readOnly
                                />
                                <i
                                  class="bi bi-copy copy address"
                                  onClick={() => {
                                    Copy(adminDetails.usdtAddress);
                                  }}
                                ></i>
                              </div>
                            </div>
                            <div className="mt-4  relative">
                              <label
                                class="form-label text-light"
                                htmlFor="kmt"
                              >
                                USDT PAY
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="kmt"
                                defaultValue={formData?.amount}
                                readOnly
                              />
                            </div>
                          </>
                        ) : null}

                        {formData?.paymentMode == "tron" ? (
                          <div className="address">
                            <img
                              src={adminDetails.kmtQR}
                              className="qr-image"
                              alt="Tron QR IMAGE"
                            />
                            <div className="mt-4  relative">
                              <label
                                class="form-label text-light"
                                htmlFor="tronAddress"
                              >
                                Tron Address
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="tronAddress"
                                defaultValue={adminDetails?.kmtAddress}
                                readOnly
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails?.tronAddress);
                                }}
                              ></i>
                            </div>

                            <div className="mt-4  relative">
                              <label
                                class="form-label text-light"
                                htmlFor="tron"
                              >
                                Tron PAY
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="tron"
                                defaultValue={formData?.amount}
                                readOnly
                              />
                            </div>
                          </div>
                        ) : null}

                        {formData?.paymentMode == "bank" ? (
                          <>
                            <div className="mt-4">
                              <label
                                class="form-label text-light"
                                htmlFor="bankName"
                              >
                                Bank Name
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="bankName"
                                defaultValue={adminDetails?.bankName}
                                readOnly
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails?.bankName);
                                }}
                              ></i>
                            </div>

                            <div className="mt-4">
                              <label
                                class="form-label text-light"
                                htmlFor="branchName"
                              >
                                Branch Name
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="branchName"
                                defaultValue={adminDetails?.branchName}
                                readOnly
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails?.branchName);
                                }}
                              ></i>
                            </div>

                            <div className="mt-4">
                              <label
                                class="form-label text-light"
                                htmlFor="accNum"
                              >
                                Account Number
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="accNum"
                                defaultValue={adminDetails?.accountNumber}
                                readOnly
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails?.accountNumber);
                                }}
                              ></i>
                            </div>

                            <div className="mt-4">
                              <label
                                class="form-label text-light"
                                htmlFor="ifsc"
                              >
                                IFSC
                              </label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="ifsc"
                                defaultValue={adminDetails?.ifsc}
                                readOnly
                              />
                              <i
                                class="bi bi-copy copy address"
                                onClick={() => {
                                  Copy(adminDetails?.ifsc);
                                }}
                              ></i>
                            </div>
                          </>
                        ) : null}

                        <div class="mb-3 mt-3">
                          <label
                            for="IdNo"
                            class="form-label text-light fw-500  required"
                          >
                            Transaction ID
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="IdNo"
                            name="IdNo"
                            value={formData.transactionId}
                            placeholder="Enter transaction Id"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                transactionId: e.target.value,
                              });
                              setValidationObj({
                                ...validationObj,
                                transactionId: "",
                              });
                            }}
                          />
                          <p className="text-center text-danger">
                            {validationObj?.transactionId}
                          </p>
                        </div>
                        <div class="mb-3">
                          <label
                            for="Name"
                            class="form-label text-light fw-500  required"
                          >
                            Remarks
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="Name"
                            name="Name"
                            value={formData.remark}
                            placeholder="Enter remark"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                remark: e.target.value,
                              });

                              setValidationObj({
                                ...validationObj,
                                remark: "",
                              });
                            }}
                          />
                          <p className="text-center text-danger">
                            {validationObj?.remark}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm light"
                      data-bs-dismiss="modal"
                      id="close-deposit-form"
                    >
                      Close
                    </button>

                    {!loading ? (
                      <button class="btn btn-sm btn-primary">Submit</button>
                    ) : (
                      <button class="btn btn-primary" type="button" disabled>
                        <span
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
